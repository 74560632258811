import { ApiErrorCode } from '@carrefour-gcs/shared';
import { defineStore } from 'pinia';

import { changeLanguage } from '../i18n';
import { navigatePreservingQuery } from '../router';

import { useUserStore } from './user.store';

import { isTRPCClientError } from '@/composables/useTrpc';
import { useImpersonateToken } from '@/domain/core/admin/composables/useImpersonateToken';
import { useAnalytics } from '@/domain/core/analytics/composables/useAnalytics';
import { logout, onAuthStateChange } from '@/domain/core/auth/auth.client';
import { isApiErrorOfType } from '@/helpers/errors.helpers';
import { routes } from '@/router/routes';

interface UserAuthFieldsType {
  userId: string;
  lastSignInTime?: string;
  getAccessToken: () => Promise<string>;
}
interface AuthStoreStateType {
  isAuthLoading: boolean;
  auth: UserAuthFieldsType | null;
}
export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreStateType => ({
    isAuthLoading: true,
    auth: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.auth,
    userId: (state) => state.auth?.userId,
  },
  actions: {
    async initAuth() {
      this.isAuthLoading = true;

      onAuthStateChange(async (user) => {
        try {
          if (!user) {
            this.auth = null;
            navigatePreservingQuery(this.router, { name: 'login' });
            return;
          }

          const userStore = useUserStore();

          this.auth = {
            userId: user.uid,
            lastSignInTime: user.metadata.lastSignInTime,
            getAccessToken: () => user.getIdToken(),
          };
          const authenticatedUser = await userStore.fetchAuthenticatedUser();

          if (authenticatedUser.appScope?.lang) {
            changeLanguage(authenticatedUser.appScope.lang);
          }

          const previousPath = window.location.pathname;
          navigatePreservingQuery(this.router, {
            path: previousPath === routes.login ? '/' : previousPath,
          });
        } catch (error) {
          if (
            isTRPCClientError(error) &&
            isApiErrorOfType(error.shape, ApiErrorCode.AUTHENT_UNAUTHENTICATED)
          ) {
            this.isAuthLoading = false;

            this.router.push({ name: 'error' });
          }
        } finally {
          this.isAuthLoading = false;
        }
      });
    },
    async logout() {
      const { trackEvent } = useAnalytics();
      const impersonateToken = useImpersonateToken();

      await logout();
      trackEvent(
        { category: 'account', event: 'logout', action: 'logout' },
        this.router.currentRoute.value,
      );

      if (impersonateToken.value) {
        impersonateToken.value = null;
        window.location.reload();
      }
    },
  },
});
