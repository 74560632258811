import type { UserWithScope } from '@carrefour-gcs/bff';
import {
  type AppScopeKpisPerimeterType,
  type DefaultFiltersType,
  type Lang,
  type ProductNomenclatureType,
  type ReferentialIntervalsType,
  type StorePerimeterType,
  type UserScope,
  ApiErrorCode,
} from '@carrefour-gcs/shared';
import { defineStore } from 'pinia';

import { configureSentryUser } from '../monitoring/sentry';

import {
  DEFAULT_IS_WITH_TAX,
  DEFAULT_PERIOD_INTERVAL,
  DEFAULT_PERIOD_OPTIONS,
  DEFAULT_PERIOD_PERIOD,
  DEFAULT_PERIOD_REFERENTIAL_INTERVALS,
  DEFAULT_REFERENTIAL,
  FILTERS_STATE_DEFAULT_VALUES,
  useKpisPerimeter,
  type FiltersStateType,
} from '@/composables/kpisPerimeter';
import { isTRPCClientError } from '@/composables/useTrpc';
import { useImpersonateToken } from '@/domain/core/admin/composables/useImpersonateToken';
import {
  getCarrefourPeriodInterval,
  getCurrentDateReferentialInterval,
} from '@/domain/core/filter/components/periodSelector/periodSelectorUtils';
import type {
  DatePeriodIntervalType,
  NielsenPeriodIntervalType,
} from '@/domain/core/filter/period.model';
import { getMe, type UserType } from '@/domain/core/user/user.query';
import { getUserWithRetry } from '@/domain/core/user/user.query';
import { isApiErrorOfType } from '@/helpers/errors.helpers';

interface UserStoreStateType {
  authenticatedUser: UserType | null;
  defaultKpisPerimeter: {
    referential: ProductNomenclatureType;
    isWithTax: boolean;
    filters: FiltersStateType;
    period: DatePeriodIntervalType | NielsenPeriodIntervalType;
    referentialIntervals: ReferentialIntervalsType;
    storePerimeter?: StorePerimeterType;
  };
}
export const useUserStore = defineStore('user', {
  state: (): UserStoreStateType => ({
    authenticatedUser: null,
    defaultKpisPerimeter: {
      referential: DEFAULT_REFERENTIAL,
      isWithTax: DEFAULT_IS_WITH_TAX,
      filters: FILTERS_STATE_DEFAULT_VALUES,
      period: {
        periodInterval: DEFAULT_PERIOD_INTERVAL,
        period: DEFAULT_PERIOD_PERIOD,
        option: DEFAULT_PERIOD_OPTIONS,
      },
      referentialIntervals: DEFAULT_PERIOD_REFERENTIAL_INTERVALS,
      storePerimeter: undefined,
    },
  }),
  actions: {
    async fetchAuthenticatedUser(resetKpisPerimeterToDefault?: boolean) {
      const impersonateToken = useImpersonateToken();
      let user: UserWithScope | undefined;
      /**
       * While impersonating, the user should be already setup so no need to get them with retry.
       * If the impersonation jwt is expired then we can remove it and reload the page
       **/
      if (impersonateToken.value) {
        try {
          user = await getMe();
        } catch (error) {
          if (
            isTRPCClientError(error) &&
            isApiErrorOfType(error.shape, ApiErrorCode.AUTHENT_UNAUTHENTICATED)
          ) {
            impersonateToken.value = null;
            window.location.reload();
          }
        }
      }
      if (!user) {
        user = await getUserWithRetry(10);
      }
      configureSentryUser(user);
      if (!user) {
        throw new Error('No user found');
      }
      this.authenticatedUser = user;
      if (user.appScope) {
        const { isDefaultPerimeter, resetPerimeter } = useKpisPerimeter(this.router);

        this.setDefaultUserKpisPerimeter(user.appScope);

        if (isDefaultPerimeter() || resetKpisPerimeterToDefault) {
          resetPerimeter();
        }
      }
      return user;
    },
    setDefaultUserKpisPerimeter(userAppScope: AppScopeKpisPerimeterType) {
      const filters = getFiltersFromUserScope(userAppScope.defaultFilters);
      const { defaultPeriod } = getDefaultCarrefourPeriodsFromUserScope(userAppScope);

      this.defaultKpisPerimeter.isWithTax = userAppScope.isWithTax ?? false;

      this.defaultKpisPerimeter.filters = filters;

      this.defaultKpisPerimeter.period = defaultPeriod;
      this.defaultKpisPerimeter.referentialIntervals = userAppScope.referentialIntervals;

      this.defaultKpisPerimeter.storePerimeter = userAppScope.storePerimeter;
    },
    updateDefaultLanguage(language: Lang) {
      if (this.authenticatedUser?.appScope) {
        this.authenticatedUser.appScope.lang = language;
      }
    },
  },
  getters: {
    isUserOnboarding: (state) => {
      return (
        state.authenticatedUser &&
        !state.authenticatedUser.scope?.flags.isAdmin &&
        !state.authenticatedUser.appScope?.validated
      );
    },
    userCountry: (state) => {
      return state.authenticatedUser?.scope?.organization.country;
    },
    isAdmin: (state) => {
      return !!state.authenticatedUser?.scope?.flags.isAdmin;
    },
    isAccountManager: (state) => {
      return !!state.authenticatedUser?.scope?.flags.isAccountManager;
    },
    isProjectsAuthorized: (state) => {
      return state.authenticatedUser?.scope?.permissions.features.includes('projects');
    },
    isImpersonating: (state) => {
      return !!state.authenticatedUser?.isImpersonating;
    },
    scope: (state): UserScope | null => {
      if (!state.authenticatedUser?.scope) {
        return null;
      }

      return state.authenticatedUser.scope;
    },
  },
});

function getDefaultCarrefourPeriodsFromUserScope(userScope: AppScopeKpisPerimeterType) {
  const intervalLastPeriod =
    userScope.referentialIntervals.carrefour[
      userScope.selectedPeriod?.period || DEFAULT_PERIOD_PERIOD
    ];
  const currentDateReferentialInterval = getCurrentDateReferentialInterval({
    currentReferential: 'carrefour',
    referentialIntervals: userScope.referentialIntervals,
    selectedPeriodType: userScope.selectedPeriod?.period || DEFAULT_PERIOD_PERIOD,
  });
  const interval = getCarrefourPeriodInterval({
    selectedPeriod: userScope.selectedPeriod?.period || DEFAULT_PERIOD_PERIOD,
    currentDateReferentialInterval,
    selectedInterval: intervalLastPeriod,
    selectedCalendarOption: userScope.selectedPeriod?.option || DEFAULT_PERIOD_OPTIONS,
  });

  const selectedPeriod = {
    periodInterval: interval,
    period: userScope.selectedPeriod?.period || DEFAULT_PERIOD_PERIOD,
    option: userScope.selectedPeriod?.option || DEFAULT_PERIOD_OPTIONS,
  };

  return {
    defaultPeriod: { ...selectedPeriod },
  };
}

export function getFiltersFromUserScope(defaultFilters?: DefaultFiltersType): FiltersStateType {
  return {
    nomenclatureFilters:
      defaultFilters?.nomenclatureFilters.map(({ nodeId }) => nodeId) ??
      FILTERS_STATE_DEFAULT_VALUES['nomenclatureFilters'],
    activityTypesFilters:
      defaultFilters?.activityTypesFilters.map(({ nodeId }) => nodeId) ??
      FILTERS_STATE_DEFAULT_VALUES['activityTypesFilters'],
    storesFilters:
      defaultFilters?.storesFilters.map(({ nodeId }) => nodeId) ??
      FILTERS_STATE_DEFAULT_VALUES['storesFilters'],
    barcodeFilters: FILTERS_STATE_DEFAULT_VALUES['barcodeFilters'],
    brandsFilters: FILTERS_STATE_DEFAULT_VALUES['brandsFilters'],
    integratedFranchisedFilters: FILTERS_STATE_DEFAULT_VALUES['integratedFranchisedFilters'],
    itemFilters: FILTERS_STATE_DEFAULT_VALUES['itemFilters'],
    tryptiqueFilters: FILTERS_STATE_DEFAULT_VALUES['tryptiqueFilters'],
  };
}
