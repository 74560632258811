import { z } from 'zod';

const goalEntitySchema = z.object({
  id: z.number(),
  name: z.string(),
  value: z.number(),
});

type GoalEntityType = z.infer<typeof goalEntitySchema>;

const goalEntityToCreateSchema = goalEntitySchema.omit({ id: true });

type GoalEntityToCreateType = z.infer<typeof goalEntityToCreateSchema>;

export {
  goalEntitySchema,
  type GoalEntityType,
  goalEntityToCreateSchema,
  type GoalEntityToCreateType,
};
