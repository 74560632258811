import type { EnvType } from './configuration.model';
import type { CountryType } from './country.model';

export type FeatureType =
  | 'productFruits'
  | 'projects'
  | 'kpiExport'
  | 'nielsen'
  | 'userflow'
  | 'hubSpot'
  | 'like4like'
  | 'embeddingMarketShareDashboard'
  | 'emailPasswordAuthentication'
  | 'dataTableMergeQueries'
  | 'dataTableSalesPossessionRate'
  | 'dataTableGrossServiceRate'
  | 'dataTableNetServiceRate'
  | 'dataTableStock'
  | 'shareProject';

/**
 * Describe features enabled per environment and per country
 *
 * An empty country array means that the feature is globally disabled
 */
export const FEATURES_PER_ENV: Record<EnvType, Record<FeatureType, CountryType[]>> = {
  LOCAL: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: ['ESP', 'FRA', 'ITA'],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: ['ESP', 'FRA', 'ITA'],
    userflow: [],
    like4like: ['FRA', 'ITA', 'ESP'],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: ['FRA', 'ITA', 'ESP'],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableNetServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableStock: ['FRA', 'ITA', 'ESP'],
    shareProject: ['FRA', 'ITA', 'ESP'],
  },
  TEST: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: [],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: [],
    userflow: [],
    like4like: ['FRA', 'ITA', 'ESP'],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: [],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableNetServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableStock: ['FRA', 'ITA', 'ESP'],
    shareProject: ['FRA', 'ITA', 'ESP'],
  },
  INTEG: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: ['ESP', 'FRA', 'ITA'],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: ['ESP', 'FRA', 'ITA'],
    userflow: ['ESP', 'FRA', 'ITA'],
    like4like: ['FRA', 'ITA', 'ESP'],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: ['FRA', 'ITA', 'ESP'],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableNetServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableStock: ['FRA', 'ITA', 'ESP'],
    shareProject: ['FRA', 'ITA', 'ESP'],
  },
  PREPROD: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: ['ESP', 'FRA', 'ITA'],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: [],
    userflow: [],
    like4like: ['FRA'],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: ['FRA', 'ITA', 'ESP'],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableNetServiceRate: ['FRA', 'ITA', 'ESP'],
    dataTableStock: ['FRA', 'ITA', 'ESP'],
    shareProject: ['FRA', 'ITA', 'ESP'],
  },
  PROD: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: ['ESP', 'FRA', 'ITA'],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: [],
    userflow: [],
    like4like: [],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: ['FRA', 'ITA', 'ESP'],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: [],
    dataTableNetServiceRate: [],
    dataTableStock: [],
    shareProject: [],
  },
  DEMO: {
    projects: ['ESP', 'FRA', 'ITA'],
    productFruits: ['ESP', 'FRA', 'ITA'],
    kpiExport: ['ESP', 'FRA', 'ITA'],
    nielsen: ['FRA', 'ITA'],
    hubSpot: [],
    userflow: [],
    like4like: [],
    embeddingMarketShareDashboard: ['FRA'],
    emailPasswordAuthentication: ['FRA', 'ITA', 'ESP'],
    dataTableMergeQueries: ['FRA', 'ITA', 'ESP'],
    dataTableSalesPossessionRate: ['FRA', 'ITA', 'ESP'],
    dataTableGrossServiceRate: [],
    dataTableNetServiceRate: [],
    dataTableStock: [],
    shareProject: [],
  },
};

interface IsFeatureEnabledForEnvAndCountryArgs {
  feature: FeatureType;
  appEnv: EnvType;
  country: CountryType;
  isCountryAgnostic?: false;
}
interface IsFeatureEnabledForEnvArgs {
  feature: FeatureType;
  appEnv: EnvType;
  country?: never;
  isCountryAgnostic: true;
}
export function isFeatureEnabledForEnvAndCountry({
  feature,
  appEnv,
  country,
  isCountryAgnostic,
}: IsFeatureEnabledForEnvAndCountryArgs | IsFeatureEnabledForEnvArgs) {
  if (isCountryAgnostic) {
    return FEATURES_PER_ENV[appEnv][feature].length > 0;
  }
  return FEATURES_PER_ENV[appEnv][feature].includes(country);
}
