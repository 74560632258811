import { z } from 'zod';
import { isAValidCountry, type CountryType, type Lang } from './country.model';
import { isHoldingCountry, type HoldingXCountryType } from './holding.model';
import type {
  AllFilterType,
  DateSelectionOption,
  PeriodDefault,
  StorePerimeterType,
} from './kpis-perimeter.model';
import type { NielsenPeriodStrType } from './nielsen.model';
import {
  FEATURE_PERMISSIONS,
  KPI_GRANULARITIES,
  PERIOD_GRANULARITIES,
  type PeriodGranularity,
} from './permissions.model';
import type { Extends, Immutable } from './ts-utilities';
import { type CustomDateType } from './date.model';

export interface DefaultFiltersItemType {
  filterKey: string;
  type: AllFilterType;
  label: string;
  nodeId: string;
}

export interface DefaultFiltersType {
  nomenclatureFilters: DefaultFiltersItemType[];
  activityTypesFilters: DefaultFiltersItemType[];
  storesFilters: DefaultFiltersItemType[];
}

export interface DateReferentialIntervalType {
  start: CustomDateType;
  end: CustomDateType;
}

export interface PeriodReferentialIntervalType {
  start: NielsenPeriodStrType;
  end: NielsenPeriodStrType;
}

export interface NielsenReferentialIntervalType {
  weekly: DateReferentialIntervalType;
  period: PeriodReferentialIntervalType;
}

export type UserScopeDateSelectionOption = Extends<
  DateSelectionOption,
  'year_to_date' | 'cumulative_to_date' | 'last_period'
>;
export type CarrefourReferentialIntervalType = Record<
  Exclude<PeriodGranularity, 'nielsen'>,
  DateReferentialIntervalType
>;
export interface ReferentialIntervalsType {
  carrefour: CarrefourReferentialIntervalType;
  nielsen?: NielsenReferentialIntervalType;
}

export interface AppScopeKpisPerimeterType {
  selectedPeriod?: {
    period: PeriodDefault | '';
    option: UserScopeDateSelectionOption | '';
  };
  isWithTax?: boolean;
  defaultFilters?: DefaultFiltersType;
  referentialIntervals: ReferentialIntervalsType;
  storePerimeter?: StorePerimeterType;
}

export interface AppScopeType extends AppScopeKpisPerimeterType {
  validated: boolean;
  lang: Lang;
}

export interface OrganizationCountryType {
  id: string;
  externalId: string;
  name: string;
  country: CountryType;
  flag: Lang;
}

export const HoldingXCountryScopeZodSchema = z
  .object({
    holdingId: z.string().readonly(),
    holdingName: z.string().readonly(),
    country: z.string().refine(isAValidCountry).readonly(),
    holdingXCountry: z.string().refine(isHoldingCountry).readonly(),
  })
  .readonly();

export type HoldingXCountryScope = z.infer<typeof HoldingXCountryScopeZodSchema>;

const BaseAuthorizationContextResourceZodSchema = z.object({
  name: z.string(),
  value: z.string(),
});
export type AuthorizationContextResourceType = z.infer<
  typeof BaseAuthorizationContextResourceZodSchema
> & {
  child?: AuthorizationContextResourceType;
};
const AuthorizationContextResourceZodSchema: z.ZodType<AuthorizationContextResourceType> =
  BaseAuthorizationContextResourceZodSchema.extend({
    child: z.lazy(() => AuthorizationContextResourceZodSchema.optional()),
  });

const AuthorizationContextZodSchema = z.object({
  country: z.string().refine(isAValidCountry).readonly(),
  product: z.object({
    carrefour: AuthorizationContextResourceZodSchema.array().readonly(),
    nielsen: AuthorizationContextResourceZodSchema.array().readonly(),
  }),

  store: z.object({
    carrefour: AuthorizationContextResourceZodSchema.array().readonly(),
  }),
});
export type AuthorizationContextType = z.infer<typeof AuthorizationContextZodSchema>;

export const UserScopeZodSchema = z.object({
  organization: z.object({
    id: z.string().cuid().readonly(),
    country: z.string().refine(isAValidCountry).readonly(),
    name: z.string().readonly(),
  }),
  /** @deprecated remove when authV2 migration is completed */
  holding: HoldingXCountryScopeZodSchema,
  authorizationContext: AuthorizationContextZodSchema,
  permissions: z
    .object({
      periods: z.enum(PERIOD_GRANULARITIES).array().readonly(),
      kpis: z.enum(KPI_GRANULARITIES).array().readonly(),
      features: z.enum(FEATURE_PERMISSIONS).array().readonly(),
    })
    .readonly(),
  flags: z
    .object({
      isAdmin: z.boolean().optional().readonly(),
      isAccountManager: z.boolean().optional().readonly(),
      isCarrefour: z.boolean().optional().readonly(),
      isUsingAuthV2: z.boolean().optional().readonly(),
    })
    .readonly(),
});

export type UserScope = Immutable<z.infer<typeof UserScopeZodSchema>>;
