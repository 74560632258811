import { z } from 'zod';

import { roleEntitySchema } from './projects.role.model';
import { userEntitySchema } from './projects.user.model';

const contributorEntitySchema = z.object({
  user: userEntitySchema,
  role: roleEntitySchema,
});

type ContributorEntityType = z.infer<typeof contributorEntitySchema>;

export { contributorEntitySchema, type ContributorEntityType };
