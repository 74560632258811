export const locales = {
  "en": {
    "accountCreationPage.companySelection": "Please select the company to which you belong",
    "accountCreationPage.information": "If your company does not appear, it does not exist in our systems.",
    "accountCreationPage.subtitle": "Track the performance of your category and collaborate with your suppliers on a single platform.",
    "accountCreationPage.validateCompanyButton": "Send my access request",
    "accountManagerSettings.activationDate": "Activation date",
    "accountManagerSettings.dateRequest": "Request received on",
    "accountManagerSettings.listTitle": "User List",
    "accountManagerSettings.noPendingRequest": "No pending request",
    "accountManagerSettings.requestTitle": "New requests",
    "accountManagerSettings.role.ACCOUNT_MANAGER": "Account Manager",
    "accountManagerSettings.role.ADMIN": "Admin",
    "accountManagerSettings.role.METRIC_VIEWER": "Metric Viewer",
    "accountManagerSettings.role.PROJECT_MANAGER": "Project Manager",
    "accountManagerSettings.role.PROJECT_VIEWER": "Project Viewer",
    "accountManagerSettings.title": "Active users",
    "accountWaitingPage.accountReject": "We're sorry, but it looks like you don't have access to the platform.",
    "accountWaitingPage.accountRejectSubtitle": "For more information, please contact your company representative",
    "accountWaitingPage.subtitle": "Keep an eye on your inbox: we'll send you an email when your request has been approved by your company's referrer. For more information, please contact your company representative",
    "accountWaitingPage.title": "Your access request has been sent successfully!",
    "admin.activeUsers": "Active users",
    "admin.filter.globalSearch.placeholder": "Search by Username, Email, Country, Role",
    "admin.helperText": "Find the user you're looking for and click impersonate to view and act in his name.",
    "admin.impersonateFrame.stop": "Stop impersonating",
    "admin.impersonationImpossibleIfAdmin": "An Admin role cannot be usurped",
    "admin.popin.button.start": "Start session as this User",
    "admin.popin.title": "Impersonate session",
    "admin.popin.warning": "When using this features, You will navigate as if you were this User, all actions will be saved as if you were this person. Use it with caution.",
    "admin.title": "Admin dashboard",
    "admin.userList.cell.impersonate": "Impersonate",
    "admin.userList.column.actions": "Actions",
    "admin.userList.column.holdingCountry": "Holding Country",
    "admin.userList.column.roles": "Role(s)",
    "admin.userList.column.user": "User",
    "authenticationPage.emailPassword": "Sign in with email",
    "authenticationPage.error": "It would appear that your company does not exist in our systems.",
    "authenticationPage.google": "Sign in with Google",
    "authenticationPage.microsoft": "Sign in with Microsoft",
    "authenticationPage.popin.continue": "Continue",
    "authenticationPage.popin.email": "Email",
    "authenticationPage.popin.email.invalid": "Invalid Email format",
    "authenticationPage.popin.email.notEmpty": "Email is required",
    "authenticationPage.popin.email.subtitle": "Enter your email address and password",
    "authenticationPage.popin.email.title": "Sign in with email",
    "authenticationPage.popin.forgotPassword": "Forgot password?",
    "authenticationPage.popin.goBack": "Go back",
    "authenticationPage.popin.invalidCredential": "Invalid email or password",
    "authenticationPage.popin.password": "Password",
    "authenticationPage.popin.password.notEmpty": "Password is required",
    "authenticationPage.popin.reset-sent": "Your password reset request has been received. Please check your email for instructions to reset your password.",
    "authenticationPage.popin.reset.subtitle": "Enter your email address to reset your password",
    "authenticationPage.popin.reset.title": "Reset your password",
    "authenticationPage.popin.send": "Send",
    "authenticationPage.subtitle": "Monitor your category & collaborate with suppliers. Log In!",
    "authenticationPage.title": "Welcome to Carrefour Links Growth !",
    "chart.noData.content": "for the selected period",
    "chart.noData.title": "No data available",
    "commonLabel.administration": "Admin",
    "commonLabel.all": "All",
    "commonLabel.applicateFilters": "Apply ({{nbFiltersSelected}}) filters",
    "commonLabel.applySelection": "Apply selection",
    "commonLabel.back": "Back",
    "commonLabel.cancel": "Cancel",
    "commonLabel.complete": "Full",
    "commonLabel.confirm": "Accept the request",
    "commonLabel.countryFrance": "France",
    "commonLabel.countryItaly": "Italy",
    "commonLabel.countrySpain": "Spain",
    "commonLabel.dateFormat": "DD/MM/YYYY",
    "commonLabel.dateRange": "Date range",
    "commonLabel.decline": "Decline",
    "commonLabel.defaultFilterPerimeterIsReset": "Your default perimeter has been restored",
    "commonLabel.details": "Details",
    "commonLabel.displayFilters": "Display scope and filters",
    "commonLabel.error": "An error has occurred while loading data",
    "commonLabel.errorData": "An error has occurred",
    "commonLabel.errorMessage": "Please try again later or contact the administrator.",
    "commonLabel.ESP": "Spain",
    "commonLabel.FRA": "France",
    "commonLabel.from": "From",
    "commonLabel.hideFilters": "Hide scope and filters",
    "commonLabel.home": "Overview",
    "commonLabel.ITA": "Italy",
    "commonLabel.lang.en": "English",
    "commonLabel.lang.es": "Spanish",
    "commonLabel.lang.fr": "French",
    "commonLabel.lang.it": "Italian",
    "commonLabel.loadMore": "Load more",
    "commonLabel.modifyFilters": "Modify filters ({{nbFiltersSelected}})",
    "commonLabel.month.1": "January",
    "commonLabel.month.10": "October",
    "commonLabel.month.11": "November",
    "commonLabel.month.12": "December",
    "commonLabel.month.2": "February",
    "commonLabel.month.3": "March",
    "commonLabel.month.4": "April",
    "commonLabel.month.5": "May",
    "commonLabel.month.6": "June",
    "commonLabel.month.7": "July",
    "commonLabel.month.8": "August",
    "commonLabel.month.9": "September",
    "commonLabel.moreDetails": "More details",
    "commonLabel.noData": "No data",
    "commonLabel.noResult": "No results",
    "commonLabel.notAvailable": "This indicator will be available shortly.",
    "commonLabel.or": "Or",
    "commonLabel.performance": "Performance",
    "commonLabel.projects": "Projects",
    "commonLabel.reEnterFilters": "Please enter your filters again",
    "commonLabel.showFiltersAndPerimeter": "Show scope and filters",
    "commonLabel.storePerimeter": "Store perimeter",
    "commonLabel.supplierView": "Supplier View",
    "commonLabel.taxonomy": "Taxonomy",
    "commonLabel.to": "to",
    "commonLabel.updatePerimeter": "Back to perimeter",
    "commonLabel.value": "Value",
    "dataTable.sort": "Sort data",
    "dataTablePerformance.dimension.average_price": "Average price",
    "dataTablePerformance.dimension.average_price_benchmark": "Average price Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_comparison": "Average price vs N-1",
    "dataTablePerformance.dimension.average_price_with_promo": "Average promo price",
    "dataTablePerformance.dimension.average_price_with_promo_benchmark": "Averag promo price Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_with_promo_comparison": "Average promo price vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_amount": "Av. weekly sales (in sales {{taxType}})",
    "dataTablePerformance.dimension.average_weekly_sales_amount_benchmark": "Av. weekly sales (in sales) Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_amount_comparison": "Av. weekly sales (in sales {{taxType}}) vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_quantity": "Av. weekly sales (in units)",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_benchmark": "Av. weekly sales (in units) Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_comparison": "Av. weekly sales (in units) vs N-1",
    "dataTablePerformance.dimension.avg-price": "Average price",
    "dataTablePerformance.dimension.avg-price-p": "Average promo price",
    "dataTablePerformance.dimension.barcode": "EAN",
    "dataTablePerformance.dimension.brand_desc": "Brand",
    "dataTablePerformance.dimension.brand_type_desc": "Brand type",
    "dataTablePerformance.dimension.ca": "Sales {{taxType}}",
    "dataTablePerformance.dimension.ca-hp": "Non promo sales",
    "dataTablePerformance.dimension.ca-p": "Promo sales",
    "dataTablePerformance.dimension.class_desc": "Class",
    "dataTablePerformance.dimension.gross_service_rate_downstream": "Gross downstream service rate",
    "dataTablePerformance.dimension.gross_service_rate_downstream_comparison": "Gross downstream service rate vs N-1",
    "dataTablePerformance.dimension.gross_service_rate_upstream": "Gross upstream service rate",
    "dataTablePerformance.dimension.gross_service_rate_upstream_comparison": "Gross upstream service rate vs N-1",
    "dataTablePerformance.dimension.item_desc": "Item",
    "dataTablePerformance.dimension.item_key": "Item",
    "dataTablePerformance.dimension.main_holding_desc": "Holding",
    "dataTablePerformance.dimension.net_service_rate_downstream": "Net downstream service rate",
    "dataTablePerformance.dimension.net_service_rate_downstream_comparison": "Net downstream service rate vs N-1",
    "dataTablePerformance.dimension.net_service_rate_upstream": "Net upstream service rate",
    "dataTablePerformance.dimension.net_service_rate_upstream_comparison": "Net upstream service rate vs N-1",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount": "Nielsen Market share (in value)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount_comparison": "Nielsen Market share (in value) vs N-1 (pp)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity": "Nielsen Market share (in quantity)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity_comparison": "Nielsen Market share (in quantity) vs N-1 (pp)",
    "dataTablePerformance.dimension.p-ca-p": "Promo pressure (in value)",
    "dataTablePerformance.dimension.p-uvc-p": "Promo pressure (in quantity)",
    "dataTablePerformance.dimension.sales_amount": "Sales {{taxType}}",
    "dataTablePerformance.dimension.sales_amount_benchmark": "Sales Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_comparison": "Sales {{taxType}} vs N-1",
    "dataTablePerformance.dimension.sales_amount_promo_percent": "Promo pressure (in sales)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_benchmark": "Promo pressure (in sales) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_comparison": "Promo pressure (in sales) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_amount_with_promo": "Sales {{taxType}} with promo",
    "dataTablePerformance.dimension.sales_amount_with_promo_benchmark": "Sales with promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_with_promo_comparison": "Sales {{taxType}} promo. vs N-1",
    "dataTablePerformance.dimension.sales_amount_without_promo": "Sales {{taxType}} without promo",
    "dataTablePerformance.dimension.sales_amount_without_promo_benchmark": "Sales without promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_without_promo_comparison": "Sales {{taxType}} non-promo. vs N-1",
    "dataTablePerformance.dimension.sales_possession_rate": "Possession rate on sales",
    "dataTablePerformance.dimension.sales_possession_rate_comparison": "Possession rate on sales vs N-1",
    "dataTablePerformance.dimension.sales_quantity": "Quantity",
    "dataTablePerformance.dimension.sales_quantity_benchmark": "Quantity Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_comparison": "Quantity vs N-1",
    "dataTablePerformance.dimension.sales_quantity_promo_percent": "Promo pressure (in units)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_benchmark": "Promo pressure (in units) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_comparison": "Promo pressure (in units) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_quantity_with_promo": "Quantity with promo",
    "dataTablePerformance.dimension.sales_quantity_with_promo_benchmark": "Quantity with promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_with_promo_comparison": "Quantity promo. vs N-1",
    "dataTablePerformance.dimension.sales_quantity_without_promo": "Quantity without promo",
    "dataTablePerformance.dimension.sales_quantity_without_promo_benchmark": "Quantity without promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_without_promo_comparison": "Quantity non-promo. vs N-1",
    "dataTablePerformance.dimension.store_chain_type_desc": "Chain type",
    "dataTablePerformance.dimension.store_key": "Store",
    "dataTablePerformance.dimension.store_region_desc": "Region",
    "dataTablePerformance.dimension.store_stock_quantity": "Store stock (in units)",
    "dataTablePerformance.dimension.store_stock_value": "Store stock (w/o tax)",
    "dataTablePerformance.dimension.sub_class_desc": "Sub-class",
    "dataTablePerformance.dimension.supplier_desc": "Local supplier",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount": "Carrefour market share (in value)",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount_comparison": "Carrefour market share (in value) vs N-1 (pp)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity": "Carrefour market share (in quantity)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity_comparison": "Carrefour market share (in quantity) vs N-1 (pp)",
    "dataTablePerformance.dimension.top_flop_ca_barcode": "Top & Flop 10000 of EAN (in value)",
    "dataTablePerformance.dimension.top_flop_ca_item_key": "Top & Flop 10000 of items (in value)",
    "dataTablePerformance.dimension.top_flop_uvc_barcode": "Top & Flop 10000 of EAN (in quantity)",
    "dataTablePerformance.dimension.top_flop_uvc_item_key": "Top & Flop 10000 of items (in quantity)",
    "dataTablePerformance.dimension.total": "Total",
    "dataTablePerformance.dimension.total_stock_quantity": "Total stock (in units)",
    "dataTablePerformance.dimension.total_stock_value": "Total stock (w/o tax)",
    "dataTablePerformance.dimension.uvc": "Quantity",
    "dataTablePerformance.dimension.uvc-hp": "Non promo quantity",
    "dataTablePerformance.dimension.uvc-p": "Promo quantity",
    "dataTablePerformance.dimension.warehouse_stock_quantity": "Warehouse stock (in units)",
    "dataTablePerformance.dimension.warehouse_stock_value": "Warehouse stock (w/o tax)",
    "dataTablePerformance.excelExport": "Export in Excel",
    "dataTablePerformance.subtitle": "Select below at least 1 dimension on which you would like to see the performance of the perimeter selected above broken down.",
    "dataTablePerformance.title": "Performance breakdown",
    "email.acceptText": "If this user is part of your entity, please accept his request directly in the tool via",
    "email.accountActivated": "Your account has been activated. You can now access the platform via the following link",
    "email.andConfigure": "and start <b>setting up your account</b>",
    "email.appName": "Carrefour Links Growth",
    "email.catmanTeam": "The Carrefour Links Growth Team",
    "email.downloadYourExport": "Here is the download link for your export",
    "email.exportAvailability": "This link is available for 15 days.",
    "email.exportFailed": "Sorry, your file could not be uploaded due to a technical error. <br/> Please run the export again from the platform.",
    "email.exportFileName": "Performance breakdown",
    "email.forAnyQuestionReferTo": "For any question, you can <b>contact your referent</b>",
    "email.hello": "Hello",
    "email.orReferToDoc": "or refer to the documentation",
    "email.otherwisePlease": "Otherwise, please <b>refuse it</b>",
    "email.rejectText": "Your request for access to Carrefour Links Growth has been refused",
    "email.requestAccess": "has requested access to the platform <span style=\\\"color: #da8796; font-weight: bold\\\">Carrefour Links Growth.</span>",
    "email.seeYouSoon": "See you soon on the platform",
    "email.subject.accessRejected": "Your request for access to Carrefour Links Growth has been refused",
    "email.subject.accessRequest": "A user wants to access Carrefour Links Growth",
    "email.subject.accessValidated": "Welcome to the Carrefour Links Growth platform!",
    "email.subject.exportError": "An error occured during your export",
    "email.subject.exportSuccess": "Your export is ready",
    "email.subject.projectInvitation": "You have been invited to join a project on Carrefour Links Growth",
    "email.thisLink": "this link",
    "email.welcome": "Welcome to the Carrefour Links Growth platform",
    "excelExport.confirmation": "Export in progress\nYou will receive your file by e-mail",
    "excelExport.CTA": "Export in Excel",
    "excelExport.desc": "You will receive an email with a link to download your .xlsx file.\nDuration generally observed (1 to 2 min)",
    "excelExport.dimension.average_price": "Average price",
    "excelExport.dimension.average_price_benchmark": "Average price Carrefour benchmark",
    "excelExport.dimension.average_price_comparison": "Average price vs N-1",
    "excelExport.dimension.average_price_with_promo": "Average promo price",
    "excelExport.dimension.average_price_with_promo_benchmark": "Averag promo price Carrefour benchmark",
    "excelExport.dimension.average_price_with_promo_comparison": "Average promo price vs N-1",
    "excelExport.dimension.average_weekly_sales_amount": "Av. weekly sales (in sales {{taxType}})",
    "excelExport.dimension.average_weekly_sales_amount_benchmark": "Av. weekly sales (in sales) Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_amount_comparison": "Av. weekly sales (in sales {{taxType}}) vs N-1",
    "excelExport.dimension.average_weekly_sales_quantity": "Av. weekly sales (in units)",
    "excelExport.dimension.average_weekly_sales_quantity_benchmark": "Av. weekly sales (in units) Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_quantity_comparison": "Av. weekly sales (in units) vs N-1",
    "excelExport.dimension.avg-price": "Average price",
    "excelExport.dimension.avg-price-p": "Average promo price",
    "excelExport.dimension.barcode": "EAN code",
    "excelExport.dimension.barcode_desc": "EAN desc.",
    "excelExport.dimension.brand_desc": "Brand",
    "excelExport.dimension.brand_type_desc": "Brand type",
    "excelExport.dimension.ca": "Sales {{taxType}}",
    "excelExport.dimension.ca-hp": "Non promo sales",
    "excelExport.dimension.ca-p": "Promo sales",
    "excelExport.dimension.class_desc": "Class",
    "excelExport.dimension.gross_service_rate_downstream": "Gross downstream service rate",
    "excelExport.dimension.gross_service_rate_downstream_comparison": "Gross downstream service rate vs N-1 (pp)",
    "excelExport.dimension.gross_service_rate_upstream": "Gross upstream service rate",
    "excelExport.dimension.gross_service_rate_upstream_comparison": "Gross upstream service rate vs N-1 (pp)",
    "excelExport.dimension.item_desc": "Item desc.",
    "excelExport.dimension.item_key": "Item code",
    "excelExport.dimension.main_holding_desc": "Holding desc.",
    "excelExport.dimension.main_holding_key": "Holding code",
    "excelExport.dimension.net_service_rate_downstream": "Net downstream service rate",
    "excelExport.dimension.net_service_rate_downstream_comparison": "Net downstream service rate vs N-1 (pp)",
    "excelExport.dimension.net_service_rate_upstream": "Net upstream service rate",
    "excelExport.dimension.net_service_rate_upstream_comparison": "Net upstream service rate vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_amount": "Nielsen Market share (in value)",
    "excelExport.dimension.nielsen_market_share_sales_amount_comparison": "Nielsen Market share (in value) vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_quantity": "Nielsen Market share (in quantity)",
    "excelExport.dimension.nielsen_market_share_sales_quantity_comparison": "Nielsen Market share (in quantity) vs N-1 (pp)",
    "excelExport.dimension.p-ca-p": "Promo pressure (in value)",
    "excelExport.dimension.p-uvc-p": "Promo pressure (in quantity)",
    "excelExport.dimension.sales_amount": "Sales {{taxType}}",
    "excelExport.dimension.sales_amount_benchmark": "Sales Carrefour benchmark",
    "excelExport.dimension.sales_amount_comparison": "Sales {{taxType}} vs N-1",
    "excelExport.dimension.sales_amount_promo_percent": "Promo pressure (in sales)",
    "excelExport.dimension.sales_amount_promo_percent_benchmark": "Promo pressure (in sales) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_amount_promo_percent_comparison": "Promo pressure (in sales) vs N-1 (pp)",
    "excelExport.dimension.sales_amount_with_promo": "Sales {{taxType}} with promo",
    "excelExport.dimension.sales_amount_with_promo_benchmark": "Sales with promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_with_promo_comparison": "Sales {{taxType}} promo. vs N-1",
    "excelExport.dimension.sales_amount_without_promo": "Sales {{taxType}} without promo",
    "excelExport.dimension.sales_amount_without_promo_benchmark": "Sales without promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_without_promo_comparison": "Sales {{taxType}} non-promo. vs N-1",
    "excelExport.dimension.sales_possession_rate": "Possession rate on sales",
    "excelExport.dimension.sales_possession_rate_comparison": "Possession rate on sales vs N-1 (pp)",
    "excelExport.dimension.sales_quantity": "Quantity",
    "excelExport.dimension.sales_quantity_benchmark": "Quantity Carrefour benchmark",
    "excelExport.dimension.sales_quantity_comparison": "Quantity vs N-1",
    "excelExport.dimension.sales_quantity_promo_percent": "Promo pressure (in units)",
    "excelExport.dimension.sales_quantity_promo_percent_benchmark": "Promo pressure (in units) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_quantity_promo_percent_comparison": "Promo pressure (in units) vs N-1 (pp)",
    "excelExport.dimension.sales_quantity_with_promo": "Quantity with promo",
    "excelExport.dimension.sales_quantity_with_promo_benchmark": "Quantity with promo Carrefour benchmark",
    "excelExport.dimension.sales_quantity_with_promo_comparison": "Quantity promo. vs N-1",
    "excelExport.dimension.sales_quantity_without_promo": "Quantity without promo",
    "excelExport.dimension.sales_quantity_without_promo_benchmark": "Quantity without promo Carrefour benchmark",
    "excelExport.dimension.sales_quantity_without_promo_comparison": "Quantity non-promo. vs N-1",
    "excelExport.dimension.store_chain_type_desc": "Chain type",
    "excelExport.dimension.store_desc": "Store desc.",
    "excelExport.dimension.store_key": "Store code",
    "excelExport.dimension.store_region_desc": "Region",
    "excelExport.dimension.sub_class_desc": "Sub-class",
    "excelExport.dimension.supplier_desc": "Local supplier desc.",
    "excelExport.dimension.supplier_key": "Local supplier code",
    "excelExport.dimension.supplier_market_share_sales_amount": "Carrefour market share (in value)",
    "excelExport.dimension.supplier_market_share_sales_amount_comparison": "Carrefour market share (in value) vs N-1 (pp)",
    "excelExport.dimension.supplier_market_share_sales_quantity": "Carrefour market share (in quantity)",
    "excelExport.dimension.supplier_market_share_sales_quantity_comparison": "Carrefour market share (in quantity) vs N-1 (pp)",
    "excelExport.dimension.top_flop_ca_barcode": "Top & Flop 10000 of EAN (in value)",
    "excelExport.dimension.top_flop_ca_item_key": "Top & Flop 10000 of items (in value)",
    "excelExport.dimension.top_flop_uvc_barcode": "Top & Flop 10000 of EAN (in quantity)",
    "excelExport.dimension.top_flop_uvc_item_key": "Top & Flop 10000 of items (in quantity)",
    "excelExport.dimension.total": "Total",
    "excelExport.dimension.uvc": "Quantity",
    "excelExport.dimension.uvc-hp": "Non promo quantity",
    "excelExport.dimension.uvc-p": "Promo quantity",
    "excelExport.dimensionDesc": "Please select at least 1 dimension",
    "excelExport.dimensionTitle": "Dimension to be exported",
    "excelExport.error": "An error has occurred. Please try again.",
    "excelExport.flop": "Flop EAN",
    "excelExport.indicatorDesc": "Please select at least one indicator",
    "excelExport.indicatorTitle": "Indicators to be exported",
    "excelExport.nameTitle.average_price": "Average price",
    "excelExport.nameTitle.average_price_benchmark": "Average price Carrefour benchmark",
    "excelExport.nameTitle.average_price_comparison": "Average price vs N-1",
    "excelExport.nameTitle.average_price_with_promo": "Average promo price",
    "excelExport.nameTitle.average_price_with_promo_benchmark": "Averag promo price Carrefour benchmark",
    "excelExport.nameTitle.average_price_with_promo_comparison": "Average promo price vs N-1",
    "excelExport.nameTitle.average_weekly_sales_amount": "Av. weekly sales (in sales {{taxType}})",
    "excelExport.nameTitle.average_weekly_sales_amount_benchmark": "Av. weekly sales (in sales) Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_amount_comparison": "Av. weekly sales (in sales {{taxType}}) vs N-1",
    "excelExport.nameTitle.average_weekly_sales_quantity": "Av. weekly sales (in units)",
    "excelExport.nameTitle.average_weekly_sales_quantity_benchmark": "Av. weekly sales (in units) Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_quantity_comparison": "Av. weekly sales (in units) vs N-1",
    "excelExport.nameTitle.avg-price": "Average price",
    "excelExport.nameTitle.avg-price-p": "Average promo price",
    "excelExport.nameTitle.barcode": "EAN",
    "excelExport.nameTitle.brand_desc": "Brand",
    "excelExport.nameTitle.brand_type_desc": "Brand type",
    "excelExport.nameTitle.ca": "Sales {{taxType}}",
    "excelExport.nameTitle.ca-hp": "Non promo sales",
    "excelExport.nameTitle.ca-p": "Promo sales",
    "excelExport.nameTitle.class_desc": "Class",
    "excelExport.nameTitle.item_desc": "Item",
    "excelExport.nameTitle.item_key": "Item",
    "excelExport.nameTitle.main_holding_desc": "Holding",
    "excelExport.nameTitle.nielsen_market_share_sales_amount": "Nielsen Market share (in value)",
    "excelExport.nameTitle.nielsen_market_share_sales_amount_comparison": "Nielsen Market share (in value) vs N-1 (pp)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity": "Nielsen Market share (in quantity)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity_comparison": "Nielsen Market share (in quantity) vs N-1 (pp)",
    "excelExport.nameTitle.p-ca-p": "Promo pressure (in value)",
    "excelExport.nameTitle.p-uvc-p": "Promo pressure (in quantity)",
    "excelExport.nameTitle.sales_amount": "Sales {{taxType}}",
    "excelExport.nameTitle.sales_amount_benchmark": "Sales Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_comparison": "Sales {{taxType}} vs N-1",
    "excelExport.nameTitle.sales_amount_promo_percent": "Promo pressure (in sales)",
    "excelExport.nameTitle.sales_amount_promo_percent_benchmark": "Promo pressure (in sales) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_amount_promo_percent_comparison": "Promo pressure (in sales) vs N-1 (pp)",
    "excelExport.nameTitle.sales_amount_with_promo": "Sales {{taxType}} with promo",
    "excelExport.nameTitle.sales_amount_with_promo_benchmark": "Sales with promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_with_promo_comparison": "Sales {{taxType}} promo. vs N-1",
    "excelExport.nameTitle.sales_amount_without_promo": "Sales {{taxType}} without promo",
    "excelExport.nameTitle.sales_amount_without_promo_benchmark": "Sales without promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_without_promo_comparison": "Sales {{taxType}} non-promo. vs N-1",
    "excelExport.nameTitle.sales_possession_rate": "Possession rate on sales",
    "excelExport.nameTitle.sales_possession_rate_comparison": "Possession rate on sales vs N-1",
    "excelExport.nameTitle.sales_quantity": "Quantity",
    "excelExport.nameTitle.sales_quantity_benchmark": "Quantity Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_comparison": "Quantity vs N-1",
    "excelExport.nameTitle.sales_quantity_promo_percent": "Promo pressure (in units)",
    "excelExport.nameTitle.sales_quantity_promo_percent_benchmark": "Promo pressure (in units) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_quantity_promo_percent_comparison": "Promo pressure (in units) vs N-1 (pp)",
    "excelExport.nameTitle.sales_quantity_with_promo": "Quantity with promo",
    "excelExport.nameTitle.sales_quantity_with_promo_benchmark": "Quantity with promo Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_with_promo_comparison": "Quantity promo. vs N-1",
    "excelExport.nameTitle.sales_quantity_without_promo": "Quantity without promo",
    "excelExport.nameTitle.sales_quantity_without_promo_benchmark": "Quantity without promo Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_without_promo_comparison": "Quantity non-promo. vs N-1",
    "excelExport.nameTitle.store_chain_type_desc": "Chain type",
    "excelExport.nameTitle.store_key": "Store",
    "excelExport.nameTitle.store_region_desc": "Region",
    "excelExport.nameTitle.sub_class_desc": "Sub-class",
    "excelExport.nameTitle.supplier_desc": "Local supplier",
    "excelExport.nameTitle.supplier_market_share_sales_amount": "Carrefour market share (in value)",
    "excelExport.nameTitle.supplier_market_share_sales_amount_comparison": "Carrefour market share (in value) vs N-1 (pp)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity": "Carrefour market share (in quantity)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity_comparison": "Carrefour market share (in quantity) vs N-1 (pp)",
    "excelExport.nameTitle.top_flop_ca_barcode": "Top & Flop 10000 of EAN (in value)",
    "excelExport.nameTitle.top_flop_ca_item_key": "Top & Flop 10000 of items (in value)",
    "excelExport.nameTitle.top_flop_uvc_barcode": "Top & Flop 10000 of EAN (in quantity)",
    "excelExport.nameTitle.top_flop_uvc_item_key": "Top & Flop 10000 of items (in quantity)",
    "excelExport.nameTitle.total": "Total",
    "excelExport.nameTitle.uvc": "Quantity",
    "excelExport.nameTitle.uvc-hp": "Non promo quantity",
    "excelExport.nameTitle.uvc-p": "Promo quantity",
    "excelExport.notificationInProgressMessage": "You will receive your file by e-mail.",
    "excelExport.notificationInProgressTitle": "Export in progress",
    "excelExport.scopeTitle": "Reminder of the selected perimeter",
    "excelExport.sendExport": "Receive the export by e-mail",
    "excelExport.title": "Performance breakdown",
    "excelExport.top": "Top EAN",
    "exportChecklistData.average_price": "Average price",
    "exportChecklistData.average_weekly_sales_amount": "Av. weekly sales (in sales {{taxType}})",
    "exportChecklistData.average_weekly_sales_quantity": "Av. weekly sales (in units)",
    "exportChecklistData.distribution": "Distribution",
    "exportChecklistData.gross_service_rate": "Gross service rate",
    "exportChecklistData.net_service_rate": "Net service rate",
    "exportChecklistData.nielsen_market_share_sales_amount": "Nielsen market share (in value)",
    "exportChecklistData.nielsen_market_share_sales_quantity": "Nielsen market share (in quantity)",
    "exportChecklistData.sales_amount": "Sales {{taxType}}",
    "exportChecklistData.sales_possession_rate": "Possession rate on sales",
    "exportChecklistData.sales_quantity": "Quantity",
    "exportChecklistData.supplier_market_share_sales_amount": "Carrefour market share (in value)",
    "exportChecklistData.supplier_market_share_sales_quantity": "Carrefour market share (in quantity)",
    "exportKpi.activity_type": "Activity type",
    "exportKpi.barcode": "EAN",
    "exportKpi.brand_key": "Brand",
    "exportKpi.brand_type_key": "Brand type",
    "exportKpi.class_key": "Class",
    "exportKpi.department_key": "Department",
    "exportKpi.dimension": "Dimension",
    "exportKpi.flopAmount_barcode": "Flop EAN (in value)",
    "exportKpi.flopAmount_item_key": "Flop Item (in value)",
    "exportKpi.flopQuantity_barcode": "Flop EAN (in quantity)",
    "exportKpi.flopQuantity_item_key": "Flop Item (in quantity)",
    "exportKpi.grp_class_key": "Group class",
    "exportKpi.HT": "Without tax",
    "exportKpi.interval": "Interval",
    "exportKpi.isWithTax": "With or without tax",
    "exportKpi.item_key": "Item",
    "exportKpi.main_holding_key": "Holding",
    "exportKpi.perimeter": "Perimeter",
    "exportKpi.sector_key": "Sector",
    "exportKpi.store_chain_type_key": "Chain type",
    "exportKpi.store_int_flag": "Integrated / Franchised",
    "exportKpi.store_key": "Store",
    "exportKpi.sub_class_key": "Sub-class",
    "exportKpi.supplier_key": "Local supplier",
    "exportKpi.top": "Top item",
    "exportKpi.topAmount_barcode": "Top EAN (in value)",
    "exportKpi.topAmount_item_key": "Top Item (in value)",
    "exportKpi.topQuantity_barcode": "Top EAN (in quantity)",
    "exportKpi.topQuantity_item_key": "Top Item (in quantity)",
    "exportKpi.TTC": "With tax",
    "filterPopin.activityType": "Activity type",
    "filterPopin.activityTypesData.E-Commerce": "Ecommerce",
    "filterPopin.activityTypesData.ECM": "Ecommerce",
    "filterPopin.activityTypesData.In store": "In store",
    "filterPopin.activityTypesData.STO": "In store",
    "filterPopin.brand": "Brand",
    "filterPopin.brandType": "Brand type",
    "filterPopin.chainType": "Chain type",
    "filterPopin.cpgVersion.brand": "Brands",
    "filterPopin.cpgVersion.localSupplierAndBrand": "Local supplier / Brand",
    "filterPopin.ean": "EAN",
    "filterPopin.hint.availableOnCarrefour": "Please use the Carrefour taxonomy",
    "filterPopin.hint.availableOnNielsen": "Please use the Nielsen taxonomy",
    "filterPopin.holding": "Holding",
    "filterPopin.holdingAndBrand": "Holding / Brand",
    "filterPopin.holdingAndLocalSupplier": "Holding / Local supplier",
    "filterPopin.holdingAndLocalSupplierAndBrand": "Holding / Local supplier / Brand",
    "filterPopin.integrateFranchised": "Integrated / franchised",
    "filterPopin.integrateFranchisedData.0": "Franchised",
    "filterPopin.integrateFranchisedData.1": "Integrated",
    "filterPopin.integrateFranchisedData.2": "Unknown",
    "filterPopin.integrateFranchisedData.2ESP": "Dark stores",
    "filterPopin.item": "Item",
    "filterPopin.localSupplier": "Local supplier",
    "filterPopin.nomenclature.carrefour": "Carrefour",
    "filterPopin.nomenclature.nielsen": "Nielsen",
    "filterPopin.nomenclature.title": "Taxonomy",
    "filterPopin.productNomenclature": "Product hierarchy",
    "filterPopin.productPlaceholder": "Please enter at least 4 characters in the search bar",
    "filterPopin.region": "Region",
    "filterPopin.searchBar": "Search for an item, brand, format...",
    "filterPopin.store": "Store",
    "filterPopin.title": "Filters",
    "General.Supplier_view": "Supplier View",
    "impersonateFrame.timeRemaining": "Time remaining",
    "likeForLike.infoBanner.lineEcommerce": "E-commerce.",
    "likeForLike.infoBanner.lineOne": "Stores that have not been open for a comparable number of days between n and n-1.",
    "likeForLike.infoBanner.lineTwo": "Stores that changed typology between year n and n-1 (format, status, country...).",
    "likeForLike.infoBanner.title": "In Like for Like mode, the analysis perimeter excludes:",
    "likeForLike.warningBanner.content": "Note: Some stores that have been closed for less than a month are included in the analysis scope on a monthly granularity basis.",
    "marketShareTableauEnbedding.info": "The content below is hosted on Tableau. Please authenticate and re-enter your filters.",
    "marketShareTableauEnbedding.subtitle": "To go further, access the Market share Performance Cockpit below.",
    "marketShareTableauEnbedding.title": "Market share summary",
    "Nav.Back": "Back",
    "Nav.Performance": "Performance",
    "Nav.Projects": "Projects",
    "onboardingConfirmation.confirmationButton": "Validate and discover the platform",
    "onboardingConfirmation.language": "Language",
    "onboardingConfirmation.languageEdit": "Modify",
    "onboardingConfirmation.perimeter": "Your default perimeter",
    "onboardingConfirmation.perimeterEdit": "Change your default perimeter",
    "onboardingConfirmation.subtitle": "Please check your settings",
    "onboardingConfirmation.title": "There's just one last step before you can discover the platform!",
    "onboardingLanguage.button": "Language",
    "onboardingLanguage.confirmationButton": "Confirm my choice and move on to the next step",
    "onboardingLanguage.subtitle": "First of all, please set your default language.",
    "onboardingLanguage.title": "Welcome to Carrefour Links Growth!",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.esp": "The e-commerce channel is excluded from the Like for like scope.",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.ita": "The e-commerce channel is excluded from the Like for like scope.",
    "onboardingPerimeter.activityType.subtitle": "Activity type",
    "onboardingPerimeter.activityType.title": "Which default activity type do you want to track performance on?",
    "onboardingPerimeter.chainType.subtitle": "Chain type",
    "onboardingPerimeter.chainType.title": "Which default chain type do you want to track performance on",
    "onboardingPerimeter.confirmationButton": "Confirm my choice and move on to the next stage",
    "onboardingPerimeter.granularity.buttonTitle": "Analysis granularity",
    "onboardingPerimeter.granularity.monthly": "Monthly",
    "onboardingPerimeter.granularity.title": "At what granularity do you want to monitor the performance of your perimeter? *",
    "onboardingPerimeter.granularity.weekly": "Weekly",
    "onboardingPerimeter.productHierarchy.button": "Add a product hierarchy",
    "onboardingPerimeter.productHierarchy.class": "Class",
    "onboardingPerimeter.productHierarchy.department": "Department",
    "onboardingPerimeter.productHierarchy.grpclass": "Group class",
    "onboardingPerimeter.productHierarchy.sector": "Sector",
    "onboardingPerimeter.productHierarchy.subclass": "Sub-class",
    "onboardingPerimeter.productHierarchy.subtitle": "Product hierarchy",
    "onboardingPerimeter.productHierarchy.title": "Which default product scope do you want to use?",
    "onboardingPerimeter.salesAmountFormat.buttonTitle": "KPI value",
    "onboardingPerimeter.salesAmountFormat.HT": "Without tax",
    "onboardingPerimeter.salesAmountFormat.title": "Do you want to use indicators in with or without taxes by default?",
    "onboardingPerimeter.salesAmountFormat.TTC": "With tax",
    "onboardingPerimeter.storePerimeter.allStores.content": "The perimeter includes all stores in the park.",
    "onboardingPerimeter.storePerimeter.allStores.title": "All stores",
    "onboardingPerimeter.storePerimeter.likeForLike.full.content": "The scope excludes stores with less than one year of seniority, closed for more than a year, or having changed typology during the  analysis period.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.content": "The scope excludes stores that do not have a comparable number of opening days between periods n-1 and n. E-commerce is also excluded",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.close": "I understand",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.esp": "In Spain, the e-commerce purchasing channel is not available for Like for Like. To select the Like for like mode, please select the In-store only purchasing channel",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.ita": "In Italy, the e-commerce purchasing channel is not available for Like for Like. To select the Like for like mode, please select the In-store only purchasing channel",
    "onboardingPerimeter.storePerimeter.likeForLike.title": "Like for like",
    "onboardingPerimeter.storePerimeter.subtitle": "Store perimeter",
    "onboardingPerimeter.storePerimeter.title": "On which store park perimeter do you want to analyze the evolution of indicators ?",
    "onboardingPerimeter.subtitle": "Each time you log in, the performance monitoring indicators will be calculated and displayed for the perimeter you define at this stage. You can change this at any time in your account settings.",
    "onboardingPerimeter.timeframe.buttonTitle": "Time period",
    "onboardingPerimeter.timeframe.cumulative_to_date": "Rolling year to date",
    "onboardingPerimeter.timeframe.last_period": "Last period",
    "onboardingPerimeter.timeframe.title": "Over what time period would you like to see your performance indicators for each connection? *",
    "onboardingPerimeter.timeframe.year_to_date": "Year to date",
    "onboardingPerimeter.title": "Then define your default perimeter.",
    "performanceCarrefourCatman.salesAmountFormat.HT": "Without tax",
    "performanceCarrefourCatman.salesAmountFormat.nielsenIsWithTax": "In Nielsen taxonomy, sales are calculated inclusive of all taxes.",
    "performanceCarrefourCatman.salesAmountFormat.TTC": "With tax",
    "performanceCarrefourCatman.storePerimeter.allStores": "All stores",
    "performanceCarrefourCatman.storePerimeter.hintEcommerceNotAvailable": "The e-commerce purchasing channel is not available in like for like analysis.",
    "performanceCarrefourCatman.storePerimeter.hintLikeForLikeNotAvailableWithEcommerce": "The Like for like analysis is not available when the e-commerce channel is selected.",
    "performanceCarrefourCatman.storePerimeter.likeForLike": "Like for like",
    "performanceCarrefourCatman.storePerimeter.nielsenAllStores": "In Nielsen taxonomy, the Like for Like mode is not available.",
    "performanceCarrefourCatman.subtitleDistribution": "Distribution",
    "performanceCarrefourCatman.subtitlePossessionAndDistribution": "Possession and distribution",
    "performanceCarrefourCatman.subtitleSales": "Sales",
    "performanceCarrefourCatman.subtitleServiceRate": "Supply",
    "performanceCarrefourCatman.subtitleStock": "Stocks",
    "performanceCarrefourCatman.subtitleSupply": "Supply",
    "performanceCarrefourCatman.title": "Category performance",
    "performanceCarrefourCatmanCpgView.benchmark": "Show benchmark",
    "performanceCarrefourCatmanCpgView.title": "Supplier performance",
    "performanceCarrefourCatmanCpgView.warning": "You are currently viewing the page as",
    "salesAmount.barChart": "Sales amount evolution {{taxType}} vs N-1",
    "salesAmount.lineChart": "Breakdown of turnover {{taxType}}",
    "salesAmount.pieChart": "Sales performance {{taxType}}",
    "salesAmount.switchButton": "Switch to quantity",
    "salesAmount.title": "Sales performance {{taxType}}",
    "salesQuantity.barChart": "Change in quantities sold vs N-1",
    "salesQuantity.lineChart": "Breakdown of turnover",
    "salesQuantity.pieChart": "Quantity performance",
    "salesQuantity.switchButton": "Switch to value",
    "salesQuantity.title": "Quantity performance",
    "Scorecard.Average_price": "Av. price",
    "Scorecard.AWS": "Av. weekly sales",
    "scorecard.grossServiceRateDownstream.tooltip": "Share of orders delivered on time to the stores by the warehouses. The gross service rate does not take into account the reprocessing carried out by the operational teams. It is a ratio of quantities of items.",
    "scorecard.grossServiceRateUpstream.tooltip": "Share of orders delivered on time by suppliers to the warehouses. The gross service rate does not take into account the reprocessing carried out by the operational teams. It is a ratio of quantities of items.",
    "scorecard.marketshareUnavailable": "To display the Nielsen Market Share, please select the Nielsen mode and a  week or Nielsen period granularity.",
    "Scorecard.Nielsen_ Market_share_value": "Nielsen Market share (in value)",
    "Scorecard.Nielsen_ Market_share_volume": "Nielsen Market share (in quantity)",
    "Scorecard.Non-promo": "Non-promo",
    "Scorecard.Num_Distrib": "Numerical Distrib.",
    "scorecard.numdistUnavailable_barcode": "To display the numerical distribution, please select one product refrence and a monthly or weekly granularity.\nShare of stores that have sold the product reference.",
    "scorecard.numdistUnavailable_item_key": "To display the numerical distribution, please select one product refrence and a monthly or weekly granularity.\nShare of stores that have sold the product reference.",
    "scorecard.numericalDistribution.tooltip": "Share of stores that have sold the product reference. \nAvailable only when selecting one product reference and a monthly or weekly granularity.",
    "Scorecard.Pressure": "pressure",
    "Scorecard.Promo": "Promo.",
    "Scorecard.Quantity": "Quantity",
    "Scorecard.Sales _value": "Sales",
    "scorecard.salesPossessionRate.tooltip": "Share of product references sold compared to the theoretical assortment of stores.",
    "scorecard.serviceRateDownstream.tooltip": "Share of orders delivered on time to the stores by the warehouses. The net service rate takes into account the reprocessing done by the operational teams and is a ratio of quantities expressed in value. The gross service rate is a ratio of quantities of items.",
    "scorecard.serviceRateUpstream.tooltip": "Share of orders delivered on time by suppliers to the warehouses. The net service rate takes into account the reprocessing done by the operational teams and is a ratio of quantities expressed in value. The gross service rate is a ratio of quantities.",
    "scorecard.stockWarehouse.tooltip": "Stock value and quantity held in the warehouse. The stock value is always expressed excluding taxes. The stock quantity is expressed in sales units.",
    "scorecard.weighdistUnavailable_barcode": "To display the weighted distribution, please select one product refrence and a monthly or weekly granularity.\nRatio between sales generated by the category for all stores selling the product and sales generated by the category for all stores.",
    "scorecard.weighdistUnavailable_item_key": "To display the weighted distribution, please select one product refrence and a monthly or weekly granularity.\nRatio between sales generated by the category for all stores selling the product and sales generated by the category for all stores.",
    "scorecard.weightedDistribution.tooltip": "Ratio between sales generated by the category for all stores selling the product and sales generated by the category for all stores. \nAvailable only when selecting one product reference and a monthly or weekly granularity.",
    "Scorecard.Weigthed_Distrib": "Weighted Distrib.",
    "scorecardPerformance.averagePrice": "Av. price {{taxType}})",
    "scorecardPerformance.averagePricePromo": "Av. price promo {{taxType}}",
    "scorecardPerformance.averagePriceWithoutTax": "Av. price w/o tax",
    "scorecardPerformance.averagePriceWithoutTaxPromo": "Av. promo price w/o tax",
    "scorecardPerformance.averagePriceWithTax": "Av. price w/ tax",
    "scorecardPerformance.averagePriceWithTaxPromo": "Av. promo price w/ tax",
    "scorecardPerformance.averagePromoPrice": "Av. promo price {{taxType}})",
    "scorecardPerformance.averageWeeklySales": "Av. weekly sales {{taxType}}",
    "scorecardPerformance.averageWeeklySalesAmountWithoutTax": "Av. weekly sales (in sales w/o tax)",
    "scorecardPerformance.averageWeeklySalesAmountWithTax": "Av. weekly sales (in sales w/ tax)",
    "scorecardPerformance.averageWeeklySalesInUnits": "Av. weekly sales (in units)",
    "scorecardPerformance.averageWeeklySalesQuantity": "Av. weekly sales (in units)",
    "scorecardPerformance.carrefourMarketShareValue": "Carrefour Market share (in value)",
    "scorecardPerformance.carrefourMarketShareVolume": "Carrefour Market share (in quantity)",
    "scorecardPerformance.grossServiceRateDownstream": "Gross downstream service rate",
    "scorecardPerformance.grossServiceRateUpstream": "Gross upstream service rate",
    "scorecardPerformance.netServiceRateDownstream": "Net downstream service rate",
    "scorecardPerformance.netServiceRateUpstream": "Net upstream service rate",
    "scorecardPerformance.nielsenMarketShareValue": "Nielsen Market share (in value)",
    "scorecardPerformance.nielsenMarketShareVolume": "Nielsen Market share (in quantity)",
    "scorecardPerformance.nonavailable": "This indicator will be available shortly.",
    "scorecardPerformance.nonavailableperimeter": "Not available on this perimeter",
    "scorecardPerformance.nonPromo": "Non-promo",
    "scorecardPerformance.notAvailableForCarrefourTaxonomy": "Available in Nielsen taxonomy only",
    "scorecardPerformance.notAvailableForCurrentPerimeter": "Not available on this perimeter",
    "scorecardPerformance.notAvailableForNielsenTaxonomy": "Available in Carrefour taxonomy only",
    "scorecardPerformance.numericalDistribution": "Numerical Distrib.",
    "scorecardPerformance.pressure": "Pressure",
    "scorecardPerformance.promo": "Promo.",
    "scorecardPerformance.promoPressure": "Promo pressure",
    "scorecardPerformance.quantity": "Quantity",
    "scorecardPerformance.salesPossessionRate": "Possession rate on sales",
    "scorecardPerformance.salesValue": "Sales {{taxType}}",
    "scorecardPerformance.salesValueWithoutTax": "Sales w/o tax",
    "scorecardPerformance.salesValueWithTax": "Sales w/ tax",
    "scorecardPerformance.serviceRate": "Service rate",
    "scorecardPerformance.serviceRate.gross": "Gross",
    "scorecardPerformance.serviceRate.net": "Net",
    "scorecardPerformance.serviceRateDownstream": "Downstream service rate",
    "scorecardPerformance.serviceRateUpstream": "Upstream service rate",
    "scorecardPerformance.stockStore": "Store stock",
    "scorecardPerformance.stockStore.tooltip": "Stock value and quantity held in the store. The stock value is always expressed excluding taxes. The stock quantity is expressed in sales units.",
    "scorecardPerformance.stockStoreQuantity": "Store stock (in units)",
    "scorecardPerformance.stockStoreWithoutTax": "Store stock (w/o tax)",
    "scorecardPerformance.stockTotal": "Total stock",
    "scorecardPerformance.stockTotal.notAvailableWithPermissions": "Not available for your permission level",
    "scorecardPerformance.stockTotal.tooltip": "Sum of store and warehouse stock. The stock value is always expressed excluding taxes. The stock quantity is expressed in sales units.",
    "scorecardPerformance.stockTotalQuantity": "Total stock (in units)",
    "scorecardPerformance.stockTotalWithoutTax": "Total stock (w/o tax)",
    "scorecardPerformance.stockWarehouse": "Warehouse stock",
    "scorecardPerformance.stockWarehouseQuantity": "Warehouse stock (in units)",
    "scorecardPerformance.stockWarehouseWithoutTax": "Warehouse stock (w/o tax)",
    "scorecardPerformance.weekAbreviation": "W",
    "scorecardPerformance.weightedDistribution": "Weighted Distrib.",
    "sidebar.Overview": "Overview",
    "sideMenu.accountManagement": "Account management",
    "sideMenu.administration": "Admin",
    "sideMenu.performance": "Category performance",
    "sideMenu.performanceReview": "Category performance",
    "sideMenu.projects": "Projects",
    "sideMenu.settings": "Access to settings",
    "sideMenu.shortcut": "Show side menu",
    "sideMenu.tools": "Tools",
    "sideMenu.userSupport": "Access to user support",
    "supplierPopin.description": "Select a supplier to gain access to its vision of the performance of its field of application",
    "supplierPopin.recentSuppliers": "Supplier wanted recently",
    "supplierPopin.searchBar": "Search for a supplier",
    "supplierPopin.title": "Access the supplier view",
    "support.contactSupport": "Contact support",
    "support.formSubmitted.goBack": "Go back to my navigation",
    "support.formSubmitted.text": "Your request has been received, and our support team will reply via email as soon as possible. Thank you for your patience.",
    "support.formSubmitted.title": "Thank you for reaching out to us!",
    "support.readOurDocumentation": "Read our documentation",
    "timeSelector.vsLastYear": "vs. last year",
    "timeSelectorPopin.apply": "Apply",
    "timeSelectorPopin.cumulative_to_date": "Rolling year to date",
    "timeSelectorPopin.customInterval": "Customised interval",
    "timeSelectorPopin.description": "Select the granularity of the analysis and a time interval.",
    "timeSelectorPopin.descriptionEssentialPackage": "Select the time interval for the analysis.",
    "timeSelectorPopin.granularity.daily": "Daily",
    "timeSelectorPopin.granularity.monthly": "Monthly",
    "timeSelectorPopin.granularity.nielsen": "Nielsen Period",
    "timeSelectorPopin.granularity.weekly": "Weekly",
    "timeSelectorPopin.last_period": "Last period",
    "timeSelectorPopin.subtitleGranularity": "Granularity of the analysis",
    "timeSelectorPopin.subtitleInterval": "Time interval",
    "timeSelectorPopin.title": "Time interval for the analysis",
    "timeSelectorPopin.year_to_date": "Year to date",
    "tools.accessButton": "Access",
    "tools.createButton": "Add a tool",
    "tools.empty.addButton": "Add a tool",
    "tools.empty.text.all.carrefour": "You don't have any tools on this page yet. {br} Please contact your administrator {br} or start adding them yourself.",
    "tools.empty.text.all.supplier": "You don't have any tools on this page yet. {br} Start by adding them yourself.",
    "tools.empty.text.custom": "You don't have any tools on this page yet. {br} Start by adding one.",
    "tools.empty.text.favorites": "You don't have any tools on this page yet. {br} Start by adding some to your favorites.",
    "tools.empty.text.recommended": "You don't have any tools on this page yet. {br} Please contact your administrator.",
    "tools.empty.title": "Welcome!",
    "tools.featured.title": "Featured",
    "tools.popup.add": "Add new shortcut",
    "tools.popup.cancel": "Cancel",
    "tools.popup.create.error.message": "The shortcut could not be created",
    "tools.popup.create.error.title": "An error has occurred",
    "tools.popup.create.success.message": "The shortcut has been successfully created",
    "tools.popup.create.success.title": "Shortcut created",
    "tools.popup.createTitle": "New shortcut",
    "tools.popup.delete.error.message": "The shortcut could not be deleted",
    "tools.popup.delete.error.title": "An error has occurred",
    "tools.popup.delete.success.message": "The shortcut has been successfully deleted",
    "tools.popup.delete.success.title": "Shortcut deleted",
    "tools.popup.deleteButton": "Delete",
    "tools.popup.deleteDescription": "Do you really want to delete the shortcut?",
    "tools.popup.deleteTitle": "Delete shortcut",
    "tools.popup.description": "Description",
    "tools.popup.descriptionPlaceholder": "Description of the tool",
    "tools.popup.form.invalidUrl": "The URL provided is invalid",
    "tools.popup.form.notEmpty": "This field is required",
    "tools.popup.form.tooLong": "The description must not exceed 300 characters",
    "tools.popup.keepButton": "Keep",
    "tools.popup.label": "Shortcut name",
    "tools.popup.link": "Link",
    "tools.popup.shortcut": "Shortcut",
    "tools.popup.update.error.message": "The shortcut could not be updated",
    "tools.popup.update.error.title": "An error has occurred",
    "tools.popup.update.success.message": "The shortcut has been successfully updated",
    "tools.popup.update.success.title": "Shortcut updated",
    "tools.popup.updateTitle": "Modify shortcut",
    "tools.title": "Tools & Services",
    "toolsFrA3distrib.description": "View competitor catalogues",
    "toolsFrA3distrib.title": "A3 Distrib",
    "toolsFrAgathe.description": "Set up my assortment",
    "toolsFrAgathe.title": "Agathe",
    "toolsFrApoline.description": "Set up my promotional operations",
    "toolsFrApoline.title": "Apoline",
    "toolsFrAssortmentBuilder.description": "Define my product range",
    "toolsFrAssortmentBuilder.title": "Assortment Builder",
    "toolsFrBasePhoto.description": "View my product images",
    "toolsFrBasePhoto.title": "Base Photo",
    "toolsFrBcpNoyau.description": "View the attributes of my products",
    "toolsFrBcpNoyau.title": "BCP Noyau",
    "toolsFrDataInsights.description": "Extract performance reports",
    "toolsFrDataInsights.title": "Data Insigths",
    "toolsFrMemory.description": "Access the management category modules",
    "toolsFrMemory.title": "Memory",
    "toolsFrNielsen.description": "View weekly market shares",
    "toolsFrNielsen.title": "Nielsen PDM Hebdo PGC",
    "toolsFrPilotagePerfAlimentaire.description": "View service rates",
    "toolsFrPilotagePerfAlimentaire.title": "Pilotage de la Performance Alimentaire",
    "toolsItAnalytics.description": "Access to dashboards",
    "toolsItAnalytics.title": "Analytics Italia website",
    "toolsItMicrostrategy.description": "Extract performance reports",
    "toolsItMicrostrategy.title": "Report Builder/Microstrategy",
    "userSettings.confirmation": "Confirm my selection",
    "userSettings.globalInformation.email": "Email",
    "userSettings.globalInformation.name": "Full name",
    "userSettings.globalInformation.role": "Role",
    "userSettings.globalInformation.subtitle": "Manage your information and login details",
    "userSettings.globalInformation.title": "Account details",
    "userSettings.logout.button": "Logout",
    "userSettings.logout.subtitle": "Logging off the platform",
    "userSettings.logout.title": "Logout",
    "userSettings.perimeter.subtitle": "Manage your default perimeter",
    "userSettings.perimeter.title": "Default perimeter",
    "userSettings.settings": "Your Profile",
    "userSettings.title": "Account settings",
    "userTools.data.a3-distrib.description": "Consult competitor catalogs",
    "userTools.data.a3-distrib.name": "A3 Distrib",
    "userTools.data.agathe.description": "Configure my assortment",
    "userTools.data.agathe.name": "Agathe",
    "userTools.data.analytics-italia-website.description": "Access dashboards",
    "userTools.data.analytics-italia-website.name": "Analytics Italia website",
    "userTools.data.apoline.description": "Configure my promotional operations",
    "userTools.data.apoline.name": "Apoline",
    "userTools.data.assortment-builder.description": "Define my assortment",
    "userTools.data.assortment-builder.name": "Assortment Builder",
    "userTools.data.base-photo.description": "Consult product visuals",
    "userTools.data.base-photo.name": "Base Photo",
    "userTools.data.bcp-noyau.description": "Consult product attributes",
    "userTools.data.bcp-noyau.name": "BCP Noyau",
    "userTools.data.cockpit-de-performance.description": "Single point of entry for market share tracking",
    "userTools.data.cockpit-de-performance.name": "Performance cockpit",
    "userTools.data.data-insights.description": "Extract performance reports",
    "userTools.data.data-insights.name": "Data Insights",
    "userTools.data.memory.description": "Access category management modules",
    "userTools.data.memory.name": "Memory",
    "userTools.data.microstrategy-web.name": "Microstrategy WEB",
    "userTools.data.nielsen-pdm-hebdo-pgc.description": "Consult weekly market shares",
    "userTools.data.nielsen-pdm-hebdo-pgc.name": "Nielsen PDM Weekly PGC",
    "userTools.data.pilotage-de-la-performance-alimentaire.description": "Consult service rates",
    "userTools.data.pilotage-de-la-performance-alimentaire.name": "Food Performance Management",
    "userTools.data.rcm-circuitos.name": "RCM Circuitos",
    "userTools.data.rcm-referencial.name": "RCM referencial",
    "userTools.data.report-builder/microstrategy.description": "Extract performance reports",
    "userTools.data.report-builder/microstrategy.name": "Report Builder/Microstrategy",
    "userTools.data.reporting-digital-mercancias.name": "Reporting digital Mercancías",
    "userTools.filters.all": "All",
    "userTools.filters.favorites": "My favorites",
    "userTools.link": "Access",
    "userTools.tags.custom": "Added by me",
    "userTools.tags.recommended": "Recommended",
    "userTools.title": "Tools & services",
    "viewAsSupplier.action": "Action",
    "viewAsSupplier.choose": "Select",
    "viewAsSupplier.noSupplierWithPlatformAccess": "No supplier has yet subscribed to the platform.",
    "viewAsSupplier.searchSupplier": "Search a supplier",
    "viewAsSupplier.selectSupplier": "Please select a supplier from those with access to the platform",
    "viewAsSupplier.supplier": "Supplier"
  },
  "fr": {
    "accountCreationPage.companySelection": "Veuillez sélectionner à quelle entreprise vous appartenez",
    "accountCreationPage.information": "Si votre entreprise n'apparait pas, c'est qu'elle n'existe pas dans nos systèmes.",
    "accountCreationPage.subtitle": "Suivez les performances de votre catégorie et collaborez avec vos fournisseurs sur une plateforme unique.",
    "accountCreationPage.validateCompanyButton": "Envoyer ma demande d'accès",
    "accountManagerSettings.activationDate": "Date d'activation",
    "accountManagerSettings.dateRequest": "Demande reçu le",
    "accountManagerSettings.listTitle": "Liste des utilisateurs",
    "accountManagerSettings.noPendingRequest": "Aucune demande en attente",
    "accountManagerSettings.requestTitle": "Nouvelles demandes",
    "accountManagerSettings.role.ACCOUNT_MANAGER": "Account Manager",
    "accountManagerSettings.role.ADMIN": "Admin",
    "accountManagerSettings.role.METRIC_VIEWER": "Metric Viewer",
    "accountManagerSettings.role.PROJECT_MANAGER": "Project Manager",
    "accountManagerSettings.role.PROJECT_VIEWER": "Project Viewer",
    "accountManagerSettings.title": "Utilisateurs actifs",
    "accountWaitingPage.accountReject": "Nous sommes désolés, il semblerait que vous n'ayez pas accès à la plateforme.",
    "accountWaitingPage.accountRejectSubtitle": "Pour plus d'informations, vous pouvez contacter votre référent",
    "accountWaitingPage.subtitle": "Surveillez régulièrement votre boite de réception : nous vous enverrons un email lorsque votre demande aura été approuvée par le référent de votre entreprise. Pour plus d'informations, vous pouvez contacter votre référent",
    "accountWaitingPage.title": "Votre demande d'accès a été envoyée avec succès !",
    "admin.activeUsers": "Utilisateurs actifs",
    "admin.filter.globalSearch.placeholder": "Rechercher par nom d'utilisateur, e-mail, pays, rôle",
    "admin.helperText": "Trouvez l'utilisateur que vous cherchez et cliquez sur usurper pour voir et agir en son nom.",
    "admin.impersonateFrame.stop": "Arrêter l'usurpation",
    "admin.impersonationImpossibleIfAdmin": "Un rôle Admin ne peut être usurpé",
    "admin.popin.button.start": "Démarrer la session en tant que cet utilisateur",
    "admin.popin.title": "Session d'usurpation",
    "admin.popin.warning": "En utilisant cette fonction, vous naviguerez comme si vous étiez cet utilisateur, toutes les actions seront enregistrées comme si vous étiez cette personne. Utilisez-le avec prudence.",
    "admin.title": "Tableau de bord administrateur",
    "admin.userList.cell.impersonate": "Usurper",
    "admin.userList.column.actions": "Actions",
    "admin.userList.column.holdingCountry": "Holding/Country",
    "admin.userList.column.roles": "Rôle(s)",
    "admin.userList.column.user": "Utilisateur",
    "authenticationPage.emailPassword": "Se connecter avec un e-mail",
    "authenticationPage.error": "Il semblerait que votre entreprise n'existe pas dans nos systèmes.",
    "authenticationPage.google": "Se connecter avec Google",
    "authenticationPage.microsoft": "Se connecter avec Microsoft",
    "authenticationPage.popin.continue": "Continuer",
    "authenticationPage.popin.email": "E-mail",
    "authenticationPage.popin.email.invalid": "Format d'e-mail invalide",
    "authenticationPage.popin.email.notEmpty": "E-mail requis",
    "authenticationPage.popin.email.subtitle": "Entrez votre e-mail et mot de passe",
    "authenticationPage.popin.email.title": "Se connecter avec un e-mail",
    "authenticationPage.popin.forgotPassword": "Mot de passe oublié?",
    "authenticationPage.popin.goBack": "Retour",
    "authenticationPage.popin.invalidCredential": "E-mail ou mot de passe invalide",
    "authenticationPage.popin.password": "Mot de passe",
    "authenticationPage.popin.password.notEmpty": "Mot de passe requis",
    "authenticationPage.popin.reset-sent": "Un e-mail vous a été envoyé. Veuillez cliquer sur le lien pour réinitialiser votre mot de passe",
    "authenticationPage.popin.reset.subtitle": "Entrez l'adresse e-mail pour réinitialiser votre mot de passe",
    "authenticationPage.popin.reset.title": "Réinitialiser votre mot de passe",
    "authenticationPage.popin.send": "Envoyer",
    "authenticationPage.subtitle": "Suivez les performances de votre catégorie et collaborez avec vos fournisseurs sur une plateforme unique.",
    "authenticationPage.title": "Bienvenue sur Carrefour Links Growth !",
    "chart.noData.content": "sur la période sélectionnée",
    "chart.noData.title": "Aucune donnée disponible",
    "commonLabel.administration": "Admin",
    "commonLabel.all": "Tout",
    "commonLabel.applicateFilters": "Appliquer ({{nbFiltersSelected}}) filtres",
    "commonLabel.applySelection": "Appliquer la sélection",
    "commonLabel.back": "Retour",
    "commonLabel.cancel": "Annuler",
    "commonLabel.complete": "complète",
    "commonLabel.confirm": "Accepter la demande",
    "commonLabel.countryFrance": "France",
    "commonLabel.countryItaly": "Italie",
    "commonLabel.countrySpain": "Espagne",
    "commonLabel.dateFormat": "DD/MM/YYYY",
    "commonLabel.dateRange": "Plage de dates",
    "commonLabel.decline": "Décliner",
    "commonLabel.defaultFilterPerimeterIsReset": "Votre périmètre par défaut a été rétabli",
    "commonLabel.details": "Détails",
    "commonLabel.displayFilters": "Afficher le périmètre et les filtres",
    "commonLabel.error": "Une erreur est survenue lors du chargement des données",
    "commonLabel.errorData": "Une erreur est survenue",
    "commonLabel.errorMessage": "Veuillez réessayer ultérieurement ou contacter l'administrateur.",
    "commonLabel.ESP": "Espagne",
    "commonLabel.FRA": "France",
    "commonLabel.from": "Du",
    "commonLabel.hideFilters": "Cacher le périmètre et les filtres",
    "commonLabel.home": "Accueil",
    "commonLabel.ITA": "Italie",
    "commonLabel.lang.en": "Anglais",
    "commonLabel.lang.es": "Espagnol",
    "commonLabel.lang.fr": "Français",
    "commonLabel.lang.it": "Italien",
    "commonLabel.loadMore": "Afficher plus de lignes",
    "commonLabel.modifyFilters": "Modifier les filtres ({{nbFiltersSelected}})",
    "commonLabel.month.1": "Janvier",
    "commonLabel.month.10": "Octobre",
    "commonLabel.month.11": "Novembre",
    "commonLabel.month.12": "Décembre",
    "commonLabel.month.2": "Février",
    "commonLabel.month.3": "Mars",
    "commonLabel.month.4": "Avril",
    "commonLabel.month.5": "Mai",
    "commonLabel.month.6": "Juin",
    "commonLabel.month.7": "Juillet",
    "commonLabel.month.8": "Août",
    "commonLabel.month.9": "Septembre",
    "commonLabel.moreDetails": "Plus de détails",
    "commonLabel.next": "Suivant",
    "commonLabel.noData": "Aucune données",
    "commonLabel.noResult": "Aucun résultat",
    "commonLabel.notAvailable": "Cet indicateur sera disponible prochainement.",
    "commonLabel.or": "Ou",
    "commonLabel.performance": "Performance",
    "commonLabel.previous": "Précédent",
    "commonLabel.projects": "Projets",
    "commonLabel.reEnterFilters": "Attention, veuillez ressaisir vos filtres",
    "commonLabel.showFiltersAndPerimeter": "Afficher le périmètre et les filtres",
    "commonLabel.storePerimeter": "Périmètre magasin",
    "commonLabel.supplierView": "Accéder à la vision fournisseur",
    "commonLabel.taxonomy": "Taxonomie",
    "commonLabel.to": "au",
    "commonLabel.updatePerimeter": "Revenir sur votre périmètre",
    "commonLabel.validateMyProject": "Valider la création de mon projet",
    "commonLabel.value": "Valeur",
    "dataTable.sort": "Trier les données",
    "dataTablePerformance.dimension.average_price": "Prix moyen",
    "dataTablePerformance.dimension.average_price_benchmark": "Prix moyen Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_comparison": "Prix moyen vs N-1",
    "dataTablePerformance.dimension.average_price_with_promo": "Prix moyen promo",
    "dataTablePerformance.dimension.average_price_with_promo_benchmark": "Prix moyen promo Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_with_promo_comparison": "Prix moyen promo vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_amount": "VMH (CA {{taxType}})",
    "dataTablePerformance.dimension.average_weekly_sales_amount_benchmark": "VMH (CA) Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_amount_comparison": "VMH (CA) vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_quantity": "VMH (UVC)",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_benchmark": "VMH (UVC) Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_comparison": "VMH (UVC) vs N-1",
    "dataTablePerformance.dimension.avg-price": "Prix moyen",
    "dataTablePerformance.dimension.avg-price-p": "Prix moyen promo",
    "dataTablePerformance.dimension.barcode": "EAN",
    "dataTablePerformance.dimension.brand_desc": "Marque",
    "dataTablePerformance.dimension.brand_type_desc": "Triptyque",
    "dataTablePerformance.dimension.ca": "CA {{taxType}}",
    "dataTablePerformance.dimension.ca-hp": "CA {{taxType}} hors promo.",
    "dataTablePerformance.dimension.ca-p": "CA {{taxType}} promo.",
    "dataTablePerformance.dimension.class_desc": "Famille",
    "dataTablePerformance.dimension.gross_service_rate_downstream": "Taux de service brut aval",
    "dataTablePerformance.dimension.gross_service_rate_downstream_comparison": "Taux de service brut aval vs N-1",
    "dataTablePerformance.dimension.gross_service_rate_upstream": "Taux de service brut amont",
    "dataTablePerformance.dimension.gross_service_rate_upstream_comparison": "Taux de service brut amont vs N-1",
    "dataTablePerformance.dimension.item_desc": "Item",
    "dataTablePerformance.dimension.item_key": "Item",
    "dataTablePerformance.dimension.main_holding_desc": "Holding",
    "dataTablePerformance.dimension.net_service_rate_downstream": "Taux de service net aval",
    "dataTablePerformance.dimension.net_service_rate_downstream_comparison": "Taux de service net aval vs N-1",
    "dataTablePerformance.dimension.net_service_rate_upstream": "Taux de service net amont",
    "dataTablePerformance.dimension.net_service_rate_upstream_comparison": "Taux de service net amont vs N-1",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount": "Part de marché Nielsen (en CA)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount_comparison": "Part de marché Nielsen (en CA) vs N-1 (pp)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity": "Part de marché Nielsen (en UVC)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity_comparison": "Part de marché Nielsen (en UVC) vs N-1 (pp)",
    "dataTablePerformance.dimension.p-ca-p": "Poids CA {{taxType}} promo.",
    "dataTablePerformance.dimension.p-uvc-p": "Poids UVC promo.",
    "dataTablePerformance.dimension.sales_amount": "CA {{taxType}}",
    "dataTablePerformance.dimension.sales_amount_benchmark": "CA Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_comparison": "CA {{taxType}} vs N-1",
    "dataTablePerformance.dimension.sales_amount_promo_percent": "Poids CA {{taxType}} promo.",
    "dataTablePerformance.dimension.sales_amount_promo_percent_benchmark": "Poids CA {{taxType}} promo. Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_comparison": "Poids CA {{taxType}} promo. vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_amount_with_promo": "CA {{taxType}} promo.",
    "dataTablePerformance.dimension.sales_amount_with_promo_benchmark": "CA promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_with_promo_comparison": "CA {{taxType}} promo. vs N-1",
    "dataTablePerformance.dimension.sales_amount_without_promo": "CA {{taxType}} hors promo.",
    "dataTablePerformance.dimension.sales_amount_without_promo_benchmark": "CA hors promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_without_promo_comparison": "CA {{taxType}} hors promo. vs N-1",
    "dataTablePerformance.dimension.sales_possession_rate": "Taux de détention sur ventes",
    "dataTablePerformance.dimension.sales_possession_rate_comparison": "Taux de détention sur ventes vs N-1",
    "dataTablePerformance.dimension.sales_quantity": "UVC",
    "dataTablePerformance.dimension.sales_quantity_benchmark": "UVC Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_comparison": "UVC vs N-1",
    "dataTablePerformance.dimension.sales_quantity_promo_percent": "Poids UVC promo.",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_benchmark": "Poids UVC promo. Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_comparison": "Poids UVC promo. vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_quantity_with_promo": "UVC promo.",
    "dataTablePerformance.dimension.sales_quantity_with_promo_benchmark": "UVC promo. Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_with_promo_comparison": "UVC promo. vs N-1",
    "dataTablePerformance.dimension.sales_quantity_without_promo": "UVC hors promo.",
    "dataTablePerformance.dimension.sales_quantity_without_promo_benchmark": "UVC hors promo. Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_without_promo_comparison": "UVC hors promo. vs N-1",
    "dataTablePerformance.dimension.store_chain_type_desc": "Format",
    "dataTablePerformance.dimension.store_key": "Magasin",
    "dataTablePerformance.dimension.store_region_desc": "Région",
    "dataTablePerformance.dimension.store_stock_quantity": "Stock magasin (UVC)",
    "dataTablePerformance.dimension.store_stock_value": "Stock magasin (HT)",
    "dataTablePerformance.dimension.sub_class_desc": "Sous-famille",
    "dataTablePerformance.dimension.supplier_desc": "Fournisseur local",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount": "Part de marché Carrefour (en CA)",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount_comparison": "Part de marché Carrefour (en CA) vs N-1 (pp)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity": "Part de marché Carrefour (en UVC)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity_comparison": "Part de marché Carrefour (en UVC) vs N-1 (pp)",
    "dataTablePerformance.dimension.top_flop_ca_barcode": "Top & Flop 10000 des EAN (en CA)",
    "dataTablePerformance.dimension.top_flop_ca_item_key": "Top & Flop 10000 des items (en CA)",
    "dataTablePerformance.dimension.top_flop_uvc_barcode": "Top & Flop 10000 des EAN (en UVC)",
    "dataTablePerformance.dimension.top_flop_uvc_item_key": "Top & Flop 10000 des items (en UVC)",
    "dataTablePerformance.dimension.total": "Total",
    "dataTablePerformance.dimension.total_stock_quantity": "Stock total (UVC)",
    "dataTablePerformance.dimension.total_stock_value": "Stock total (HT)",
    "dataTablePerformance.dimension.uvc": "UVC",
    "dataTablePerformance.dimension.uvc-hp": "UVC hors promo.",
    "dataTablePerformance.dimension.uvc-p": "UVC promo.",
    "dataTablePerformance.dimension.warehouse_stock_quantity": "Stock entrepôt (UVC)",
    "dataTablePerformance.dimension.warehouse_stock_value": "Stock entrepôt (HT)",
    "dataTablePerformance.excelExport": "Exporter sur Excel",
    "dataTablePerformance.subtitle": "Sélectionnez ci-dessous au moins 1 dimension sur laquelle vous souhaitez voir décomposer la performance du périmètre sélectionné plus haut.",
    "dataTablePerformance.title": "Décomposition de la performance",
    "email.acceptText": "Si cet utilisateur fait partie de votre entité, veuillez accepter sa demande directement dans l'outil via",
    "email.accountActivated": "Votre demande d’accès à Carrefour Links Growth a été approuvée ! Vous pouvez désormais accéder à la plateforme via le lien suivant",
    "email.andConfigure": "et commencer à <b>paramétrer votre compte</b>",
    "email.appName": "Carrefour Links Growth",
    "email.catmanTeam": "L'équipe Carrefour Links Growth",
    "email.downloadYourExport": "Voici le lien de téléchargement de votre extraction",
    "email.exportAvailability": "Ce lien est disponible durant 15 jours.",
    "email.exportFailed": "Nous sommes désolés, votre fichier n'a pas pu être téléchargé en raison d'une erreur technique. <br/> Veuillez lancer de nouveau l'export depuis la plateforme.",
    "email.exportFileName": "Decomposition de la performance",
    "email.forAnyQuestionReferTo": "Pour toute question, vous pouvez <b>contacter votre référent</b>",
    "email.hello": "Bonjour",
    "email.orReferToDoc": "ou vous référer à la documentation",
    "email.otherwisePlease": "Sinon, veuillez <b>la refuser</b>",
    "email.rejectText": "Votre demande d'accès à Carrefour Links Growth a été refusée",
    "email.requestAccess": "a demandé accès à la plateforme <span style=\\\"color: #da8796; font-weight: bold\\\">Carrefour Links Growth.</span>",
    "email.seeYouSoon": "A très vite sur la plateforme",
    "email.subject.accessRejected": "Votre demande d’accès à Carrefour Links Growth a malheureusement été refusée.",
    "email.subject.accessRequest": "Un utilisateur souhaite accéder à Carrefour Links Growth",
    "email.subject.accessValidated": "Bienvenue sur la plateforme Carrefour Links Growth !",
    "email.subject.exportError": "Une erreur est survenue lors de votre export",
    "email.subject.exportSuccess": "Votre export est prêt !",
    "email.subject.projectInvitation": "Vous avez été invité à rejoindre un projet sur Carrefour Links Growth",
    "email.thisLink": "le lien suivant",
    "email.welcome": "Bienvenue sur la plateforme Carrefour Links Growth",
    "excelExport.confirmation": "Export en cours\nVous allez recevoir votre fichier par mail",
    "excelExport.CTA": "Exporter sur Excel",
    "excelExport.desc": "Vous recevrez par email un lien pour télécharger votre fichier .xlsx\nDurée généralement constatées (1 à 2 min)",
    "excelExport.dimension.average_price": "Prix moyen",
    "excelExport.dimension.average_price_benchmark": "Prix moyen Carrefour benchmark",
    "excelExport.dimension.average_price_comparison": "Prix moyen vs N-1",
    "excelExport.dimension.average_price_with_promo": "Prix moyen promo",
    "excelExport.dimension.average_price_with_promo_benchmark": "Prix moyen promo Carrefour benchmark",
    "excelExport.dimension.average_price_with_promo_comparison": "Prix moyen promo vs N-1",
    "excelExport.dimension.average_weekly_sales_amount": "VMH (CA {{taxType}})",
    "excelExport.dimension.average_weekly_sales_amount_benchmark": "VMH (CA) Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_amount_comparison": "VMH (CA) vs N-1",
    "excelExport.dimension.average_weekly_sales_quantity": "VMH (UVC)",
    "excelExport.dimension.average_weekly_sales_quantity_benchmark": "VMH (UVC) Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_quantity_comparison": "VMH (UVC) vs N-1",
    "excelExport.dimension.avg-price": "Prix moyen",
    "excelExport.dimension.avg-price-p": "Prix moyen promo",
    "excelExport.dimension.barcode": "EAN code",
    "excelExport.dimension.barcode_desc": "EAN desc.",
    "excelExport.dimension.brand_desc": "Marque",
    "excelExport.dimension.brand_type_desc": "Triptyque",
    "excelExport.dimension.ca": "CA {{taxType}}",
    "excelExport.dimension.ca-hp": "CA {{taxType}} hors promo.",
    "excelExport.dimension.ca-p": "CA {{taxType}} promo.",
    "excelExport.dimension.class_desc": "Famille",
    "excelExport.dimension.gross_service_rate_downstream": "Taux de service brut aval",
    "excelExport.dimension.gross_service_rate_downstream_comparison": "Taux de service brut aval vs N-1 (pp)",
    "excelExport.dimension.gross_service_rate_upstream": "Taux de service brut amont",
    "excelExport.dimension.gross_service_rate_upstream_comparison": "Taux de service brut amont vs N-1 (pp)",
    "excelExport.dimension.item_desc": "Item desc.",
    "excelExport.dimension.item_key": "Item code",
    "excelExport.dimension.main_holding_desc": "Holding desc.",
    "excelExport.dimension.main_holding_key": "Holding code",
    "excelExport.dimension.net_service_rate_downstream": "Taux de service net aval",
    "excelExport.dimension.net_service_rate_downstream_comparison": "Taux de service net aval vs N-1 (pp)",
    "excelExport.dimension.net_service_rate_upstream": "Taux de service net amont",
    "excelExport.dimension.net_service_rate_upstream_comparison": "Taux de service net amont vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_amount": "Part de marché Nielsen (en CA)",
    "excelExport.dimension.nielsen_market_share_sales_amount_comparison": "Part de marché Nielsen (en CA) vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_quantity": "Part de marché Nielsen (en UVC)",
    "excelExport.dimension.nielsen_market_share_sales_quantity_comparison": "Part de marché Nielsen (en UVC) vs N-1 (pp)",
    "excelExport.dimension.p-ca-p": "Poids CA {{taxType}} promo.",
    "excelExport.dimension.p-uvc-p": "Poids UVC promo.",
    "excelExport.dimension.sales_amount": "CA {{taxType}}",
    "excelExport.dimension.sales_amount_benchmark": "CA Carrefour benchmark",
    "excelExport.dimension.sales_amount_comparison": "CA {{taxType}} vs N-1",
    "excelExport.dimension.sales_amount_promo_percent": "Poids CA {{taxType}} promo.",
    "excelExport.dimension.sales_amount_promo_percent_benchmark": "Poids CA {{taxType}} promo. Carrefour benchmark (pp)",
    "excelExport.dimension.sales_amount_promo_percent_comparison": "Poids CA {{taxType}} promo. vs N-1 (pp)",
    "excelExport.dimension.sales_amount_with_promo": "CA {{taxType}} promo.",
    "excelExport.dimension.sales_amount_with_promo_benchmark": "CA promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_with_promo_comparison": "CA {{taxType}} promo. vs N-1",
    "excelExport.dimension.sales_amount_without_promo": "CA {{taxType}} hors promo.",
    "excelExport.dimension.sales_amount_without_promo_benchmark": "CA hors promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_without_promo_comparison": "CA {{taxType}} hors promo. vs N-1",
    "excelExport.dimension.sales_possession_rate": "Taux de détention sur ventes",
    "excelExport.dimension.sales_possession_rate_comparison": "Taux de détention sur ventes vs N-1 (pp)",
    "excelExport.dimension.sales_quantity": "UVC",
    "excelExport.dimension.sales_quantity_benchmark": "UVC Carrefour benchmark",
    "excelExport.dimension.sales_quantity_comparison": "UVC vs N-1",
    "excelExport.dimension.sales_quantity_promo_percent": "Poids UVC promo.",
    "excelExport.dimension.sales_quantity_promo_percent_benchmark": "Poids UVC promo. Carrefour benchmark (pp)",
    "excelExport.dimension.sales_quantity_promo_percent_comparison": "Poids UVC promo. vs N-1 (pp)",
    "excelExport.dimension.sales_quantity_with_promo": "UVC promo.",
    "excelExport.dimension.sales_quantity_with_promo_benchmark": "UVC promo. Carrefour benchmark",
    "excelExport.dimension.sales_quantity_with_promo_comparison": "UVC promo. vs N-1",
    "excelExport.dimension.sales_quantity_without_promo": "UVC hors promo.",
    "excelExport.dimension.sales_quantity_without_promo_benchmark": "UVC hors promo. Carrefour benchmark",
    "excelExport.dimension.sales_quantity_without_promo_comparison": "UVC hors promo. vs N-1",
    "excelExport.dimension.store_chain_type_desc": "Format",
    "excelExport.dimension.store_desc": "Magasin desc.",
    "excelExport.dimension.store_key": "Magasin code",
    "excelExport.dimension.store_region_desc": "Région",
    "excelExport.dimension.sub_class_desc": "Sous-famille",
    "excelExport.dimension.supplier_desc": "Fournisseur local desc.",
    "excelExport.dimension.supplier_key": "Fournisseur local code",
    "excelExport.dimension.supplier_market_share_sales_amount": "Part de marché Carrefour (en CA)",
    "excelExport.dimension.supplier_market_share_sales_amount_comparison": "Part de marché Carrefour (en CA) vs N-1 (pp)",
    "excelExport.dimension.supplier_market_share_sales_quantity": "Part de marché Carrefour (en UVC)",
    "excelExport.dimension.supplier_market_share_sales_quantity_comparison": "Part de marché Carrefour (en UVC) vs N-1 (pp)",
    "excelExport.dimension.top_flop_ca_barcode": "Top & Flop 10000 des EAN (en CA)",
    "excelExport.dimension.top_flop_ca_item_key": "Top & Flop 10000 des items (en CA)",
    "excelExport.dimension.top_flop_uvc_barcode": "Top & Flop 10000 des EAN (en UVC)",
    "excelExport.dimension.top_flop_uvc_item_key": "Top & Flop 10000 des items (en UVC)",
    "excelExport.dimension.total": "Total",
    "excelExport.dimension.uvc": "UVC",
    "excelExport.dimension.uvc-hp": "UVC hors promo.",
    "excelExport.dimension.uvc-p": "UVC promo.",
    "excelExport.dimensionDesc": "Veuillez choisir au moins 1 dimension",
    "excelExport.dimensionTitle": "Dimension à exporter",
    "excelExport.error": "Une erreur est survenue, veuillez réessayer",
    "excelExport.flop": "Flop EAN",
    "excelExport.indicatorDesc": "Veuillez sélectionner au moins un indicateur",
    "excelExport.indicatorTitle": "Indicateurs à exporter",
    "excelExport.nameTitle.average_price": "Prix moyen",
    "excelExport.nameTitle.average_price_benchmark": "Prix moyen Carrefour benchmark",
    "excelExport.nameTitle.average_price_comparison": "Prix moyen vs N-1",
    "excelExport.nameTitle.average_price_with_promo": "Prix moyen promo",
    "excelExport.nameTitle.average_price_with_promo_benchmark": "Prix moyen promo Carrefour benchmark",
    "excelExport.nameTitle.average_price_with_promo_comparison": "Prix moyen promo vs N-1",
    "excelExport.nameTitle.average_weekly_sales_amount": "VMH (CA {{taxType}})",
    "excelExport.nameTitle.average_weekly_sales_amount_benchmark": "VMH (CA) Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_amount_comparison": "VMH (CA) vs N-1",
    "excelExport.nameTitle.average_weekly_sales_quantity": "VMH (UVC)",
    "excelExport.nameTitle.average_weekly_sales_quantity_benchmark": "VMH (UVC) Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_quantity_comparison": "VMH (UVC) vs N-1",
    "excelExport.nameTitle.avg-price": "Prix moyen",
    "excelExport.nameTitle.avg-price-p": "Prix moyen promo",
    "excelExport.nameTitle.barcode": "EAN",
    "excelExport.nameTitle.brand_desc": "Marque",
    "excelExport.nameTitle.brand_type_desc": "Triptyque",
    "excelExport.nameTitle.ca": "CA {{taxType}}",
    "excelExport.nameTitle.ca-hp": "CA {{taxType}} hors promo.",
    "excelExport.nameTitle.ca-p": "CA {{taxType}} promo.",
    "excelExport.nameTitle.class_desc": "Famille",
    "excelExport.nameTitle.item_desc": "Item",
    "excelExport.nameTitle.item_key": "Item",
    "excelExport.nameTitle.main_holding_desc": "Holding",
    "excelExport.nameTitle.nielsen_market_share_sales_amount": "Part de marché Nielsen (en CA)",
    "excelExport.nameTitle.nielsen_market_share_sales_amount_comparison": "Part de marché Nielsen (en CA) vs N-1 (pp)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity": "Part de marché Nielsen (en UVC)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity_comparison": "Part de marché Nielsen (en UVC) vs N-1 (pp)",
    "excelExport.nameTitle.p-ca-p": "Poids CA {{taxType}} promo.",
    "excelExport.nameTitle.p-uvc-p": "Poids UVC promo.",
    "excelExport.nameTitle.sales_amount": "CA {{taxType}}",
    "excelExport.nameTitle.sales_amount_benchmark": "CA Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_comparison": "CA {{taxType}} vs N-1",
    "excelExport.nameTitle.sales_amount_promo_percent": "Poids CA {{taxType}} promo.",
    "excelExport.nameTitle.sales_amount_promo_percent_benchmark": "Poids CA {{taxType}} promo. Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_amount_promo_percent_comparison": "Poids CA {{taxType}} promo. vs N-1 (pp)",
    "excelExport.nameTitle.sales_amount_with_promo": "CA {{taxType}} promo.",
    "excelExport.nameTitle.sales_amount_with_promo_benchmark": "CA promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_with_promo_comparison": "CA {{taxType}} promo. vs N-1",
    "excelExport.nameTitle.sales_amount_without_promo": "CA {{taxType}} hors promo.",
    "excelExport.nameTitle.sales_amount_without_promo_benchmark": "CA hors promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_without_promo_comparison": "CA {{taxType}} hors promo. vs N-1",
    "excelExport.nameTitle.sales_possession_rate": "Taux de détention sur ventes",
    "excelExport.nameTitle.sales_possession_rate_comparison": "Taux de détention sur ventes vs N-1",
    "excelExport.nameTitle.sales_quantity": "UVC",
    "excelExport.nameTitle.sales_quantity_benchmark": "UVC Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_comparison": "UVC vs N-1",
    "excelExport.nameTitle.sales_quantity_promo_percent": "Poids UVC promo.",
    "excelExport.nameTitle.sales_quantity_promo_percent_benchmark": "Poids UVC promo. Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_quantity_promo_percent_comparison": "Poids UVC promo. vs N-1 (pp)",
    "excelExport.nameTitle.sales_quantity_with_promo": "UVC promo.",
    "excelExport.nameTitle.sales_quantity_with_promo_benchmark": "UVC promo. Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_with_promo_comparison": "UVC promo. vs N-1",
    "excelExport.nameTitle.sales_quantity_without_promo": "UVC hors promo.",
    "excelExport.nameTitle.sales_quantity_without_promo_benchmark": "UVC hors promo. Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_without_promo_comparison": "UVC hors promo. vs N-1",
    "excelExport.nameTitle.store_chain_type_desc": "Format",
    "excelExport.nameTitle.store_key": "Magasin",
    "excelExport.nameTitle.store_region_desc": "Région",
    "excelExport.nameTitle.sub_class_desc": "Sous-famille",
    "excelExport.nameTitle.supplier_desc": "Fournisseur local",
    "excelExport.nameTitle.supplier_market_share_sales_amount": "Part de marché Carrefour (en CA)",
    "excelExport.nameTitle.supplier_market_share_sales_amount_comparison": "Part de marché Carrefour (en CA) vs N-1 (pp)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity": "Part de marché Carrefour (en UVC)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity_comparison": "Part de marché Carrefour (en UVC) vs N-1 (pp)",
    "excelExport.nameTitle.top_flop_ca_barcode": "Top & Flop 10000 des EAN (en CA)",
    "excelExport.nameTitle.top_flop_ca_item_key": "Top & Flop 10000 des items (en CA)",
    "excelExport.nameTitle.top_flop_uvc_barcode": "Top & Flop 10000 des EAN (en UVC)",
    "excelExport.nameTitle.top_flop_uvc_item_key": "Top & Flop 10000 des items (en UVC)",
    "excelExport.nameTitle.total": "Total",
    "excelExport.nameTitle.uvc": "UVC",
    "excelExport.nameTitle.uvc-hp": "UVC hors promo.",
    "excelExport.nameTitle.uvc-p": "UVC promo.",
    "excelExport.notificationInProgressMessage": "Vous allez recevoir votre fichier par mail.",
    "excelExport.notificationInProgressTitle": "Export en cours",
    "excelExport.scopeTitle": "Rappel du périmètre sélectionné",
    "excelExport.sendExport": "Recevoir l'export par mail",
    "excelExport.title": "Décomposition de la performance",
    "excelExport.top": "Top EAN",
    "exportChecklistData.average_price": "Prix moyen",
    "exportChecklistData.average_weekly_sales_amount": "VMH (CA {{taxType}})",
    "exportChecklistData.average_weekly_sales_quantity": "VMH (UVC)",
    "exportChecklistData.distribution": "Distribution",
    "exportChecklistData.gross_service_rate": "Taux de service brut",
    "exportChecklistData.net_service_rate": "Taux de service net",
    "exportChecklistData.nielsen_market_share_sales_amount": "Part de marché Nielsen (en CA)",
    "exportChecklistData.nielsen_market_share_sales_quantity": "Part de marché Nielsen (en UVC)",
    "exportChecklistData.sales_amount": "CA {{taxType}}",
    "exportChecklistData.sales_possession_rate": "Taux de détention sur ventes",
    "exportChecklistData.sales_quantity": "UVC",
    "exportChecklistData.supplier_market_share_sales_amount": "Part de marché Carrefour (en CA)",
    "exportChecklistData.supplier_market_share_sales_quantity": "Part de marché Carrefour (en UVC)",
    "exportKpi.activity_type": "Canal d'achat",
    "exportKpi.barcode": "EAN",
    "exportKpi.brand_key": "Marque",
    "exportKpi.brand_type_key": "Triptyque",
    "exportKpi.class_key": "Famille",
    "exportKpi.department_key": "Département",
    "exportKpi.dimension": "Dimension",
    "exportKpi.flopAmount_barcode": "Flop EAN (en CA)",
    "exportKpi.flopAmount_item_key": "Flop Item (en CA)",
    "exportKpi.flopQuantity_barcode": "Flop EAN (en UVC)",
    "exportKpi.flopQuantity_item_key": "Flop Item (en UVC)",
    "exportKpi.grp_class_key": "Groupe de familles",
    "exportKpi.HT": "HT",
    "exportKpi.interval": "Intervalle",
    "exportKpi.isWithTax": "HT ou TTC",
    "exportKpi.item_key": "Produit (Item code)",
    "exportKpi.main_holding_key": "Holding",
    "exportKpi.perimeter": "Périmètre",
    "exportKpi.sector_key": "Secteur",
    "exportKpi.store_chain_type_key": "Format",
    "exportKpi.store_int_flag": "Intégrés / Franchisés",
    "exportKpi.store_key": "Magasin",
    "exportKpi.sub_class_key": "Sous-famille",
    "exportKpi.supplier_key": "Fournisseur local",
    "exportKpi.topAmount_barcode": "Top EAN (en CA)",
    "exportKpi.topAmount_item_key": "Top Item (en CA)",
    "exportKpi.topQuantity_barcode": "Top EAN (en UVC)",
    "exportKpi.topQuantity_item_key": "Top Item (en UVC)",
    "exportKpi.TTC": "TTC",
    "Filter.Granularity": "Granularité",
    "Filter.Granularity.Daily": "Jour",
    "Filter.Granularity.Monthly": "Mois",
    "Filter.Granularity.Nielsen_period": "Période Nielsen",
    "Filter.Granularity.Weekly": "Semaine",
    "filterPopin.activityType": "Canal d'achat",
    "filterPopin.activityTypesData.E-Commerce": "Ecommerce",
    "filterPopin.activityTypesData.ECM": "Ecommerce",
    "filterPopin.activityTypesData.In store": "In store",
    "filterPopin.activityTypesData.STO": "In store",
    "filterPopin.brand": "Marque",
    "filterPopin.brandType": "Triptyque",
    "filterPopin.chainType": "Format",
    "filterPopin.cpgVersion.brand": "Marques",
    "filterPopin.cpgVersion.localSupplierAndBrand": "Fournisseur local / Marque",
    "filterPopin.ean": "EAN",
    "filterPopin.hint.availableOnCarrefour": "Veuillez utiliser la taxonomie Carrefour",
    "filterPopin.hint.availableOnNielsen": "Veuillez utiliser la taxonomie Nielsen",
    "filterPopin.holding": "Holding",
    "filterPopin.holdingAndBrand": "Holding / Marque",
    "filterPopin.holdingAndLocalSupplier": "Holding / Fournisseur local",
    "filterPopin.holdingAndLocalSupplierAndBrand": "Holding / Fournisseur local / Marque",
    "filterPopin.integrateFranchised": "Intégrés / Franchisés",
    "filterPopin.integrateFranchisedData.0": "Franchisés",
    "filterPopin.integrateFranchisedData.1": "Intégrés",
    "filterPopin.integrateFranchisedData.2": "Unknown",
    "filterPopin.integrateFranchisedData.2ESP": "Dark stores",
    "filterPopin.item": "Item",
    "filterPopin.localSupplier": "Fournisseur local",
    "filterPopin.nomenclature.carrefour": "Carrefour",
    "filterPopin.nomenclature.nielsen": "Nielsen",
    "filterPopin.nomenclature.title": "Nomenclature",
    "filterPopin.productNomenclature": "Nomenclature produit",
    "filterPopin.productPlaceholder": "Veuillez saisir au moins 4 caractères dans la barre de recherche",
    "filterPopin.region": "Région",
    "filterPopin.searchBar": "Rechercher un article, marque, format...",
    "filterPopin.store": "Magasin",
    "filterPopin.title": "Filtres",
    "General.category_performance": "Performance de la catégorie",
    "General.Country": "France",
    "General.filters": "Filtres",
    "General.Hide_filters": "Cacher les filtres",
    "General.Performance": "Performance",
    "General.Show_filters": "Afficher les filtres",
    "General.Supplier_view": "Accéder à la vision fournisseur",
    "General.Update_perimeter": "Revenir sur votre périmètre",
    "impersonateFrame.timeRemaining": "Temps restant",
    "likeForLike.infoBanner.lineEcommerce": "Le e-commerce.",
    "likeForLike.infoBanner.lineOne": "Les magasins n'ayant pas été ouverts un nombre de jours comparables entre n et n-1.",
    "likeForLike.infoBanner.lineTwo": "Les magasins ayant changé de typologie entre l'année n et n-1 (format, statut, pays...).",
    "likeForLike.infoBanner.title": "En mode parc constant, le périmètre d'analyse exclut :",
    "likeForLike.warningBanner.content": "Attention, certains magasins ont fermé pour une période inférieure à un mois et sont donc comptabilisés dans les indicateurs.",
    "marketShareTableauEnbedding.info": "Le contenu ci-dessous est hébergé sur Tableau. Veuillez vous authentifier et ressaisir vos filtres.",
    "marketShareTableauEnbedding.subtitle": "Pour aller plus loin, accédez ci-dessous à la synthèse Parts de marché du Cockpit de performance.",
    "marketShareTableauEnbedding.title": "Synthèse Parts de marché",
    "Nav.Back": "Retour",
    "Nav.Performance": "Performance",
    "Nav.Projects": "Projets",
    "onboardingConfirmation.confirmationButton": "Valider et découvir la plateforme",
    "onboardingConfirmation.language": "Langue",
    "onboardingConfirmation.languageEdit": "Modifier",
    "onboardingConfirmation.perimeter": "Votre périmètre par défaut",
    "onboardingConfirmation.perimeterEdit": "Modifier votre périmètre par défaut",
    "onboardingConfirmation.subtitle": "Veuillez vérifier votre paramètrage",
    "onboardingConfirmation.title": "Plus qu'une dernière étape avant de découvrir la plateforme !",
    "onboardingLanguage.button": "Langue",
    "onboardingLanguage.confirmationButton": "Confirmer mon choix et passer à l'étape suivante",
    "onboardingLanguage.subtitle": "Tout d'abord, veuillez paramétrer votre langue par défaut.",
    "onboardingLanguage.title": "Bienvenue sur Carrefour Links Growth !",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.esp": "Le canal e-commerce est exclu du périmètre parc constant",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.ita": "Le canal e-commerce est exclu du périmètre parc constant",
    "onboardingPerimeter.activityType.subtitle": "Canal d'achat",
    "onboardingPerimeter.activityType.title": "Sur quel canal d'achat souhaitez-vous suivre les performances par défaut? *",
    "onboardingPerimeter.chainType.subtitle": "Format",
    "onboardingPerimeter.chainType.title": "Sur quel format de magasin souhaitez-vous suivre les performances par défaut? *",
    "onboardingPerimeter.confirmationButton": "Confirmer mon choix et passer à l'étape suivante",
    "onboardingPerimeter.granularity.buttonTitle": "Granularité d'analyse",
    "onboardingPerimeter.granularity.monthly": "Mensuelle",
    "onboardingPerimeter.granularity.title": "A quelle granularité souhaitez vous suivre la performance de votre périmètre ? *",
    "onboardingPerimeter.granularity.weekly": "Hebdomadaire",
    "onboardingPerimeter.productHierarchy.button": "Ajouter une hiérarchie produit",
    "onboardingPerimeter.productHierarchy.class": "Famille",
    "onboardingPerimeter.productHierarchy.department": "Département",
    "onboardingPerimeter.productHierarchy.grpclass": "Groupe de familles",
    "onboardingPerimeter.productHierarchy.sector": "Secteur",
    "onboardingPerimeter.productHierarchy.subclass": "Sous-famille",
    "onboardingPerimeter.productHierarchy.subtitle": "Hierarchie produit",
    "onboardingPerimeter.productHierarchy.title": "Quel périmètre produit souhaitez-vous utiliser par défaut ?",
    "onboardingPerimeter.salesAmountFormat.buttonTitle": "Valeur KPI",
    "onboardingPerimeter.salesAmountFormat.HT": "HT",
    "onboardingPerimeter.salesAmountFormat.title": "Souhaitez-vous utiliser les indicateurs en HT ou en TTC par défaut  ? *",
    "onboardingPerimeter.salesAmountFormat.TTC": "TTC",
    "onboardingPerimeter.storePerimeter.allStores.content": "Le périmètre comprend tous les magasins du parc.",
    "onboardingPerimeter.storePerimeter.allStores.title": "Ensemble du parc",
    "onboardingPerimeter.storePerimeter.likeForLike.full.content": "Le périmètre exclut les magasins ayant moins d'un an d’ancienneté, fermés depuis plus d'un an ou ayant changé de typologie durant la période d’étude.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.content": "Le périmètre exclut les magasins n’ayant pas un nombre de jours d’ouverture comparables entre les périodes n-1 et n. Le e-commerce est également exclu.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.close": "J’ai compris",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.esp": "En Espagne, le canal d’achat e-commerce n’est pas disponible à Parc constant. Pour sélectionner l’option Parc constant, veuillez sélectionner le canal d’achat In store uniquement.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.ita": "En Italie, le canal d’achat e-commerce n’est pas disponible à Parc constant. Pour sélectionner l’option Parc constant, veuillez sélectionner le canal d’achat In store uniquement.",
    "onboardingPerimeter.storePerimeter.likeForLike.title": "Parc constant",
    "onboardingPerimeter.storePerimeter.subtitle": "Périmètre magasin",
    "onboardingPerimeter.storePerimeter.title": "Sur quel périmètre parc magasin souhaitez vous analyser l'évolution des indicateurs ? *",
    "onboardingPerimeter.subtitle": "A chaque connexion, les indicateurs de suivi de la performance seront calculés et affichés sur le périmètre que vous allez définir à cette étape. Vous pourrez le modifier à tout moment dans les paramètres de votre compte.",
    "onboardingPerimeter.timeframe.buttonTitle": "Période temporelle",
    "onboardingPerimeter.timeframe.cumulative_to_date": "Cumul annuel mobile",
    "onboardingPerimeter.timeframe.last_period": "Période précédente",
    "onboardingPerimeter.timeframe.title": "Sur quelle période temporelle souhaitez vous voir vos indicateurs de perfomance lors de chaque connexion ? *",
    "onboardingPerimeter.timeframe.year_to_date": "Cumul à date",
    "onboardingPerimeter.title": "Ensuite, veuillez définir votre périmètre par défaut.",
    "performanceCarrefourCatman.salesAmountFormat.HT": "HT",
    "performanceCarrefourCatman.salesAmountFormat.nielsenIsWithTax": "En taxonomie Nielsen, les ventes sont calculées toutes taxes comprises.",
    "performanceCarrefourCatman.salesAmountFormat.TTC": "TTC",
    "performanceCarrefourCatman.storePerimeter.allStores": "Ensemble du parc",
    "performanceCarrefourCatman.storePerimeter.hintEcommerceNotAvailable": "Le canal d’achat e-commerce n’est pas \ndisponible à parc constant.",
    "performanceCarrefourCatman.storePerimeter.hintLikeForLikeNotAvailableWithEcommerce": "L'analyse Like for like n'est pas disponible quand le canal e-commerce est sélectionné.",
    "performanceCarrefourCatman.storePerimeter.likeForLike": "Parc constant",
    "performanceCarrefourCatman.storePerimeter.nielsenAllStores": "En taxonomie Nielsen, le mode Like for like n'est pas disponible.",
    "performanceCarrefourCatman.subtitleDistribution": "Distribution",
    "performanceCarrefourCatman.subtitlePossessionAndDistribution": "Détention et distribution",
    "performanceCarrefourCatman.subtitleSales": "Ventes",
    "performanceCarrefourCatman.subtitleServiceRate": "Approvisionnement",
    "performanceCarrefourCatman.subtitleStock": "Stocks",
    "performanceCarrefourCatman.subtitleSupply": "Approvisionnement",
    "performanceCarrefourCatman.title": "Performance de la catégorie",
    "performanceCarrefourCatmanCpgView.benchmark": "Afficher benchmark",
    "performanceCarrefourCatmanCpgView.title": "Performance du fournisseur",
    "performanceCarrefourCatmanCpgView.warning": "Vous voyez actuellement la page en tant que",
    "projectForm.cancelPopin.confirmationNotification": "La création du projet a bien été annulée",
    "projectForm.cancelPopin.deleteButton": "Supprimer le projet",
    "projectForm.cancelPopin.description": "Attention, toutes les informations complétées seront perdues. Voulez-vous vraiment annuler la création de ce projet ?",
    "projectForm.cancelPopin.discardButton": "Retour au projet",
    "projectForm.cancelPopin.title": "Annuler le projet",
    "projectForm.confirmationStep.description": "Vous pouvez vérifier les attributs, le périmètre et le mode d'évaluation du projet et valider la création de votre projet. \nUne fois le projet créé, vous pourrez partager la page de suivi avec votre fournisseur partenaire, et suivre ensemble au quotidien sa performance.",
    "projectForm.startFormButton": "Commencer la création de mon projet",
    "projectForm.step1.description": "Veuillez renseigner les informations décrivant votre projet : sélection d'un type de projet, nom et description, fournisseur partenaire, dates de début et de fin du projet.",
    "projectForm.step1.endDate": "Date de fin du projet",
    "projectForm.step1.options.CONCEPT_TESTING": "Test de concept",
    "projectForm.step1.options.INNOVATION_LAUNCH": "Lancement d'innovation",
    "projectForm.step1.options.INSTORE_ENTERTAINMENT": "Animation en magasin",
    "projectForm.step1.options.MERCHANDISING_TEST": "Test merchandizing",
    "projectForm.step1.options.RANGE_LAUNCH": "Lancement de gamme",
    "projectForm.step1.options.SIGNAGE_TEST": "Test signalétique",
    "projectForm.step1.projectDescription": "Description du projet",
    "projectForm.step1.projectDescription.buttonDescription": "Décrivez votre projet en quelques lignes",
    "projectForm.step1.projectName": "Nom du projet",
    "projectForm.step1.projectName.buttonDescription": "Choisissez un nom pour votre projet",
    "projectForm.step1.projectSupplier": "Fournisseur participant au projet",
    "projectForm.step1.projectSupplier.buttonDescription": "Recherchez le fournisseur avec lequel vous menez le projet",
    "projectForm.step1.projectType": "Type de projet",
    "projectForm.step1.projectType.buttonDescription": "Veuillez renseigner les informations caractérisant votre projet",
    "projectForm.step1.startDate": "Date de début du projet",
    "projectForm.step1.title": "Etape 1 : Attributs du projet",
    "projectForm.step2.brand": "Marque",
    "projectForm.step2.brand.button": "Ajouter une marque",
    "projectForm.step2.chainType": "Format",
    "projectForm.step2.chainType.button": "Ajouter un format",
    "projectForm.step2.description": "Veuillez renseigner le périmètre de votre projet pour suivre sa performance : périmètre produit et magasin et fréquence d'analyse souhaitée.",
    "projectForm.step2.performanceGranularity": "A quelle granularité souhaitez-vous surveillez les performances de votre projet ?",
    "projectForm.step2.performanceGranularity.buttonDescription": "Sélectionnez la granularité",
    "projectForm.step2.performanceGranularity.information": "Si vous choisissez une granularité mensuelle, la performance du projet sera affichée par défaut pour le dernier mois disponible, et l'évolution du projet sera présentée mois par mois dans les graphiques.",
    "projectForm.step2.product": "Produit",
    "projectForm.step2.product.button": "Ajouter un produit",
    "projectForm.step2.productHierarchy": "Nomenclature du produit",
    "projectForm.step2.productHierarchy.button": "Ajouter un groupe de famille, une famille, une sous-famille",
    "projectForm.step2.store": "Magasin",
    "projectForm.step2.store.button": "Ajouter un magasin",
    "projectForm.step2.title": "Etape 2 : Périmètre du projet",
    "projectForm.step3.button": "Evaluation",
    "projectForm.step3.buttonDescription": "Sélectionner une évaluation",
    "projectForm.step3.description": "Veuillez renseigner comment vous souhaitez évaluer la performance de votre projet : choix d'un objectif (ventes, quantité, VMH), sélection d'un périmètre produit et magasin de comparaison, ou aucun mode\nd'évaluation.",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi": "Ventes moyennes hebdomadaires",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.buttonDescription": "Fixer un objectif de VMH à atteindre",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.value": "Montant en €",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.valueDescription": "VMH",
    "projectForm.step3.targetObjective.quantityKpi": "UVC",
    "projectForm.step3.targetObjective.quantityKpi.buttonDescription": "Fixer un objectif de UVC à atteindre",
    "projectForm.step3.targetObjective.quantityKpi.value": "Montant en UVC",
    "projectForm.step3.targetObjective.quantityKpi.valueDescription": "UVC",
    "projectForm.step3.targetObjective.salesKpi": "CA TTC",
    "projectForm.step3.targetObjective.salesKpi.buttonDescription": "Fixer un objectif de CA TTC à atteindre",
    "projectForm.step3.targetObjective.salesKpi.value": "Montant en €",
    "projectForm.step3.targetObjective.salesKpi.valueDescription": "CA TTC",
    "projectForm.step3.title": "Etape 3 : Evaluation du projet",
    "projectForm.title": "Créer un nouveau projet",
    "projectOverview.allProjectsFilter": "Tous mes projets",
    "projectOverview.allStatusFilter": "Tous les statuts",
    "projectOverview.allSuppliersFilter": "Tous les fournisseurs",
    "projectOverview.cta": "Créer un nouveau projet",
    "projectOverview.title": "Consulter les projets",
    "salesAmount.barChart": "Evolution du CA {{taxType}} vs N-1",
    "salesAmount.lineChart": "Répartition du CA {{taxType}}",
    "salesAmount.pieChart": "Analyse du CA {{taxType}}",
    "salesAmount.switchButton": "Afficher en UVC",
    "salesAmount.title": "Analyse du CA {{taxType}}",
    "salesQuantity.barChart": "Evolution des quantités vendues vs N-1 (UVC)",
    "salesQuantity.lineChart": "Répartition des quantités (UVC)",
    "salesQuantity.pieChart": "Analyse des quantités vendues",
    "salesQuantity.switchButton": "Afficher en CA",
    "salesQuantity.title": "Analyse des quantités vendues",
    "Scorecard.Average_price": "Prix moyen",
    "Scorecard.AWS": "VMH",
    "scorecard.grossServiceRateDownstream.tooltip": "Part des commandes livrées à temps en magasin par les entrepôts. Le taux de service brut ne prend pas en compte les retraitements effectués par les équipes opérationnelles. C’est un rapport de quantités exprimées en UVC.",
    "scorecard.grossServiceRateUpstream.tooltip": "Part des commandes livrées à temps par les fournisseurs en entrepôt. Le taux de service brut ne prend pas en compte les retraitements effectués par les équipes opérationnelles. C’est un rapport de quantités exprimées en UVC.",
    "scorecard.marketshareUnavailable": "Pour afficher la Part de Marché Nielsen, veuillez sélectionner le mode Nielsen ainsi qu'une granularité semaine ou période Nielsen",
    "Scorecard.Nielsen_ Market_share_value": "Part de marché Nielsen (CA TTC)",
    "Scorecard.Nielsen_ Market_share_volume": "Part de marché Nielsen (UVC)",
    "Scorecard.Non-promo": "hors promo",
    "Scorecard.Num_Distrib": "DN",
    "scorecard.numdistUnavailable_barcode": "Pour afficher la DN sur ventes, veuillez sélectionner un EAN et une granularité mensuelle ou hebdomadaire. \nPart des magasins ayant vendu la référence produit.",
    "scorecard.numdistUnavailable_item_key": "Pour afficher la DN sur ventes, veuillez sélectionner un item et une granularité mensuelle ou hebdomadaire. \nPart des magasins ayant vendu la référence produit.",
    "scorecard.numericalDistribution.tooltip": "Part des magasins ayant vendu la référence produit. \nDisponible seulement à la maille produit et en granularité mensuelle ou hebdomadaire.",
    "Scorecard.Pressure": "Poids",
    "Scorecard.Promo": "Promo.",
    "Scorecard.Quantity": "UVC",
    "Scorecard.Sales _value": "CA TTC",
    "scorecard.salesPossessionRate.tooltip": "Part des références produits vendues par rapport à l’assortiment théorique des magasins.",
    "scorecard.serviceRateDownstream.tooltip": "Part des commandes livrées à temps en magasin par les entrepôts. Le taux de service net prend en compte les retraitements effectués par les équipes opérationnelles et est un rapport de quantités exprimées en valeur. Le taux de service brut est un rapport de quantités exprimées en UVC.",
    "scorecard.serviceRateUpstream.tooltip": "Part des commandes livrées à temps par les fournisseurs en entrepôt. Le taux de service net prend en compte les retraitements effectués par les équipes opérationnelles et est un rapport de quantités exprimées en valeur. Le taux de service brut est un rapport de quantités exprimées en UVC.",
    "scorecard.stockWarehouse.tooltip": "Stock en valeur et en quantité détenu en entrepôt. Le stock en valeur est toujours exprimé en HT. Le stock en quantité est exprimé en UVC.",
    "scorecard.weighdistUnavailable_barcode": "Pour afficher la DV sur ventes, veuillez sélectionner un EAN et une granularité mensuelle ou hebdomadaire. \nRapport entre le CA généré par la catégorie pour tous les magasins vendant le produit et le CA généré par la catégorie pour tous les magasins.",
    "scorecard.weighdistUnavailable_item_key": "Pour afficher la DV sur ventes, veuillez sélectionner un item et une granularité mensuelle ou hebdomadaire. \nRapport entre le CA généré par la catégorie pour tous les magasins vendant le produit et le CA généré par la catégorie pour tous les magasins.",
    "scorecard.weightedDistribution.tooltip": "Rapport entre le CA généré par la catégorie pour tous les magasins vendant le produit et le CA généré par la catégorie pour tous les magasins.\nDisponible seulement à la maille produit et en granularité mensuelle ou hebdomadaire.",
    "Scorecard.Weigthed_Distrib": "DV",
    "scorecardPerformance.averagePrice": "Prix moyen {{taxType}})",
    "scorecardPerformance.averagePricePromo": "Prix moyen promo {{taxType}}",
    "scorecardPerformance.averagePriceWithoutTax": "Prix moyen HT",
    "scorecardPerformance.averagePriceWithoutTaxPromo": "Prix moyen promo. HT",
    "scorecardPerformance.averagePriceWithTax": "Prix moyen TTC",
    "scorecardPerformance.averagePriceWithTaxPromo": "Prix moyen promo. TTC",
    "scorecardPerformance.averagePromoPrice": "Prix moyen promo. {{taxType}})",
    "scorecardPerformance.averageWeeklySales": "VMH {{taxType}}",
    "scorecardPerformance.averageWeeklySalesAmountWithoutTax": "VMH (CA HT)",
    "scorecardPerformance.averageWeeklySalesAmountWithTax": "VMH (CA TTC)",
    "scorecardPerformance.averageWeeklySalesInUnits": "VMH (UVC)",
    "scorecardPerformance.averageWeeklySalesQuantity": "VMH (UVC)",
    "scorecardPerformance.carrefourMarketShareValue": "Part de marché Carrefour (CA)",
    "scorecardPerformance.carrefourMarketShareVolume": "Part de marché Carrefour (UVC)",
    "scorecardPerformance.grossServiceRateDownstream": "Taux de service brut aval",
    "scorecardPerformance.grossServiceRateUpstream": "Taux de service brut amont",
    "scorecardPerformance.netServiceRateDownstream": "Taux de service net aval",
    "scorecardPerformance.netServiceRateUpstream": "Taux de service net amont",
    "scorecardPerformance.nielsenMarketShareValue": "Part de marché Nielsen (CA)",
    "scorecardPerformance.nielsenMarketShareVolume": "Part de marché Nielsen (UVC)",
    "scorecardPerformance.nonavailable": "Cet indicateur sera disponible prochainement",
    "scorecardPerformance.nonavailableperimeter": "Non disponible sur ce périmètre",
    "scorecardPerformance.nonPromo": "Hors promo",
    "scorecardPerformance.notAvailableForCarrefourTaxonomy": "Disponible uniquement en taxonomie Nielsen",
    "scorecardPerformance.notAvailableForCurrentPerimeter": "Non disponible sur ce périmètre",
    "scorecardPerformance.notAvailableForNielsenTaxonomy": "Disponible uniquement en taxonomie Carrefour",
    "scorecardPerformance.numericalDistribution": "DN sur ventes",
    "scorecardPerformance.pressure": "Poids",
    "scorecardPerformance.promo": "Promo.",
    "scorecardPerformance.promoPressure": "Poids promo",
    "scorecardPerformance.quantity": "UVC",
    "scorecardPerformance.salesPossessionRate": "Taux de détention sur ventes",
    "scorecardPerformance.salesValue": "CA {{taxType}}",
    "scorecardPerformance.salesValueWithoutTax": "CA HT",
    "scorecardPerformance.salesValueWithTax": "CA TTC",
    "scorecardPerformance.serviceRate": "Taux de service",
    "scorecardPerformance.serviceRate.gross": "Brut",
    "scorecardPerformance.serviceRate.net": "Net",
    "scorecardPerformance.serviceRateDownstream": "Taux de service aval",
    "scorecardPerformance.serviceRateUpstream": "Taux de service amont",
    "scorecardPerformance.stockStore": "Stock magasin",
    "scorecardPerformance.stockStore.tooltip": "Stock en valeur et en quantité détenu en magasin. Le stock en valeur est toujours exprimé en HT. Le stock en quantité est exprimé en UVC.",
    "scorecardPerformance.stockStoreQuantity": "Stock magasin (UVC)",
    "scorecardPerformance.stockStoreWithoutTax": "Stock magasin (HT)",
    "scorecardPerformance.stockTotal": "Stock total",
    "scorecardPerformance.stockTotal.notAvailableWithPermissions": "Non disponible pour votre niveau de permissions",
    "scorecardPerformance.stockTotal.tooltip": "Somme des stocks magasin et entrepôt. Le stock en valeur est toujours exprimé en HT. Le stock en quantité est exprimé en UVC.",
    "scorecardPerformance.stockTotalQuantity": "Stock total (UVC)",
    "scorecardPerformance.stockTotalWithoutTax": "Stock total (HT)",
    "scorecardPerformance.stockWarehouse": "Stock entrepôt",
    "scorecardPerformance.stockWarehouseQuantity": "Stock entrepôt (UVC)",
    "scorecardPerformance.stockWarehouseWithoutTax": "Stock entrepôt (HT)",
    "scorecardPerformance.weekAbreviation": "S",
    "scorecardPerformance.weightedDistribution": "DV sur ventes",
    "sidebar.Overview": "Accueil",
    "sideMenu.accountManagement": "Gestion des utilisateurs",
    "sideMenu.administration": "Administration",
    "sideMenu.performance": "Performance de la catégorie",
    "sideMenu.performanceReview": "Performance de la catégorie",
    "sideMenu.projects": "Projets",
    "sideMenu.settings": "Accéder aux paramètres",
    "sideMenu.shortcut": "Afficher le menu latéral",
    "sideMenu.tools": "Outils",
    "sideMenu.userSupport": "Accéder au support utilisateur",
    "supplierPopin.description": "Sélectionner un fournisseur afin d'accéder à sa vision de la performance de son périmètre",
    "supplierPopin.recentSuppliers": "Fournisseur recherchés récemment",
    "supplierPopin.searchBar": "Rechercher un fournisseur",
    "supplierPopin.title": "Accéder à la vision fournisseur",
    "support.contactSupport": "Contacter le support",
    "support.formSubmitted.goBack": "Retourner à ma navigation",
    "support.formSubmitted.text": "Votre demande a été reçue et notre équipe de support vous répondra par e-mail dès que possible. Merci de pour votre patience.",
    "support.formSubmitted.title": "Merci de nous avoir contactés!",
    "support.readOurDocumentation": "Lire notre documentation",
    "Table.Brand": "Marque",
    "Table.Brand_type": "Triptyque",
    "Table.Chain_type": "Format",
    "Table.Family": "Famille",
    "Table.Item": "EAN",
    "Table.load_more": "Afficher plus de lignes",
    "Table.more_details": "Plus de détails",
    "Table.Sub-family": "Sous-famille",
    "Table.Supplier": "Fournisseur",
    "timeSelector.vsLastYear": "vs. année précédente",
    "timeSelectorPopin.apply": "Appliquer",
    "timeSelectorPopin.cumulative_to_date": "Cumul annuel mobile",
    "timeSelectorPopin.customInterval": "Intervalle personnalisé",
    "timeSelectorPopin.description": "Sélectionner la granularité de l'analyse et un intervalle temporel.",
    "timeSelectorPopin.descriptionEssentialPackage": "Sélectionner l'intervalle temporel de l'analyse.",
    "timeSelectorPopin.granularity.daily": "Quotidienne",
    "timeSelectorPopin.granularity.monthly": "Mensuelle",
    "timeSelectorPopin.granularity.nielsen": "Période Nielsen",
    "timeSelectorPopin.granularity.weekly": "Hebdomadaire",
    "timeSelectorPopin.last_period": "Période précédente",
    "timeSelectorPopin.subtitleGranularity": "Granularité de l'analyse",
    "timeSelectorPopin.subtitleInterval": "Intervalle temporel",
    "timeSelectorPopin.title": "Intervalle temporel d'analyse",
    "timeSelectorPopin.year_to_date": "Cumul à date",
    "tools.accessButton": "Accéder",
    "tools.createButton": "Ajouter un outil",
    "tools.empty.addButton": "Ajouter un outil",
    "tools.empty.text.all.carrefour": "Vous n'avez pas encore d'outil sur cette page. {br} Veuillez contacter votre administrateur {br} ou commencez par en ajouter vous-même.",
    "tools.empty.text.all.supplier": "Vous n'avez pas encore d'outil sur cette page. {br} Commencez par en ajouter vous-même.",
    "tools.empty.text.custom": "Vous n'avez pas encore d'outil sur cette page. {br} Commencez par en ajouter vous-même.",
    "tools.empty.text.favorites": "Vous n'avez pas encore d'outil sur cette page. {br} Commencez par en ajouter à vos favoris.",
    "tools.empty.text.recommended": "Vous n'avez pas encore d'outil sur cette page. {br} Veuillez contacter votre administrateur.",
    "tools.empty.title": "Bienvenue!",
    "tools.featured.title": "À la une",
    "tools.popup.add": "Ajouter le raccourci",
    "tools.popup.cancel": "Annuler",
    "tools.popup.create.error.message": "Le raccourci n'a pas pu être créé",
    "tools.popup.create.error.title": "Une erreur est survenue",
    "tools.popup.create.success.message": "Le raccourci a été créé avec succès",
    "tools.popup.create.success.title": "Raccourci créé",
    "tools.popup.createTitle": "Nouveau raccourci",
    "tools.popup.delete.error.message": "Le raccourci n'a pas pu être supprimé",
    "tools.popup.delete.error.title": "Une erreur est survenue",
    "tools.popup.delete.success.message": "Le raccourci a supprimé avec succès",
    "tools.popup.delete.success.title": "Raccourci supprimé",
    "tools.popup.deleteButton": "Supprimer",
    "tools.popup.deleteDescription": "Voulez-vous vraiment supprimer le raccourci ?",
    "tools.popup.deleteTitle": "Supprimer le raccourci",
    "tools.popup.description": "Description",
    "tools.popup.descriptionPlaceholder": "Votre description de l'outil",
    "tools.popup.form.invalidUrl": "L'URL fournie n'est pas valide",
    "tools.popup.form.notEmpty": "Ce champs est requis",
    "tools.popup.form.tooLong": "La description ne doit pas éxcéder les 300 caractères",
    "tools.popup.keepButton": "Conserver",
    "tools.popup.label": "Nom du raccourci",
    "tools.popup.link": "Lien",
    "tools.popup.shortcut": "Raccourci",
    "tools.popup.update.error.message": "Le raccourci n'a pas pu être mis à jour",
    "tools.popup.update.error.title": "Une erreur est survenue",
    "tools.popup.update.success.message": "Le raccourci a été modifié avec succès",
    "tools.popup.update.success.title": "Raccourci mis à jour",
    "tools.popup.updateTitle": "Modifier le raccourci",
    "tools.title": "Outils & Services",
    "toolsFrA3distrib.description": "Consulter les catalogues concurrents",
    "toolsFrA3distrib.title": "A3 Distrib",
    "toolsFrAgathe.description": "Paramétrer mon assortiment",
    "toolsFrAgathe.title": "Agathe",
    "toolsFrApoline.description": "Paramétrer mes opérations promotionnelles",
    "toolsFrApoline.title": "Apoline",
    "toolsFrAssortmentBuilder.description": "Définir mon assortiment",
    "toolsFrAssortmentBuilder.title": "Assortment Builder",
    "toolsFrBasePhoto.description": "Consulter les visuels de mes produits",
    "toolsFrBasePhoto.title": "Base Photo",
    "toolsFrBcpNoyau.description": "Consulter les attributs de mes produits",
    "toolsFrBcpNoyau.title": "BCP Noyau",
    "toolsFrDataInsights.description": "Extraire des rapports de performances",
    "toolsFrDataInsights.title": "Data Insigths",
    "toolsFrMemory.description": "Accéder aux modules de catégorie management",
    "toolsFrMemory.title": "Memory",
    "toolsFrNielsen.description": "Consulter les parts de marché à la semaine",
    "toolsFrNielsen.title": "Nielsen PDM Hebdo PGC",
    "toolsFrPilotagePerfAlimentaire.description": "Consulter les taux de service",
    "toolsFrPilotagePerfAlimentaire.title": "Pilotage de la Performance Alimentaire",
    "toolsItAnalytics.description": "Accéder aux dashboards",
    "toolsItAnalytics.title": "Analytics Italia website",
    "toolsItMicrostrategy.description": "Extraire des rapports de performances",
    "toolsItMicrostrategy.title": "Report Builder/Microstrategy",
    "userSettings.confirmation": "Confirmer mon choix",
    "userSettings.globalInformation.email": "Mail",
    "userSettings.globalInformation.name": "Nom complet",
    "userSettings.globalInformation.role": "Rôle",
    "userSettings.globalInformation.subtitle": "Gérez vos informations et identifiants",
    "userSettings.globalInformation.title": "Informations du compte",
    "userSettings.logout.button": "Déconnexion",
    "userSettings.logout.subtitle": "Se déconnecter de la plateforme",
    "userSettings.logout.title": "Déconnexion",
    "userSettings.perimeter.subtitle": "Gérer votre périmètre par défaut",
    "userSettings.perimeter.title": "Périmètre par défaut",
    "userSettings.settings": "Paramètres",
    "userSettings.title": "Paramètres du compte",
    "userTools.data.a3-distrib.description": "Consulter les catalogues concurrents",
    "userTools.data.a3-distrib.name": "A3 Distrib",
    "userTools.data.agathe.description": "Paramétrer mon assortiment",
    "userTools.data.agathe.name": "Agathe",
    "userTools.data.analytics-italia-website.description": "Accéder aux dashboards",
    "userTools.data.analytics-italia-website.name": "Analytics Italia website",
    "userTools.data.apoline.description": "Paramétrer mes opérations promotionnelles",
    "userTools.data.apoline.name": "Apoline",
    "userTools.data.assortment-builder.description": "Définir mon assortiment",
    "userTools.data.assortment-builder.name": "Assortment Builder",
    "userTools.data.base-photo.description": "Consulter les visuels de mes produits",
    "userTools.data.base-photo.name": "Base Photo",
    "userTools.data.bcp-noyau.description": "Consulter les attributs de mes produits",
    "userTools.data.bcp-noyau.name": "BCP Noyau",
    "userTools.data.cockpit-de-performance.description": "Point d'entrée unique pour le suivi de la part de marché",
    "userTools.data.cockpit-de-performance.name": "Cockpit de performance",
    "userTools.data.data-insights.description": "Extraire des rapports de performances",
    "userTools.data.data-insights.name": "Data Insights",
    "userTools.data.memory.description": "Accéder aux modules de catégorie management",
    "userTools.data.memory.name": "Memory",
    "userTools.data.microstrategy-web.name": "Microstrategy WEB",
    "userTools.data.nielsen-pdm-hebdo-pgc.description": "Consulter les parts de marché à la semaine",
    "userTools.data.nielsen-pdm-hebdo-pgc.name": "Nielsen PDM Hebdo PGC",
    "userTools.data.pilotage-de-la-performance-alimentaire.description": "Consulter les taux de service",
    "userTools.data.pilotage-de-la-performance-alimentaire.name": "Pilotage de la Performance Alimentaire",
    "userTools.data.rcm-circuitos.name": "RCM Circuitos",
    "userTools.data.rcm-referencial.name": "RCM referencial",
    "userTools.data.report-builder/microstrategy.description": "Extraire des rapports de performances",
    "userTools.data.report-builder/microstrategy.name": "Report Builder/Microstrategy",
    "userTools.data.reporting-digital-mercancias.name": "Reporting digital Mercancías",
    "userTools.filters.all": "Tous",
    "userTools.filters.favorites": "Mes favoris",
    "userTools.link": "Accéder",
    "userTools.tags.custom": "Ajouté par moi",
    "userTools.tags.recommended": "Recommandé",
    "userTools.title": "Outils et services",
    "viewAsSupplier.action": "Action",
    "viewAsSupplier.choose": "Choisir",
    "viewAsSupplier.noSupplierWithPlatformAccess": "Aucun fournisseur n’a encore souscrit à la plateforme.",
    "viewAsSupplier.searchSupplier": "Rechercher un fournisseur",
    "viewAsSupplier.selectSupplier": "Veuillez sélectionner un fournisseur parmi ceux ayant accès à la plateforme",
    "viewAsSupplier.supplier": "Fournisseur"
  },
  "it": {
    "accountCreationPage.companySelection": "Selezionare l'azienda di appartenenza",
    "accountCreationPage.information": "Se la vostra azienda non compare, non esiste nei nostri sistemi.",
    "accountCreationPage.subtitle": "Tracciate le prestazioni della vostra categoria e collaborate con i vostri fornitori su un'unica piattaforma.",
    "accountCreationPage.validateCompanyButton": "Invia la mia richiesta di accesso",
    "accountManagerSettings.activationDate": "Data di attivazione",
    "accountManagerSettings.dateRequest": "Richiesta ricevuta il",
    "accountManagerSettings.listTitle": "Elenco utenti",
    "accountManagerSettings.noPendingRequest": "Nessuna nuova richiesta",
    "accountManagerSettings.requestTitle": "Nuove richieste",
    "accountManagerSettings.role.ACCOUNT_MANAGER": "Account Manager",
    "accountManagerSettings.role.ADMIN": "Admin",
    "accountManagerSettings.role.METRIC_VIEWER": "Metric Viewer",
    "accountManagerSettings.role.PROJECT_MANAGER": "Project Manager",
    "accountManagerSettings.role.PROJECT_VIEWER": "Project Viewer",
    "accountManagerSettings.title": "Utenti attivi",
    "accountWaitingPage.accountReject": "Ci dispiace, ma sembra che non abbiate accesso alla piattaforma.",
    "accountWaitingPage.accountRejectSubtitle": "Per ulteriori informazioni, contattare il vostro referente aziendale",
    "accountWaitingPage.subtitle": "Tenete d'occhio la vostra casella di posta: vi invieremo un'e-mail quando la vostra richiesta sarà stata approvata dal referente della vostra azienda. Per ulteriori informazioni, contattate il vostro referente aziendale",
    "accountWaitingPage.title": "La richiesta di accesso è stata inviata con successo!",
    "admin.activeUsers": "Utenti attivi",
    "admin.filter.globalSearch.placeholder": "Cerca per nome utente, email, paese, ruolo",
    "admin.helperText": "Trova l'utente che stai cercando e fai clic su impersona per visualizzare e agire in suo nome.",
    "admin.impersonateFrame.stop": "Stop impersonazione",
    "admin.impersonationImpossibleIfAdmin": "Il ruolo di amministratore non può essere usurpato",
    "admin.popin.button.start": "Inizia sessione come questo utente",
    "admin.popin.title": "Sessione di impersonazione",
    "admin.popin.warning": "Utilizzando questa funzione, navigherai come se fossi questo utente, tutte le azioni saranno salvate come se fossi tu questa persona. Usalo con cautela.",
    "admin.title": "Pannello di amministrazione",
    "admin.userList.cell.impersonate": "Impersona",
    "admin.userList.column.actions": "Azioni",
    "admin.userList.column.holdingCountry": "Holding/Country",
    "admin.userList.column.roles": "Ruolo(i)",
    "admin.userList.column.user": "Utente",
    "authenticationPage.emailPassword": "Accedi con e-mail",
    "authenticationPage.error": "Sembra che la vostra azienda non esista nei nostri sistemi.",
    "authenticationPage.google": "Connettersi con Google",
    "authenticationPage.microsoft": "Connettersi con Microsoft",
    "authenticationPage.popin.continue": "Continua",
    "authenticationPage.popin.email": "E-mail",
    "authenticationPage.popin.email.invalid": "Formato e-mail non valido",
    "authenticationPage.popin.email.notEmpty": "E-mail è obbligatorio",
    "authenticationPage.popin.email.subtitle": "Inserisci l'indirizzo e-mail e la password",
    "authenticationPage.popin.email.title": "Accedi con e-mail",
    "authenticationPage.popin.forgotPassword": "Password dimenticata?",
    "authenticationPage.popin.goBack": "Tornare",
    "authenticationPage.popin.invalidCredential": "E-mail o password non validi",
    "authenticationPage.popin.password": "Password",
    "authenticationPage.popin.password.notEmpty": "Password è obbligatoria",
    "authenticationPage.popin.reset-sent": "Ti è stata inviata un'e-mail. Fai clic sul link per reimpostare la sua password",
    "authenticationPage.popin.reset.subtitle": "Inserisci l'indirizzo e-mail per reimpostare la sua password",
    "authenticationPage.popin.reset.title": "Reimposta la tua password",
    "authenticationPage.popin.send": "Invia",
    "authenticationPage.subtitle": "Tracciate le prestazioni della vostra categoria e collaborate con i vostri fornitori su un'unica piattaforma.",
    "authenticationPage.title": "Benvenuti a Carrefour Links Growth !",
    "chart.noData.content": "per il periodo selezionato",
    "chart.noData.title": "Non sono disponibili dati",
    "commonLabel.administration": "Admin",
    "commonLabel.all": "Tutti",
    "commonLabel.applicateFilters": "Applica ({{nbFiltersSelected}}) filtri",
    "commonLabel.applySelection": "Applica la selezione",
    "commonLabel.back": "Torna indietro",
    "commonLabel.cancel": "Annulla",
    "commonLabel.complete": "Completo",
    "commonLabel.confirm": "Accettare la richiesta",
    "commonLabel.countryFrance": "Francia",
    "commonLabel.countryItaly": "Italia",
    "commonLabel.countrySpain": "Spagna",
    "commonLabel.dateFormat": "DD/MM/YYYY",
    "commonLabel.dateRange": "Intervallo di date",
    "commonLabel.decline": "Rifiutare",
    "commonLabel.defaultFilterPerimeterIsReset": "Il perimetro di default è stato ripristinato",
    "commonLabel.details": "Dettagli",
    "commonLabel.displayFilters": "Mostrare perimetro e filtri",
    "commonLabel.error": "Si è verificato un errore durante il caricamento dei dati",
    "commonLabel.errorData": "Si è verificato un errore",
    "commonLabel.errorMessage": "Riprovare più tardi o contattare l'amministratore.",
    "commonLabel.ESP": "Spagna",
    "commonLabel.FRA": "Francia",
    "commonLabel.from": "Dal",
    "commonLabel.hideFilters": "Nascondi perimetro e filtri",
    "commonLabel.home": "Panoramica",
    "commonLabel.ITA": "Italia",
    "commonLabel.lang.en": "Inglese",
    "commonLabel.lang.es": "Spagnolo",
    "commonLabel.lang.fr": "Francese",
    "commonLabel.lang.it": "Italiano",
    "commonLabel.loadMore": "Mostrare più righe",
    "commonLabel.modifyFilters": "Modificare i filtri ({{nbFiltersSelected}})",
    "commonLabel.month.1": "Gennaio",
    "commonLabel.month.10": "Ottobre",
    "commonLabel.month.11": "Novembre",
    "commonLabel.month.12": "Dicembre",
    "commonLabel.month.2": "Febbraio",
    "commonLabel.month.3": "Marzo",
    "commonLabel.month.4": "Aprile",
    "commonLabel.month.5": "Maggio",
    "commonLabel.month.6": "Giugno",
    "commonLabel.month.7": "Luglio",
    "commonLabel.month.8": "Agosto",
    "commonLabel.month.9": "Settembre",
    "commonLabel.moreDetails": "Maggiori dettagli",
    "commonLabel.noData": "Nessun dato",
    "commonLabel.noResult": "Nessun risultato",
    "commonLabel.notAvailable": "Questo indicatore sarà disponibile a breve.",
    "commonLabel.or": "O",
    "commonLabel.performance": "Performance",
    "commonLabel.previous": "Precedente",
    "commonLabel.projects": "Progetti",
    "commonLabel.reEnterFilters": "Inserire nuovamente i filtri",
    "commonLabel.showFiltersAndPerimeter": "Visualizzare il perimetro e filtri",
    "commonLabel.storePerimeter": "Perimetro de parco",
    "commonLabel.supplierView": "Accedi alla panoramica del fornitore",
    "commonLabel.taxonomy": "Tassonomia",
    "commonLabel.to": "al",
    "commonLabel.updatePerimeter": "Torna al tuo perimetro",
    "commonLabel.validateMyProject": "Crea il mio progetto",
    "commonLabel.value": "Valore",
    "dataTable.sort": "Classificare i dati",
    "dataTablePerformance.dimension.average_price": "Prezzo medio",
    "dataTablePerformance.dimension.average_price_benchmark": "Prezzo medio Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_comparison": "Prezzo medio vs N-1",
    "dataTablePerformance.dimension.average_price_with_promo": "Prezzo promozionale medio",
    "dataTablePerformance.dimension.average_price_with_promo_benchmark": "Prezzo medio promo Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_with_promo_comparison": "Prezzo medio promo. vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_amount": "Vendite medie settimanali {{taxType}}",
    "dataTablePerformance.dimension.average_weekly_sales_amount_benchmark": "Vendite settimanali Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_amount_comparison": "Vendite settimanali vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_quantity": "Quantità medie settimanali",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_benchmark": "Quantità medie settimanali Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_comparison": "Quantità medie settimanali vs N-1",
    "dataTablePerformance.dimension.avg-price": "Prezzo medio",
    "dataTablePerformance.dimension.avg-price-p": "Prezzo promozionale medio",
    "dataTablePerformance.dimension.barcode": "EAN",
    "dataTablePerformance.dimension.brand_desc": "Marca",
    "dataTablePerformance.dimension.brand_type_desc": "Tipo di Marca",
    "dataTablePerformance.dimension.ca": "Vendite {{taxType}}",
    "dataTablePerformance.dimension.ca-hp": "Vendite {{taxType}} Continuativo",
    "dataTablePerformance.dimension.ca-p": "Vendite {{taxType}} Promo",
    "dataTablePerformance.dimension.class_desc": "Famiglia",
    "dataTablePerformance.dimension.gross_service_rate_downstream": "Tasso di servizio lordo a valle",
    "dataTablePerformance.dimension.gross_service_rate_downstream_comparison": "Tasso di servizio lordo a valle vs N-1",
    "dataTablePerformance.dimension.gross_service_rate_upstream": "Tasso di servizio lordo a monte",
    "dataTablePerformance.dimension.gross_service_rate_upstream_comparison": "Tasso di servizio lordo a monte vs N-1",
    "dataTablePerformance.dimension.item_desc": "Prodotto",
    "dataTablePerformance.dimension.item_key": "Item",
    "dataTablePerformance.dimension.main_holding_desc": "Holding",
    "dataTablePerformance.dimension.net_service_rate_downstream": "Tasso di servizio netto a valle",
    "dataTablePerformance.dimension.net_service_rate_downstream_comparison": "Tasso di servizio netto a valle vs N-1",
    "dataTablePerformance.dimension.net_service_rate_upstream": "Tasso di servizio netto a monte",
    "dataTablePerformance.dimension.net_service_rate_upstream_comparison": "Tasso di servizio netto a monte vs N-1",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount": "Quota di mercato Nielsen (in valore)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount_comparison": "Quota di mercato Nielsen (in valore) vs N-1 (pp)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity": "Quota di mercato Nielsen (in quantità)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity_comparison": "Quota di mercato Nielsen (in quantità) vs N-1 (pp)",
    "dataTablePerformance.dimension.p-ca-p": "Peso promozionale (in valore)",
    "dataTablePerformance.dimension.p-uvc-p": "Peso promozionale (in quantità)",
    "dataTablePerformance.dimension.sales_amount": "Vendite {{taxType}}",
    "dataTablePerformance.dimension.sales_amount_benchmark": "Vendite Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_comparison": "Vendite {{taxType}} vs N-1",
    "dataTablePerformance.dimension.sales_amount_promo_percent": "Peso promozionale (in valore)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_benchmark": "Peso promozionale (in valore) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_comparison": "Peso promozionale (in valore) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_amount_with_promo": "Vendite {{taxType}} Promo",
    "dataTablePerformance.dimension.sales_amount_with_promo_benchmark": "Vendite Promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_with_promo_comparison": "Vendite {{taxType}} promo. vs N-1",
    "dataTablePerformance.dimension.sales_amount_without_promo": "Vendite {{taxType}} Continuativo",
    "dataTablePerformance.dimension.sales_amount_without_promo_benchmark": "Vendite senza promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_without_promo_comparison": "Vendite {{taxType}} senza promo vs N-1",
    "dataTablePerformance.dimension.sales_possession_rate": "Tasso di possesso sulle vendite",
    "dataTablePerformance.dimension.sales_possession_rate_comparison": "Tasso di possesso sulle vendite vs N-1",
    "dataTablePerformance.dimension.sales_quantity": "Quantità",
    "dataTablePerformance.dimension.sales_quantity_benchmark": "Quantità Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_comparison": "Quantità vs N-1",
    "dataTablePerformance.dimension.sales_quantity_promo_percent": "Peso promozionale (in quantità)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_benchmark": "Peso promozionale (in quantità) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_comparison": "Peso promozionale (in quantità) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_quantity_with_promo": "Quantità promo",
    "dataTablePerformance.dimension.sales_quantity_with_promo_benchmark": "Quantità promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_with_promo_comparison": "Quantità promo. vs N-1",
    "dataTablePerformance.dimension.sales_quantity_without_promo": "Quantità non promo",
    "dataTablePerformance.dimension.sales_quantity_without_promo_benchmark": "Quantità senza promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_without_promo_comparison": "Quantità senza promo vs N-1",
    "dataTablePerformance.dimension.store_chain_type_desc": "Store format",
    "dataTablePerformance.dimension.store_key": "Negozio",
    "dataTablePerformance.dimension.store_region_desc": "Regione",
    "dataTablePerformance.dimension.store_stock_quantity": "Stock punto vendita",
    "dataTablePerformance.dimension.store_stock_value": "Stock punto vendita nette",
    "dataTablePerformance.dimension.sub_class_desc": "Sottofamiglia",
    "dataTablePerformance.dimension.supplier_desc": "Fornitore locale",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount": "Quota di mercato di Carrefour (in valore)",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount_comparison": "Quota di mercato di Carrefour (in valore) vs N-1 (pp)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity": "Quota di mercato di Carrefour (in quantità)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity_comparison": "Quota di mercato di Carrefour (in quantità) vs N-1 (pp)",
    "dataTablePerformance.dimension.top_flop_ca_barcode": "Top & Flop 10000 di articoli (in valore)",
    "dataTablePerformance.dimension.top_flop_ca_item_key": "Top & Flop 10000 di articoli (in valore)",
    "dataTablePerformance.dimension.top_flop_uvc_barcode": "Top & Flop 10000 di articoli (in quantità)",
    "dataTablePerformance.dimension.top_flop_uvc_item_key": "Top & Flop 10000 di articoli (in quantità)",
    "dataTablePerformance.dimension.total": "Totale",
    "dataTablePerformance.dimension.total_stock_quantity": "Stock totale",
    "dataTablePerformance.dimension.total_stock_value": "Stock totale nette",
    "dataTablePerformance.dimension.uvc": "Quantità",
    "dataTablePerformance.dimension.uvc-hp": "Quantità non promo",
    "dataTablePerformance.dimension.uvc-p": "Quantità promo",
    "dataTablePerformance.dimension.warehouse_stock_quantity": "Stock deposito",
    "dataTablePerformance.dimension.warehouse_stock_value": "Stock deposito nette",
    "dataTablePerformance.excelExport": "Esportare in Excel",
    "dataTablePerformance.subtitle": "Selezionate qui di seguito almeno una dimensione sulla quale desiderate vedere suddivise le prestazioni del perimetro selezionato in precedenza.",
    "dataTablePerformance.title": "Ripartizione delle performance",
    "email.acceptText": "Se questo utente fa parte della tua entità, accetta la sua richiesta direttamente nello strumento tramite",
    "email.accountActivated": "La tua richiesta di accesso a Carrefour Links Growth è stata approvata! Ora puoi accedere alla piattaforma tramite il seguente link",
    "email.andConfigure": "e iniziare a <b>configurare il tuo account</b>",
    "email.appName": "Carrefour Links Growth",
    "email.catmanTeam": "Il team di Carrefour Links Growth",
    "email.downloadYourExport": "Ecco il link per il download dell'estrazione di",
    "email.exportAvailability": "Questo link è disponibile per 15 giorni.",
    "email.exportFailed": "Siamo spiacenti, non è stato possibile caricare il file a causa di un errore tecnico. <br/> Si prega di eseguire nuovamente l'esportazione dalla piattaforma.",
    "email.exportFileName": "Ripartizione delle performance",
    "email.forAnyQuestionReferTo": "Se hai domande, puoi <b>contattare il tuo referente</b>",
    "email.hello": "Ciao",
    "email.orReferToDoc": "o consulta la documentazione",
    "email.otherwisePlease": "Altrimenti, per favore <b>rifiutalo</b>",
    "email.rejectText": "La tua richiesta di accesso a Carrefour Links Growth è stata rifiutata",
    "email.requestAccess": "ha richiesto l'accesso alla piattaforma <span style=\\\"color: #da8796; font-weight: bold\\\">Carrefour Links Growth.</span>",
    "email.seeYouSoon": "A presto sulla piattaforma",
    "email.subject.accessRejected": "La tua richiesta di accesso a Carrefour Links Growth è stata rifiutata",
    "email.subject.accessRequest": "Un utente vuole accedere a Carrefour Links Growth",
    "email.subject.accessValidated": "Benvenuto sulla piattaforma Carrefour Links Growth!",
    "email.subject.exportError": "Si è verificato un errore durante il tuo export",
    "email.subject.exportSuccess": "Il tuo export è pronto!",
    "email.subject.projectInvitation": "Sei stato invitato a partecipare a un progetto su Carrefour Links Growth",
    "email.thisLink": "questo link",
    "email.welcome": "Benvenuto sulla piattaforma Carrefour Links Growth",
    "excelExport.confirmation": "Esportazione in corso\nRiceverete il vostro file via e-mail",
    "excelExport.CTA": "Esportare in Excel",
    "excelExport.desc": "Riceverete un'e-mail con un link per scaricare il vostro file .xlsx.\r\nDurata generalmente osservata (da 1 a 2 minuti)",
    "excelExport.dimension.average_price": "Prezzo medio",
    "excelExport.dimension.average_price_benchmark": "Prezzo medio Carrefour benchmark",
    "excelExport.dimension.average_price_comparison": "Prezzo medio vs N-1",
    "excelExport.dimension.average_price_with_promo": "Prezzo promozionale medio",
    "excelExport.dimension.average_price_with_promo_benchmark": "Prezzo medio promo Carrefour benchmark",
    "excelExport.dimension.average_price_with_promo_comparison": "Prezzo medio promo. vs N-1",
    "excelExport.dimension.average_weekly_sales_amount": "Vendite medie settimanali {{taxType}}",
    "excelExport.dimension.average_weekly_sales_amount_benchmark": "Vendite settimanali Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_amount_comparison": "Vendite settimanali vs N-1",
    "excelExport.dimension.average_weekly_sales_quantity": "Quantità medie settimanali",
    "excelExport.dimension.average_weekly_sales_quantity_benchmark": "Quantità medie settimanali Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_quantity_comparison": "Quantità medie settimanali vs N-1",
    "excelExport.dimension.avg-price": "Prezzo medio",
    "excelExport.dimension.avg-price-p": "Prezzo promozionale medio",
    "excelExport.dimension.barcode": "EAN code",
    "excelExport.dimension.barcode_desc": "EAN desc.",
    "excelExport.dimension.brand_desc": "Marca",
    "excelExport.dimension.brand_type_desc": "Tipo di Marca",
    "excelExport.dimension.ca": "Vendite {{taxType}}",
    "excelExport.dimension.ca-hp": "Vendite {{taxType}} Continuativo",
    "excelExport.dimension.ca-p": "Vendite {{taxType}} Promo",
    "excelExport.dimension.class_desc": "Famiglia",
    "excelExport.dimension.gross_service_rate_downstream": "Tasso di servizio lordo a valle",
    "excelExport.dimension.gross_service_rate_downstream_comparison": "Tasso di servizio lordo a valle vs N-1 (pp)",
    "excelExport.dimension.gross_service_rate_upstream": "Tasso di servizio lordo a monte",
    "excelExport.dimension.gross_service_rate_upstream_comparison": "Tasso di servizio lordo a monte vs N-1 (pp)",
    "excelExport.dimension.item_desc": "Item desc.",
    "excelExport.dimension.item_key": "Item code",
    "excelExport.dimension.main_holding_desc": "Holding desc.",
    "excelExport.dimension.main_holding_key": "Holding code",
    "excelExport.dimension.net_service_rate_downstream": "Tasso di servizio netto a valle",
    "excelExport.dimension.net_service_rate_downstream_comparison": "Tasso di servizio netto a valle vs N-1 (pp)",
    "excelExport.dimension.net_service_rate_upstream": "Tasso di servizio netto a monte",
    "excelExport.dimension.net_service_rate_upstream_comparison": "Tasso di servizio netto a monte vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_amount": "Quota di mercato Nielsen (in valore)",
    "excelExport.dimension.nielsen_market_share_sales_amount_comparison": "Quota di mercato Nielsen (in valore) vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_quantity": "Quota di mercato Nielsen (in quantità)",
    "excelExport.dimension.nielsen_market_share_sales_quantity_comparison": "Quota di mercato Nielsen (in quantità) vs N-1 (pp)",
    "excelExport.dimension.p-ca-p": "Peso promozionale (in valore)",
    "excelExport.dimension.p-uvc-p": "Peso promozionale (in quantità)",
    "excelExport.dimension.sales_amount": "Vendite {{taxType}}",
    "excelExport.dimension.sales_amount_benchmark": "Vendite Carrefour benchmark",
    "excelExport.dimension.sales_amount_comparison": "Vendite {{taxType}} vs N-1",
    "excelExport.dimension.sales_amount_promo_percent": "Peso promozionale (in valore)",
    "excelExport.dimension.sales_amount_promo_percent_benchmark": "Peso promozionale (in valore) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_amount_promo_percent_comparison": "Peso promozionale (in valore) vs N-1 (pp)",
    "excelExport.dimension.sales_amount_with_promo": "Vendite {{taxType}} Promo",
    "excelExport.dimension.sales_amount_with_promo_benchmark": "Vendite Promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_with_promo_comparison": "Vendite {{taxType}} promo. vs N-1",
    "excelExport.dimension.sales_amount_without_promo": "Vendite {{taxType}} Continuativo",
    "excelExport.dimension.sales_amount_without_promo_benchmark": "Vendite senza promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_without_promo_comparison": "Vendite {{taxType}} senza promo vs N-1",
    "excelExport.dimension.sales_possession_rate": "Tasso di possesso sulle vendite",
    "excelExport.dimension.sales_possession_rate_comparison": "Tasso di possesso sulle vendite vs N-1 (pp)",
    "excelExport.dimension.sales_quantity": "Quantità",
    "excelExport.dimension.sales_quantity_benchmark": "Quantità Carrefour benchmark",
    "excelExport.dimension.sales_quantity_comparison": "Quantità vs N-1",
    "excelExport.dimension.sales_quantity_promo_percent": "Peso promozionale (in quantità)",
    "excelExport.dimension.sales_quantity_promo_percent_benchmark": "Peso promozionale (in quantità) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_quantity_promo_percent_comparison": "Peso promozionale (in quantità) vs N-1 (pp)",
    "excelExport.dimension.sales_quantity_with_promo": "Quantità promo",
    "excelExport.dimension.sales_quantity_with_promo_benchmark": "Quantità promo Carrefour benchmark",
    "excelExport.dimension.sales_quantity_with_promo_comparison": "Quantità promo. vs N-1",
    "excelExport.dimension.sales_quantity_without_promo": "Quantità non promo",
    "excelExport.dimension.sales_quantity_without_promo_benchmark": "Quantità senza promo Carrefour benchmark",
    "excelExport.dimension.sales_quantity_without_promo_comparison": "Quantità senza promo vs N-1",
    "excelExport.dimension.store_chain_type_desc": "Store format",
    "excelExport.dimension.store_desc": "Negozio desc.",
    "excelExport.dimension.store_key": "Negozio code",
    "excelExport.dimension.store_region_desc": "Regione",
    "excelExport.dimension.sub_class_desc": "Sottofamiglia",
    "excelExport.dimension.supplier_desc": "Fornitore locale desc.",
    "excelExport.dimension.supplier_key": "Fornitore locale code",
    "excelExport.dimension.supplier_market_share_sales_amount": "Quota di mercato di Carrefour (in valore)",
    "excelExport.dimension.supplier_market_share_sales_amount_comparison": "Quota di mercato di Carrefour (in valore) vs N-1 (pp)",
    "excelExport.dimension.supplier_market_share_sales_quantity": "Quota di mercato di Carrefour (in quantità)",
    "excelExport.dimension.supplier_market_share_sales_quantity_comparison": "Quota di mercato di Carrefour (in quantità) vs N-1 (pp)",
    "excelExport.dimension.top_flop_ca_barcode": "Top & Flop 10000 di articoli (in valore)",
    "excelExport.dimension.top_flop_ca_item_key": "Top & Flop 10000 di articoli (in valore)",
    "excelExport.dimension.top_flop_uvc_barcode": "Top & Flop 10000 di articoli (in quantità)",
    "excelExport.dimension.top_flop_uvc_item_key": "Top & Flop 10000 di articoli (in quantità)",
    "excelExport.dimension.total": "Totale",
    "excelExport.dimension.uvc": "Quantità",
    "excelExport.dimension.uvc-hp": "Quantità non promo",
    "excelExport.dimension.uvc-p": "Quantità promo",
    "excelExport.dimensionDesc": "Selezionare almeno 1 dimensione",
    "excelExport.dimensionTitle": "Dimensioni da esportare",
    "excelExport.error": "Si è verificato un errore. Riprovare.",
    "excelExport.flop": "Flop item",
    "excelExport.indicatorDesc": "Selezionare almeno un indicatore",
    "excelExport.indicatorTitle": "Indicatori da esportare",
    "excelExport.nameTitle.average_price": "Prezzo medio",
    "excelExport.nameTitle.average_price_benchmark": "Prezzo medio Carrefour benchmark",
    "excelExport.nameTitle.average_price_comparison": "Prezzo medio vs N-1",
    "excelExport.nameTitle.average_price_with_promo": "Prezzo promozionale medio",
    "excelExport.nameTitle.average_price_with_promo_benchmark": "Prezzo medio promo Carrefour benchmark",
    "excelExport.nameTitle.average_price_with_promo_comparison": "Prezzo medio promo. vs N-1",
    "excelExport.nameTitle.average_weekly_sales_amount": "Vendite medie settimanali {{taxType}}",
    "excelExport.nameTitle.average_weekly_sales_amount_benchmark": "Vendite settimanali Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_amount_comparison": "Vendite settimanali vs N-1",
    "excelExport.nameTitle.average_weekly_sales_quantity": "Quantità medie settimanali",
    "excelExport.nameTitle.average_weekly_sales_quantity_benchmark": "Quantità medie settimanali Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_quantity_comparison": "Quantità medie settimanali vs N-1",
    "excelExport.nameTitle.avg-price": "Prezzo medio",
    "excelExport.nameTitle.avg-price-p": "Prezzo promozionale medio",
    "excelExport.nameTitle.barcode": "EAN",
    "excelExport.nameTitle.brand_desc": "Marca",
    "excelExport.nameTitle.brand_type_desc": "Tipo di Marca",
    "excelExport.nameTitle.ca": "Vendite {{taxType}}",
    "excelExport.nameTitle.ca-hp": "Vendite {{taxType}} Continuativo",
    "excelExport.nameTitle.ca-p": "Vendite {{taxType}} Promo",
    "excelExport.nameTitle.class_desc": "Famiglia",
    "excelExport.nameTitle.item_desc": "Prodotto",
    "excelExport.nameTitle.item_key": "Item",
    "excelExport.nameTitle.main_holding_desc": "Holding",
    "excelExport.nameTitle.nielsen_market_share_sales_amount": "Quota di mercato Nielsen (in valore)",
    "excelExport.nameTitle.nielsen_market_share_sales_amount_comparison": "Quota di mercato Nielsen (in valore) vs N-1 (pp)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity": "Quota di mercato Nielsen (in quantità)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity_comparison": "Quota di mercato Nielsen (in quantità) vs N-1 (pp)",
    "excelExport.nameTitle.p-ca-p": "Peso promozionale (in valore)",
    "excelExport.nameTitle.p-uvc-p": "Peso promozionale (in quantità)",
    "excelExport.nameTitle.sales_amount": "Vendite {{taxType}}",
    "excelExport.nameTitle.sales_amount_benchmark": "Vendite Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_comparison": "Vendite {{taxType}} vs N-1",
    "excelExport.nameTitle.sales_amount_promo_percent": "Peso promozionale (in valore)",
    "excelExport.nameTitle.sales_amount_promo_percent_benchmark": "Peso promozionale (in valore) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_amount_promo_percent_comparison": "Peso promozionale (in valore) vs N-1 (pp)",
    "excelExport.nameTitle.sales_amount_with_promo": "Vendite {{taxType}} Promo",
    "excelExport.nameTitle.sales_amount_with_promo_benchmark": "Vendite Promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_with_promo_comparison": "Vendite {{taxType}} promo. vs N-1",
    "excelExport.nameTitle.sales_amount_without_promo": "Vendite {{taxType}} Continuativo",
    "excelExport.nameTitle.sales_amount_without_promo_benchmark": "Vendite senza promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_without_promo_comparison": "Vendite {{taxType}} senza promo vs N-1",
    "excelExport.nameTitle.sales_possession_rate": "Tasso di possesso sulle vendite",
    "excelExport.nameTitle.sales_possession_rate_comparison": "Tasso di possesso sulle vendite vs N-1",
    "excelExport.nameTitle.sales_quantity": "Quantità",
    "excelExport.nameTitle.sales_quantity_benchmark": "Quantità Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_comparison": "Quantità vs N-1",
    "excelExport.nameTitle.sales_quantity_promo_percent": "Peso promozionale (in quantità)",
    "excelExport.nameTitle.sales_quantity_promo_percent_benchmark": "Peso promozionale (in quantità) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_quantity_promo_percent_comparison": "Peso promozionale (in quantità) vs N-1 (pp)",
    "excelExport.nameTitle.sales_quantity_with_promo": "Quantità promo",
    "excelExport.nameTitle.sales_quantity_with_promo_benchmark": "Quantità promo Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_with_promo_comparison": "Quantità promo. vs N-1",
    "excelExport.nameTitle.sales_quantity_without_promo": "Quantità non promo",
    "excelExport.nameTitle.sales_quantity_without_promo_benchmark": "Quantità senza promo Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_without_promo_comparison": "Quantità senza promo vs N-1",
    "excelExport.nameTitle.store_chain_type_desc": "Store format",
    "excelExport.nameTitle.store_key": "Negozio",
    "excelExport.nameTitle.store_region_desc": "Regione",
    "excelExport.nameTitle.sub_class_desc": "Sottofamiglia",
    "excelExport.nameTitle.supplier_desc": "Fornitore locale",
    "excelExport.nameTitle.supplier_market_share_sales_amount": "Quota di mercato di Carrefour (in valore)",
    "excelExport.nameTitle.supplier_market_share_sales_amount_comparison": "Quota di mercato di Carrefour (in valore) vs N-1 (pp)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity": "Quota di mercato di Carrefour (in quantità)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity_comparison": "Quota di mercato di Carrefour (in quantità) vs N-1 (pp)",
    "excelExport.nameTitle.top_flop_ca_barcode": "Top & Flop 10000 di articoli (in valore)",
    "excelExport.nameTitle.top_flop_ca_item_key": "Top & Flop 10000 di articoli (in valore)",
    "excelExport.nameTitle.top_flop_uvc_barcode": "Top & Flop 10000 di articoli (in quantità)",
    "excelExport.nameTitle.top_flop_uvc_item_key": "Top & Flop 10000 di articoli (in quantità)",
    "excelExport.nameTitle.total": "Totale",
    "excelExport.nameTitle.uvc": "Quantità",
    "excelExport.nameTitle.uvc-hp": "Quantità non promo",
    "excelExport.nameTitle.uvc-p": "Quantità promo",
    "excelExport.notificationInProgressMessage": "Riceverete il vostro file via e-mail.",
    "excelExport.notificationInProgressTitle": "Esportazione in corso",
    "excelExport.scopeTitle": "Promemoria del perimetro selezionato",
    "excelExport.sendExport": "Ricevere l'esportazione via e-mail",
    "excelExport.title": "Ripartizione delle performance",
    "excelExport.top": "Top item",
    "exportChecklistData.average_price": "Prezzo medio",
    "exportChecklistData.average_weekly_sales_amount": "Vendite medie settimanali {{taxType}}",
    "exportChecklistData.average_weekly_sales_quantity": "Quantità medie settimanali",
    "exportChecklistData.distribution": "Distribuzione",
    "exportChecklistData.gross_service_rate": "Tasso di servizio lordo",
    "exportChecklistData.net_service_rate": "Tasso di servizio netto",
    "exportChecklistData.nielsen_market_share_sales_amount": "Quota di mercato Nielsen (in valore)",
    "exportChecklistData.nielsen_market_share_sales_quantity": "Quota di mercato Nielsen (in quantità)",
    "exportChecklistData.sales_amount": "Vendite {{taxType}}",
    "exportChecklistData.sales_possession_rate": "Tasso di possesso sulle vendite",
    "exportChecklistData.sales_quantity": "Quantità",
    "exportChecklistData.supplier_market_share_sales_amount": "Quota di mercato di Carrefour (in valore)",
    "exportChecklistData.supplier_market_share_sales_quantity": "Quota di mercato di Carrefour (in quantità)",
    "exportKpi.activity_type": "Canale di acquisto",
    "exportKpi.barcode": "EAN",
    "exportKpi.brand_key": "Marca",
    "exportKpi.brand_type_key": "Tipo di Marca",
    "exportKpi.class_key": "Famiglia",
    "exportKpi.department_key": "Reparto",
    "exportKpi.dimension": "Dimensione",
    "exportKpi.flopAmount_barcode": "Flop EAN (in valore)",
    "exportKpi.flopAmount_item_key": "Flop Item (in valore)",
    "exportKpi.flopQuantity_barcode": "Flop EAN (in quantità)",
    "exportKpi.flopQuantity_item_key": "Flop Item (in quantità)",
    "exportKpi.grp_class_key": "Gruppo Famiglia",
    "exportKpi.HT": "Nette",
    "exportKpi.interval": "Intervallo",
    "exportKpi.isWithTax": "Al netto o al netto delle imposte",
    "exportKpi.item_key": "Prodotto",
    "exportKpi.main_holding_key": "Holding",
    "exportKpi.perimeter": "Perimetro",
    "exportKpi.sector_key": "Settore",
    "exportKpi.store_chain_type_key": "Store format",
    "exportKpi.store_int_flag": "Integrati / Franchisee",
    "exportKpi.store_key": "Negozio",
    "exportKpi.sub_class_key": "Sottofamiglia",
    "exportKpi.supplier_key": "Fornitore locale",
    "exportKpi.topAmount_barcode": "Top EAN (in valore)",
    "exportKpi.topAmount_item_key": "Top Item (in valore)",
    "exportKpi.topQuantity_barcode": "Top EAN (in quantità)",
    "exportKpi.topQuantity_item_key": "Top Item (in quantità)",
    "exportKpi.TTC": "Lorde",
    "filterPopin.activityType": "Canale di acquisto",
    "filterPopin.activityTypesData.E-Commerce": "Ecommerce",
    "filterPopin.activityTypesData.ECM": "Ecommerce",
    "filterPopin.activityTypesData.In store": "In store",
    "filterPopin.activityTypesData.STO": "In store",
    "filterPopin.brand": "Marca",
    "filterPopin.brandType": "Tipo di Marca",
    "filterPopin.chainType": "Store format",
    "filterPopin.cpgVersion.brand": "Marca",
    "filterPopin.cpgVersion.localSupplierAndBrand": "Fornitore locale / Marca",
    "filterPopin.ean": "EAN",
    "filterPopin.hint.availableOnCarrefour": "Utilizzare la tassonomia Carrefour",
    "filterPopin.hint.availableOnNielsen": "Utilizzare la tassonomia Nielsen",
    "filterPopin.holding": "Holding",
    "filterPopin.holdingAndBrand": "Holding / Marca",
    "filterPopin.holdingAndLocalSupplier": "Holding / Fornitore locale",
    "filterPopin.holdingAndLocalSupplierAndBrand": "Holding / Fornitore locale / Marca",
    "filterPopin.integrateFranchised": "Integrati / Franchisee",
    "filterPopin.integrateFranchisedData.0": "Franchisee",
    "filterPopin.integrateFranchisedData.1": "Integrati",
    "filterPopin.integrateFranchisedData.2": "Unknown",
    "filterPopin.integrateFranchisedData.2ESP": "Dark stores",
    "filterPopin.item": "Item",
    "filterPopin.localSupplier": "Fornitore locale",
    "filterPopin.nomenclature.carrefour": "Carrefour",
    "filterPopin.nomenclature.nielsen": "Nielsen",
    "filterPopin.nomenclature.title": "Nomenclatura",
    "filterPopin.productNomenclature": "Nomenclatura prodotto",
    "filterPopin.productPlaceholder": "Inserire almeno 4 caratteri nella barra di ricerca",
    "filterPopin.region": "Regione",
    "filterPopin.searchBar": "Cerca un articolo, una marca, un formato...",
    "filterPopin.store": "Negozio",
    "filterPopin.title": "Filtri",
    "impersonateFrame.timeRemaining": "Tempo rimanente",
    "likeForLike.infoBanner.lineEcommerce": "L'e-commerce.",
    "likeForLike.infoBanner.lineOne": "I negozi che non sono stati aperti per un numero comparabile di giorni tra n e n-1.",
    "likeForLike.infoBanner.lineTwo": "I negozi che hanno cambiato tipologia tra l'anno n e n-1 (formato, stato, paese...).",
    "likeForLike.infoBanner.title": "Nel modo Like for Like, il perimetro di analisi esclude:",
    "likeForLike.warningBanner.content": "Nota: Alcuni negozi che sono stati chiusi per meno di un mese sono inclusi nel campo di analisi su base mensile.",
    "marketShareTableauEnbedding.info": "Il contenuto qui sotto è ospitato su Tableau. Si prega di autenticarsi e reinserire i filtri.",
    "marketShareTableauEnbedding.subtitle": "Per andare oltre, acceda di seguito alla sintesi delle quote di mercato del Cockpit de Performance.",
    "marketShareTableauEnbedding.title": "Sintesi Quota di Mercato",
    "onboardingConfirmation.confirmationButton": "Convalidare e scoprire la piattaforma",
    "onboardingConfirmation.language": "Lingua",
    "onboardingConfirmation.languageEdit": "Modificare",
    "onboardingConfirmation.perimeter": "Il vostro perimetro predefinito",
    "onboardingConfirmation.perimeterEdit": "Modificare il perimetro predefinito",
    "onboardingConfirmation.subtitle": "Controllare le impostazioni",
    "onboardingConfirmation.title": "Manca solo un ultimo passo prima di poter scoprire la piattaforma!",
    "onboardingLanguage.button": "Lingua",
    "onboardingLanguage.confirmationButton": "Confermare la mia scelta e passare alla fase successiva",
    "onboardingLanguage.subtitle": "Innanzitutto, impostare la lingua predefinita.",
    "onboardingLanguage.title": "Benvenuti a Carrefour Links Growth !",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.esp": "Il canale di e-commerce è escluso dal perimetro del parco Like for Like",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.ita": "Il canale di e-commerce è escluso dal perimetro del parco Like for Like",
    "onboardingPerimeter.activityType.subtitle": "Canale di acquisto",
    "onboardingPerimeter.activityType.title": "Su quale canale di acquisto desidera monitorare le prestazioni per impostazione predefinita?",
    "onboardingPerimeter.chainType.subtitle": "Store format",
    "onboardingPerimeter.chainType.title": "Su quale formato di negozio desidera monitorare le prestazioni per impostazione predefinita?",
    "onboardingPerimeter.confirmationButton": "Confermare la mia scelta e passare alla fase successiva",
    "onboardingPerimeter.granularity.buttonTitle": "Granularità dell'analisi",
    "onboardingPerimeter.granularity.monthly": "Mensile",
    "onboardingPerimeter.granularity.title": "A quale granularità volete monitorare le prestazioni del vostro perimetro? *",
    "onboardingPerimeter.granularity.weekly": "Settimanale",
    "onboardingPerimeter.productHierarchy.button": "Aggiungere una nomenclatura prodotto",
    "onboardingPerimeter.productHierarchy.class": "Famiglia",
    "onboardingPerimeter.productHierarchy.department": "Reparto",
    "onboardingPerimeter.productHierarchy.grpclass": "Gruppo Famiglia",
    "onboardingPerimeter.productHierarchy.sector": "Settore",
    "onboardingPerimeter.productHierarchy.subclass": "Sottofamiglia",
    "onboardingPerimeter.productHierarchy.subtitle": "Nomenclatura prodotto",
    "onboardingPerimeter.productHierarchy.title": "Quale perimetro di prodotto predefinito desideri utilizzare?",
    "onboardingPerimeter.salesAmountFormat.buttonTitle": "Valore KPI",
    "onboardingPerimeter.salesAmountFormat.HT": "Nette",
    "onboardingPerimeter.salesAmountFormat.title": "Desidera utilizzare indicatori in netto o in lordo per impostazione predefinita?",
    "onboardingPerimeter.salesAmountFormat.TTC": "Lorde",
    "onboardingPerimeter.storePerimeter.allStores.content": "Il perimetro include tutti i negozi del parco.",
    "onboardingPerimeter.storePerimeter.allStores.title": "Intero parco",
    "onboardingPerimeter.storePerimeter.likeForLike.full.content": "Il perimetro esclude i negozi con meno di un anno di anzianità, chiusi da più di un anno o che hanno cambiato tipologia durante il periodo di studio.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.content": "Il perimetro esclude i negozi che non hanno un numero comparabile di giorni di apertura tra i periodi n-1 e n. Anche l'e-commerce è escluso.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.close": "Capito",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.esp": "In Spagna, il canale di acquisto e-commerce non è disponibile per il Parco Costante. Per selezionare l'opzione Like for Like, si prega di selezionare il canale di acquisto solo in negozio",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.ita": "In Italia, il canale di acquisto e-commerce non è disponibile per il Parco Costante. Per selezionare l'opzione Like for Like, si prega di selezionare il canale di acquisto solo in negozio",
    "onboardingPerimeter.storePerimeter.likeForLike.title": "Like for like",
    "onboardingPerimeter.storePerimeter.subtitle": "Perimetro de parco",
    "onboardingPerimeter.storePerimeter.title": "Su quale perimetro di parco negozi desidera analizzare l'evoluzione degli indicatori?",
    "onboardingPerimeter.subtitle": "A ogni accesso, gli indicatori di monitoraggio delle prestazioni saranno calcolati e visualizzati per il perimetro definito in questa fase. È possibile modificare questa impostazione in qualsiasi momento nelle impostazioni dell'account.",
    "onboardingPerimeter.timeframe.buttonTitle": "Periodo di tempo",
    "onboardingPerimeter.timeframe.cumulative_to_date": "Anno mobile fino ad oggi",
    "onboardingPerimeter.timeframe.last_period": "Periodo precedente",
    "onboardingPerimeter.timeframe.title": "Su quale periodo di tempo vorreste vedere i vostri indicatori di performance per ogni connessione? *",
    "onboardingPerimeter.timeframe.year_to_date": "Totale ad oggi",
    "onboardingPerimeter.title": "Quindi definire il perimetro predefinito.",
    "performanceCarrefourCatman.salesAmountFormat.HT": "Nette",
    "performanceCarrefourCatman.salesAmountFormat.nielsenIsWithTax": "Nella tassonomia Nielsen, le vendite sono calcolate al lordo di tutte le imposte.",
    "performanceCarrefourCatman.salesAmountFormat.TTC": "Lorde",
    "performanceCarrefourCatman.storePerimeter.allStores": "Intero parco",
    "performanceCarrefourCatman.storePerimeter.hintEcommerceNotAvailable": "El canal de compras e-commerce no está disponible en el análisis Like for like.",
    "performanceCarrefourCatman.storePerimeter.hintLikeForLikeNotAvailableWithEcommerce": "El análisis Like for like no está disponible cuando se selecciona el canal de compras e-commerce.",
    "performanceCarrefourCatman.storePerimeter.likeForLike": "Like for like",
    "performanceCarrefourCatman.storePerimeter.nielsenAllStores": "Nella tassonomia di Nielsen, la modalità Like for Like non è disponibile.",
    "performanceCarrefourCatman.subtitleDistribution": "Distribuzione",
    "performanceCarrefourCatman.subtitlePossessionAndDistribution": "Possesso e distribuzione",
    "performanceCarrefourCatman.subtitleSales": "Vendite",
    "performanceCarrefourCatman.subtitleServiceRate": "Fornitura",
    "performanceCarrefourCatman.subtitleStock": "Inventario",
    "performanceCarrefourCatman.subtitleSupply": "Fornitura",
    "performanceCarrefourCatman.title": "Performance di categoria",
    "performanceCarrefourCatmanCpgView.benchmark": "Mostra benchmark",
    "performanceCarrefourCatmanCpgView.title": "Performance del fornitore",
    "performanceCarrefourCatmanCpgView.warning": "Attualmente stai visualizzando la pagina di",
    "projectForm.cancelPopin.confirmationNotification": "Il progetto è stato eliminato correttamente.",
    "projectForm.cancelPopin.deleteButton": "Eliminare il progetto",
    "projectForm.cancelPopin.description": "Attenzione, tutte le informazioni completate saranno perse.\nSei sicuro di voler annullare la creazione di questo progetto?",
    "projectForm.cancelPopin.discardButton": "Tornare al progetto",
    "projectForm.cancelPopin.title": "Annullare il nuovo progetto",
    "projectForm.step1.description": "Inserisci le informazioni che caratterizzano il tuo progetto.",
    "projectForm.step1.endDate": "Data di fine del progetto",
    "projectForm.step1.projectDescription": "Descrizione del progetto",
    "projectForm.step1.projectDescription.buttonDescription": "Descrivi il tuo progetto in poche righe",
    "projectForm.step1.projectName": "Nome del progetto",
    "projectForm.step1.projectName.buttonDescription": "Scegli un nome per il tuo progetto",
    "projectForm.step1.projectSupplier": "Fornitore che partecipa al progetto",
    "projectForm.step1.projectSupplier.buttonDescription": "Trova il fornitore con cui stai realizzando il progetto",
    "projectForm.step1.projectType": "Tipo di progetto",
    "projectForm.step1.projectType.buttonDescription": "Seleziona un tipo di progetto (impostazione predefinita)",
    "projectForm.step1.startDate": "Data di inizio del progetto",
    "projectForm.step1.title": "Caratteristiche del progetto",
    "projectForm.step2.brand": "Marca",
    "projectForm.step2.brand.button": "Aggiungi una marca",
    "projectForm.step2.chainType": "Formato",
    "projectForm.step2.chainType.button": "Aggiungi un tipo di catena",
    "projectForm.step2.description": "Inserisci l'ambito del tuo progetto per monitorarne le prestazioni.",
    "projectForm.step2.performanceGranularity": "A quale frequenza vuoi monitorare le prestazioni del tuo progetto ?*",
    "projectForm.step2.performanceGranularity.buttonDescription": "Seleziona la frequenza",
    "projectForm.step2.performanceGranularity.information": "Esempio, se scegli la frequenza mensile, la performance del progetto verrà visualizzata per impostazione \npredefinita sull'ultimo mese disponibile e l'evoluzione del progetto verrà presentata mese per mese nei \ngrafici.",
    "projectForm.step2.product": "Prodotto",
    "projectForm.step2.product.button": "Aggiungi un prodotto",
    "projectForm.step2.productHierarchy": "Nomenclatura prodotto",
    "projectForm.step2.productHierarchy.button": "Aggiungi un gruppo famiglia, una famiglia, una sottofamiglia",
    "projectForm.step2.store": "Negozio",
    "projectForm.step2.store.button": "Aggiungere un formato o un negozio",
    "projectForm.step2.title": "Scopo del progetto",
    "projectForm.step3.button": "Valutazione",
    "projectForm.step3.buttonDescription": "Seleziona una valutazione",
    "projectForm.step3.description": "Si prega di indicare qual è il proprio obiettivo di performance relativo agli indicatori disponibili nell'ambito del progetto.",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi": "Vendite medie settimanali",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.buttonDescription": "Stabilire un obiettivo di Vendite medie settimanali da raggiungere",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.value": "Importo in €",
    "projectForm.step3.targetObjective.averageWeeklySalesKpi.valueDescription": "Vendite medie settimanali",
    "projectForm.step3.targetObjective.quantityKpi": "Quantità",
    "projectForm.step3.targetObjective.quantityKpi.buttonDescription": "Stabilire un obiettivo di quantità da raggiungere",
    "projectForm.step3.targetObjective.quantityKpi.value": "Importo in Quantità",
    "projectForm.step3.targetObjective.quantityKpi.valueDescription": "Quantità",
    "projectForm.step3.targetObjective.salesKpi": "Vendite lorde",
    "projectForm.step3.targetObjective.salesKpi.buttonDescription": "Stabilire un obiettivo di vendita lordo da raggiungere",
    "projectForm.step3.targetObjective.salesKpi.value": "Importo in €",
    "projectForm.step3.targetObjective.salesKpi.valueDescription": "Vendite lorde",
    "projectForm.step3.title": "Valutazione del progetto",
    "projectForm.title": "Crea un nuovo progetto",
    "projectOverview.allProjectsFilter": "Tutti i miei progetti",
    "projectOverview.allStatusFilter": "Tutti gli stati",
    "projectOverview.allSuppliersFilter": "Tutti i fornitori",
    "projectOverview.cta": "Crea un nuovo progetto",
    "projectOverview.title": "Consulta i progetti",
    "salesAmount.barChart": "Variazione delle vendite {{taxType}} rispetto all'anno precedente",
    "salesAmount.lineChart": "Ripartizione delle vendite {{taxType}}",
    "salesAmount.pieChart": "Analisi delle vendite {{taxType}}",
    "salesAmount.switchButton": "Visualizza quantità",
    "salesAmount.title": "Analisi delle vendite {{taxType}}",
    "salesQuantity.barChart": "Variazione delle quantità vendute rispetto a N-1",
    "salesQuantity.lineChart": "Ripartizione delle quantità",
    "salesQuantity.pieChart": "Analisi delle quantità vendute",
    "salesQuantity.switchButton": "Display in CA",
    "salesQuantity.title": "Analisi delle quantità vendute",
    "scorecard.grossServiceRateDownstream.tooltip": "Quota degli ordini consegnati puntualmente in negozio dai magazzini. Il tasso di servizio lordo non tiene conto delle rettifiche effettuate dalle squadre operative. È un rapporto di quantità espresse in in numero di prodotti.",
    "scorecard.grossServiceRateUpstream.tooltip": "Quota degli ordini consegnati puntualmente dai fornitori al magazzino. Il tasso di servizio lordo non tiene conto delle rettifiche effettuate dalle squadre operative. È un rapporto di quantità espresse in in numero di prodotti.",
    "scorecard.marketshareUnavailable": "Per visualizzare la quota di mercato Nielsen, selezionare la modalità Nielsen e la granularità della settimana o del periodo Nielsen.",
    "scorecard.numdistUnavailable_barcode": "Per visualizzare la distribuzione numerica, selezionare uno codice prodotto e una granularità mensile o settimanale.\nQuota dei negozi che hanno venduto il riferimento del prodotto.",
    "scorecard.numdistUnavailable_item_key": "Per visualizzare la distribuzione numerica, selezionare uno codice prodotto e una granularità mensile o settimanale.\nQuota dei negozi che hanno venduto il riferimento del prodotto.",
    "scorecard.numericalDistribution.tooltip": "Quota dei negozi che hanno venduto il riferimento del prodotto. \nDisponibile solo selezionando uno codice prodotto e una granularità mensile o settimanale.",
    "scorecard.salesPossessionRate.tooltip": "Quota delle referenze dei prodotti venduti rispetto all'assortimento teorico dei negozi.",
    "scorecard.serviceRateDownstream.tooltip": "Quota degli ordini consegnati puntualmente in negozio dai magazzini. Il tasso di servizio netto tiene conto delle rettifiche effettuate dalle squadre operative è un rapporto di quantità espresse in valore. Il tasso di servizio lordo è un rapporto di quantità espresse in numero di prodotti.",
    "scorecard.serviceRateUpstream.tooltip": "Quota degli ordini consegnati in tempo dai fornitori al magazzino. Il tasso di servizio netto tiene conto delle rettifiche effettuate dalle squadre operative ed è un rapporto di quantità espresse in valore. Il tasso di servizio lordo è un rapporto di quantità espresse in numero di prodotti.",
    "scorecard.stockWarehouse.tooltip": "Valore e quantità delle scorte in magazzino. Il valore delle scorte è sempre espresso al netto delle imposte. La quantità delle scorte è espressa in quantità.",
    "scorecard.weighdistUnavailable_barcode": "Per visualizzare la distribuzione ponderata, selezionare uno codice prodotto e una granularità mensile o settimanale.\nRapporto tra le vendite generate dalla categoria per tutti i negozi che vendono il prodotto e le vendite generate dalla categoria per tutti i negozi.",
    "scorecard.weighdistUnavailable_item_key": "Per visualizzare la distribuzione ponderata, selezionare uno codice prodotto e una granularità mensile o settimanale.\nRapporto tra le vendite generate dalla categoria per tutti i negozi che vendono il prodotto e le vendite generate dalla categoria per tutti i negozi.",
    "scorecard.weightedDistribution.tooltip": "Rapporto tra le vendite generate dalla categoria per tutti i negozi che vendono il prodotto e le vendite generate dalla categoria per tutti i negozi.\nDisponibile solo selezionando uno codice prodotto e una granularità mensile o settimanale.",
    "scorecardPerformance.averagePrice": "Prezzo medio {{taxType}})",
    "scorecardPerformance.averagePricePromo": "Prezzo medio promo {{taxType}}",
    "scorecardPerformance.averagePriceWithoutTax": "Prezzo medio Nette",
    "scorecardPerformance.averagePriceWithoutTaxPromo": "Prezzo medio promo Nette",
    "scorecardPerformance.averagePriceWithTax": "Prezzo medio Lorde",
    "scorecardPerformance.averagePriceWithTaxPromo": "Prezzo medio promo Lorde",
    "scorecardPerformance.averagePromoPrice": "Prezzo medio promo {{taxType}})",
    "scorecardPerformance.averageWeeklySales": "Vendite medie settimanali {{taxType}}",
    "scorecardPerformance.averageWeeklySalesAmountWithoutTax": "Vendite medie settimanali Nette",
    "scorecardPerformance.averageWeeklySalesAmountWithTax": "Vendite medie settimanali Lorde",
    "scorecardPerformance.averageWeeklySalesInUnits": "Quantità medie settimanali",
    "scorecardPerformance.averageWeeklySalesQuantity": "Quantità medie settimanali",
    "scorecardPerformance.carrefourMarketShareValue": "Quota di Mercato Carrefour (VL)",
    "scorecardPerformance.carrefourMarketShareVolume": "Quota di Mercato Carrefour (Quantità)",
    "scorecardPerformance.grossServiceRateDownstream": "Tasso di servizio lordo a valle",
    "scorecardPerformance.grossServiceRateUpstream": "Tasso di servizio lordo a monte",
    "scorecardPerformance.netServiceRateDownstream": "Tasso di servizio netto a valle",
    "scorecardPerformance.netServiceRateUpstream": "Tasso di servizio netto a monte",
    "scorecardPerformance.nielsenMarketShareValue": "Quota di Mercato Nielsen (VL)",
    "scorecardPerformance.nielsenMarketShareVolume": "Quota di Mercato Nielsen (Quantità)",
    "scorecardPerformance.nonavailable": "Questo indicatore sarà disponibile a breve.",
    "scorecardPerformance.nonavailableperimeter": "Non disponibile su questo perimetro",
    "scorecardPerformance.nonPromo": "Continuativo",
    "scorecardPerformance.notAvailableForCarrefourTaxonomy": "Disponibile solo nella tassonomia Nielsen",
    "scorecardPerformance.notAvailableForCurrentPerimeter": "Non disponibile su questo perimetro",
    "scorecardPerformance.notAvailableForNielsenTaxonomy": "Disponibile solo nella tassonomia Carrefour",
    "scorecardPerformance.numericalDistribution": "Distribuzione numerica",
    "scorecardPerformance.pressure": "Peso",
    "scorecardPerformance.promo": "Promo.",
    "scorecardPerformance.promoPressure": "Peso promo",
    "scorecardPerformance.quantity": "Quantità",
    "scorecardPerformance.salesPossessionRate": "Tasso di possesso sulle vendite",
    "scorecardPerformance.salesValue": "Vendite {{taxType}}",
    "scorecardPerformance.salesValueWithoutTax": "Vendite Nette",
    "scorecardPerformance.salesValueWithTax": "Vendite Lorde",
    "scorecardPerformance.serviceRate": "Tasso di servizio",
    "scorecardPerformance.serviceRate.gross": "Lorde",
    "scorecardPerformance.serviceRate.net": "Nette",
    "scorecardPerformance.serviceRateDownstream": "Tasso di servizio a valle",
    "scorecardPerformance.serviceRateUpstream": "Tasso di servizio a monte",
    "scorecardPerformance.stockStore": "Stock punto vendita",
    "scorecardPerformance.stockStore.tooltip": "Valore e quantità delle scorte nel negozio. Il valore delle scorte è sempre espresso al netto delle imposte. La quantità delle scorte è espressa in quantità.",
    "scorecardPerformance.stockStoreQuantity": "Stock punto vendita",
    "scorecardPerformance.stockStoreWithoutTax": "Stock punto vendita nette",
    "scorecardPerformance.stockTotal": "Stock totale",
    "scorecardPerformance.stockTotal.notAvailableWithPermissions": "Non disponibile per il tuo livello di autorizzazione",
    "scorecardPerformance.stockTotal.tooltip": "Somma delle scorte del negozio e del magazzino. Il valore delle scorte è sempre espresso al netto delle imposte. La quantità delle scorte è espressa in quantità.",
    "scorecardPerformance.stockTotalQuantity": "Stock totale",
    "scorecardPerformance.stockTotalWithoutTax": "Stock totale nette",
    "scorecardPerformance.stockWarehouse": "Stock deposito",
    "scorecardPerformance.stockWarehouseQuantity": "Stock deposito",
    "scorecardPerformance.stockWarehouseWithoutTax": "Stock deposito nette",
    "scorecardPerformance.weekAbreviation": "S",
    "scorecardPerformance.weightedDistribution": "Distribuzione ponderata",
    "sideMenu.accountManagement": "Gestione dell'account",
    "sideMenu.administration": "Amministrazione",
    "sideMenu.performance": "Performance di categoria",
    "sideMenu.performanceReview": "Performance di categoria",
    "sideMenu.projects": "Progetti",
    "sideMenu.settings": "Accesso ai parametri",
    "sideMenu.shortcut": "Mostra il menu laterale",
    "sideMenu.tools": "Strumenti",
    "sideMenu.userSupport": "Supporto agli utenti dell'accesso",
    "supplierPopin.description": "Seleziona un fornitore per accedere alla panoramica delle prestazioni del suo ambito.",
    "supplierPopin.recentSuppliers": "Fornitore recentemente cercato",
    "supplierPopin.searchBar": "Trova un fornitore",
    "supplierPopin.title": "Accedi alla panoramica del fornitore",
    "support.contactSupport": "Contattare l'helpdesk",
    "support.formSubmitted.goBack": "Torna alla mia navigazione",
    "support.formSubmitted.text": "Su solicitud ha sido recibida y nuestro equipo de soporte le responderá por correo electrónico lo antes posible. Gracias por su paciencia.",
    "support.formSubmitted.title": "Grazie per averci contattato!",
    "support.readOurDocumentation": "Leggete la nostra documentazione",
    "timeSelector.vsLastYear": "rispetto all'anno precedente",
    "timeSelectorPopin.apply": "Applicare",
    "timeSelectorPopin.cumulative_to_date": "Anno mobile fino ad oggi",
    "timeSelectorPopin.customInterval": "Intervallo personalizzato",
    "timeSelectorPopin.description": "Selezionare la granularità dell'analisi e un intervallo di tempo.",
    "timeSelectorPopin.descriptionEssentialPackage": "Selezionare l'intervallo di tempo per l'analisi.",
    "timeSelectorPopin.granularity.daily": "Giorno",
    "timeSelectorPopin.granularity.monthly": "Mensile",
    "timeSelectorPopin.granularity.nielsen": "Periodo Nielsen",
    "timeSelectorPopin.granularity.weekly": "Settimanale",
    "timeSelectorPopin.last_period": "Periodo precedente",
    "timeSelectorPopin.subtitleGranularity": "Granularità dell'analisi",
    "timeSelectorPopin.subtitleInterval": "Intervallo di tempo",
    "timeSelectorPopin.title": "Intervallo di tempo per l'analisi",
    "timeSelectorPopin.year_to_date": "Totale ad oggi",
    "tools.accessButton": "Aggiungere uno strumento",
    "tools.createButton": "Aggiungi un link",
    "tools.empty.addButton": "Aggiungi uno strumento",
    "tools.empty.text.all.carrefour": "Non avete ancora strumenti su questa pagina. {br} Si prega di contattare l'amministratore {br} o inizi ad aggiungerli da solo.",
    "tools.empty.text.all.supplier": "Non avete ancora strumenti su questa pagina. {br} Inizi ad aggiungerli da solo.",
    "tools.empty.text.custom": "Non hai ancora strumenti su questa pagina.\n{br} Inizia aggiungendone uno.",
    "tools.empty.text.favorites": "Non avete ancora strumenti su questa pagina. {br} Inizi ad aggiungerne alcuni ai vostri preferiti.",
    "tools.empty.text.recommended": "Non avete ancora strumenti su questa pagina. {br} Si prega di contattare l'amministratore.",
    "tools.empty.title": "Benvenuto!",
    "tools.featured.title": "In primo piano",
    "tools.popup.add": "Aggiungi nuova scorciatoia",
    "tools.popup.cancel": "Annullare",
    "tools.popup.create.error.message": "Non è stato possibile creare la scorciatoia",
    "tools.popup.create.error.title": "Si è verificato un errore",
    "tools.popup.create.success.message": "La scorciatoia è stata creata con successo",
    "tools.popup.create.success.title": "Scorciatoia creata",
    "tools.popup.createTitle": "Nuova scorciatoia",
    "tools.popup.delete.error.message": "Non è stato possibile eliminare il collegamento",
    "tools.popup.delete.error.title": "Si è verificato un errore",
    "tools.popup.delete.success.message": "La scorciatoia è stata eliminata con successo",
    "tools.popup.delete.success.title": "Scorciatoia eliminata",
    "tools.popup.deleteButton": "Cancellare",
    "tools.popup.deleteDescription": "Vuoi davvero cancellare la scorciatoia?",
    "tools.popup.deleteTitle": "Cancellare la scorciatoia",
    "tools.popup.description": "Descrizione",
    "tools.popup.descriptionPlaceholder": "Descrizione dello strumento",
    "tools.popup.form.invalidUrl": "L'URL fornito non è valido",
    "tools.popup.form.notEmpty": "Questo campo è obbligatorio",
    "tools.popup.form.tooLong": "La descrizione non deve superare i 300 caratteri",
    "tools.popup.keepButton": "Mantenere",
    "tools.popup.label": "Nome della scorciatoia",
    "tools.popup.link": "Link",
    "tools.popup.shortcut": "Scorciatoia",
    "tools.popup.update.error.message": "Non è stato possibile aggiornare la scorciatoia",
    "tools.popup.update.error.title": "Si è verificato un errore",
    "tools.popup.update.success.message": "La scorciatoia è stata aggiornata con successo",
    "tools.popup.update.success.title": "Scorciatoia aggiornata",
    "tools.popup.updateTitle": "Modificare la scorciatoia",
    "tools.title": "Strumenti e servizi",
    "toolsFrA3distrib.description": "Consultare i cataloghi dei concorrenti",
    "toolsFrA3distrib.title": "A3 Distrib",
    "toolsFrAgathe.description": "Impostare la mia gamma di prodotti",
    "toolsFrAgathe.title": "Agathe",
    "toolsFrApoline.description": "Configurare le mie operazioni promozionali",
    "toolsFrApoline.title": "Apoline",
    "toolsFrAssortmentBuilder.description": "Definire la mia gamma di prodotti",
    "toolsFrAssortmentBuilder.title": "Assortment Builder",
    "toolsFrBasePhoto.description": "Visualizza le immagini dei miei prodotti",
    "toolsFrBasePhoto.title": "Base Photo",
    "toolsFrBcpNoyau.description": "Consultare gli attributi dei miei prodotti",
    "toolsFrBcpNoyau.title": "BCP Noyau",
    "toolsFrDataInsights.description": "Estrarre i rapporti sulle prestazioni",
    "toolsFrDataInsights.title": "Data Insigths",
    "toolsFrMemory.description": "Accedere ai moduli di gestione delle categorie",
    "toolsFrMemory.title": "Memory",
    "toolsFrNielsen.description": "Visualizza le quote di mercato settimanali",
    "toolsFrNielsen.title": "Nielsen PDM Hebdo PGC",
    "toolsFrPilotagePerfAlimentaire.description": "Consultare i tassi di servizio",
    "toolsFrPilotagePerfAlimentaire.title": "Pilotage de la Performance Alimentaire",
    "toolsItAnalytics.description": "Accesso ai dashboards",
    "toolsItAnalytics.title": "Analytics Italia website",
    "toolsItMicrostrategy.description": "Estrarre i rapporti sulle prestazioni",
    "toolsItMicrostrategy.title": "Report Builder/Microstrategy",
    "userSettings.confirmation": "Confermare la mia scelta",
    "userSettings.globalInformation.email": "Email",
    "userSettings.globalInformation.name": "Nome e cognome",
    "userSettings.globalInformation.role": "Ruolo",
    "userSettings.globalInformation.subtitle": "Gestione delle informazioni e dei dati di accesso",
    "userSettings.globalInformation.title": "Informazioni sul conto",
    "userSettings.logout.button": "Disconnessione",
    "userSettings.logout.subtitle": "Disconnessione dalla piattaforma",
    "userSettings.logout.title": "Disconnessione",
    "userSettings.perimeter.subtitle": "Gestire il perimetro predefinito",
    "userSettings.perimeter.title": "Perimetro predefinito",
    "userSettings.settings": "Parametri",
    "userSettings.title": "Impostazioni dell'account",
    "userTools.data.a3-distrib.description": "Consulta cataloghi concorrenti",
    "userTools.data.a3-distrib.name": "A3 Distrib",
    "userTools.data.agathe.description": "Configura il mio assortimento",
    "userTools.data.agathe.name": "Agathe",
    "userTools.data.analytics-italia-website.description": "Accedi ai pannelli di controllo",
    "userTools.data.analytics-italia-website.name": "Sito web Analytics Italia",
    "userTools.data.apoline.description": "Configura le mie operazioni promozionali",
    "userTools.data.apoline.name": "Apoline",
    "userTools.data.assortment-builder.description": "Definisci il mio assortimento",
    "userTools.data.assortment-builder.name": "Assortment Builder",
    "userTools.data.base-photo.description": "Consulta le immagini dei miei prodotti",
    "userTools.data.base-photo.name": "Base Photo",
    "userTools.data.bcp-noyau.description": "Consulta attributi dei miei prodotti",
    "userTools.data.bcp-noyau.name": "BCP Noyau",
    "userTools.data.cockpit-de-performance.description": "Punto de entrada único para el seguimiento de la cuota de mercado",
    "userTools.data.cockpit-de-performance.name": "Cockpit delle prestazioni",
    "userTools.data.data-insights.description": "Estrai report sulle prestazioni",
    "userTools.data.data-insights.name": "Data Insights",
    "userTools.data.memory.description": "Accedi ai moduli di gestione delle categorie",
    "userTools.data.memory.name": "Memory",
    "userTools.data.microstrategy-web.name": "Microstrategy WEB",
    "userTools.data.nielsen-pdm-hebdo-pgc.description": "Consulta le quote di mercato settimanali",
    "userTools.data.nielsen-pdm-hebdo-pgc.name": "Nielsen PDM Settimanale PGC",
    "userTools.data.pilotage-de-la-performance-alimentaire.description": "Consulta i tassi di servizio",
    "userTools.data.pilotage-de-la-performance-alimentaire.name": "Gestione delle Prestazioni Alimentari",
    "userTools.data.rcm-circuitos.name": "RCM Circuitos",
    "userTools.data.rcm-referencial.name": "RCM referencial",
    "userTools.data.report-builder/microstrategy.description": "Estrai report sulle prestazioni",
    "userTools.data.report-builder/microstrategy.name": "Report Builder/Microstrategy",
    "userTools.data.reporting-digital-mercancias.name": "Reporting digital Mercancías",
    "userTools.filters.all": "Tutti",
    "userTools.filters.favorites": "I miei preferiti",
    "userTools.link": "Accedere",
    "userTools.tags.custom": "Aggiunto da me",
    "userTools.tags.recommended": "Consigliato",
    "userTools.title": "Strumenti e servizi",
    "viewAsSupplier.action": "Azione",
    "viewAsSupplier.choose": "Scegliere",
    "viewAsSupplier.noSupplierWithPlatformAccess": "Nessun fornitore si è ancora iscritto alla piattaforma.",
    "viewAsSupplier.searchSupplier": "Ricerca di un fornitore",
    "viewAsSupplier.selectSupplier": "Si prega di selezionare un fornitore tra quelli che hanno accesso alla piattaforma",
    "viewAsSupplier.supplier": "Fornitore"
  },
  "es": {
    "accountCreationPage.companySelection": "Seleccione la empresa a la que pertenece",
    "accountCreationPage.information": "Si su empresa no aparece, es que no existe en nuestros sistemas.",
    "accountCreationPage.subtitle": "Siga el rendimiento de su categoría y colabore con sus proveedores en una única plataforma.",
    "accountCreationPage.validateCompanyButton": "Enviar mi solicitud de acceso",
    "accountManagerSettings.activationDate": "Fecha de activación",
    "accountManagerSettings.dateRequest": "Solicitud recibida el",
    "accountManagerSettings.listTitle": "Lista de usuarios",
    "accountManagerSettings.noPendingRequest": "No hay solicitudes pendientes",
    "accountManagerSettings.requestTitle": "Nuevas solicitudes",
    "accountManagerSettings.role.ACCOUNT_MANAGER": "Account Manager",
    "accountManagerSettings.role.ADMIN": "Admin",
    "accountManagerSettings.role.METRIC_VIEWER": "Metric Viewer",
    "accountManagerSettings.role.PROJECT_MANAGER": "Project Manager",
    "accountManagerSettings.role.PROJECT_VIEWER": "Project Viewer",
    "accountManagerSettings.title": "Usuarios activos",
    "accountWaitingPage.accountReject": "Lo sentimos, pero parece que no tienes acceso a la plataforma.",
    "accountWaitingPage.accountRejectSubtitle": "Para más información, póngase en contacto con",
    "accountWaitingPage.subtitle": "Esté atento a su bandeja de entrada: le enviaremos un email cuando su solicitud haya sido aprobada por el remitente de su empresa. Para más información, ponte en contacto con el representante de tu empresa.",
    "accountWaitingPage.title": "Su solicitud de acceso se ha enviado correctamente.",
    "admin.activeUsers": "Usuarios activos",
    "admin.filter.globalSearch.placeholder": "Buscar por nombre de usuario, correo electrónico, país, rol",
    "admin.helperText": "Encuentra al usuario que buscas y haz clic en suplantar para ver y actuar en su nombre.",
    "admin.impersonateFrame.stop": "Dejar de suplantar",
    "admin.impersonationImpossibleIfAdmin": "No se puede usurpar una función de administrador",
    "admin.popin.button.start": "Iniciar sesión como este usuario",
    "admin.popin.title": "Sesión de suplantación",
    "admin.popin.warning": "Al utilizar esta función, navegarás como si fueras este usuario, todas las acciones se guardarán como si fueras esta persona. Úsalo con precaución.",
    "admin.title": "Panel de administración",
    "admin.userList.cell.impersonate": "Suplantar",
    "admin.userList.column.actions": "Acciones",
    "admin.userList.column.holdingCountry": "Holding/Country",
    "admin.userList.column.roles": "Rol(es)",
    "admin.userList.column.user": "Usuario",
    "authenticationPage.emailPassword": "Iniciar sesión con correo electrónico",
    "authenticationPage.error": "Parece que su empresa no existe en nuestros sistemas.",
    "authenticationPage.google": "Conectar con Google",
    "authenticationPage.microsoft": "Conectar con Microsoft",
    "authenticationPage.popin.continue": "Continuar",
    "authenticationPage.popin.email": "Correo electrónico",
    "authenticationPage.popin.email.invalid": "Formato de correo electrónico inválido",
    "authenticationPage.popin.email.notEmpty": "El correo electrónico es obligatorio",
    "authenticationPage.popin.email.subtitle": "Ingrese la dirección de correo electrónico y la contraseña",
    "authenticationPage.popin.email.title": "Iniciar sesión con correo electrónico",
    "authenticationPage.popin.forgotPassword": "¿Olvidó su contraseña?",
    "authenticationPage.popin.goBack": "Volver",
    "authenticationPage.popin.invalidCredential": "Correo electrónico o contraseña inválidos",
    "authenticationPage.popin.password": "Contraseña",
    "authenticationPage.popin.password.notEmpty": "La contraseña es obligatoria",
    "authenticationPage.popin.reset-sent": "Se ha enviado un correo electrónico. Por favor, haga clic en el enlace para restablecer su contraseña",
    "authenticationPage.popin.reset.subtitle": "Ingrese la dirección de correo electrónico para restablecer su contraseña",
    "authenticationPage.popin.reset.title": "Restablecer su contraseña",
    "authenticationPage.popin.send": "Enviar",
    "authenticationPage.subtitle": "Siga el rendimiento de su categoría y colabore con sus proveedores en una única plataforma.",
    "authenticationPage.title": "¡Bienvenido a Carrefour Links Growth!",
    "chart.noData.content": "para el periodo seleccionado",
    "chart.noData.title": "No hay datos disponibles",
    "commonLabel.administration": "Admin",
    "commonLabel.all": "Todos",
    "commonLabel.applicateFilters": "Aplicar ({{nbFiltersSelected}}) filtros",
    "commonLabel.applySelection": "Aplicar selección",
    "commonLabel.back": "Vuelvo",
    "commonLabel.cancel": "Anular",
    "commonLabel.complete": "Completo",
    "commonLabel.confirm": "Aceptar la solicitud",
    "commonLabel.countryFrance": "Francia",
    "commonLabel.countryItaly": "Italia",
    "commonLabel.countrySpain": "España",
    "commonLabel.dateFormat": "DD/MM/YYYY",
    "commonLabel.dateRange": "Rango de fechas",
    "commonLabel.decline": "Declinar",
    "commonLabel.defaultFilterPerimeterIsReset": "Su perímetro por defecto ha sido restaurado",
    "commonLabel.details": "Detalles",
    "commonLabel.displayFilters": "Mostrar el perimetro y los filtros",
    "commonLabel.error": "Se ha producido un error al cargar los datos",
    "commonLabel.errorData": "Se ha producido un error",
    "commonLabel.errorMessage": "Vuelva a intentarlo más tarde o póngase en contacto con el administrador.",
    "commonLabel.ESP": "España",
    "commonLabel.FRA": "Francia",
    "commonLabel.from": "Del",
    "commonLabel.hideFilters": "Ocultar el perimetro y los filtros",
    "commonLabel.home": "Página de inicio",
    "commonLabel.ITA": "Italia",
    "commonLabel.lang.en": "Inglés",
    "commonLabel.lang.es": "Español",
    "commonLabel.lang.fr": "Francés",
    "commonLabel.lang.it": "Italiano",
    "commonLabel.loadMore": "Mostrar más líneas",
    "commonLabel.modifyFilters": "Modificar filtros ({{nbFiltersSelected}})",
    "commonLabel.month.1": "Enero",
    "commonLabel.month.10": "Octubre",
    "commonLabel.month.11": "Noviembre",
    "commonLabel.month.12": "Diciembre",
    "commonLabel.month.2": "Febrero",
    "commonLabel.month.3": "Marzo",
    "commonLabel.month.4": "Abril",
    "commonLabel.month.5": "Mayo",
    "commonLabel.month.6": "Junio",
    "commonLabel.month.7": "Julio",
    "commonLabel.month.8": "Agosto",
    "commonLabel.month.9": "Septiembre",
    "commonLabel.moreDetails": "Más detalles",
    "commonLabel.noData": "Sin datos",
    "commonLabel.noResult": "Sin resultados",
    "commonLabel.notAvailable": "Este indicador estará disponible en breve.",
    "commonLabel.or": "O",
    "commonLabel.performance": "Rendimiento",
    "commonLabel.projects": "Proyectos",
    "commonLabel.reEnterFilters": "Vuelva a introducir sus filtros",
    "commonLabel.showFiltersAndPerimeter": "Mostrar el perimetro y los filtros",
    "commonLabel.storePerimeter": "Perímetro del parque de tiendas",
    "commonLabel.supplierView": "Accede a la visión del proveedor",
    "commonLabel.taxonomy": "Taxonomía",
    "commonLabel.to": "al",
    "commonLabel.updatePerimeter": "Volver a su perímetro",
    "commonLabel.value": "Valor",
    "dataTable.sort": "Ordenar datos",
    "dataTablePerformance.dimension.average_price": "Precio medio",
    "dataTablePerformance.dimension.average_price_benchmark": "Precio medio Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_comparison": "Precio medio vs N-1",
    "dataTablePerformance.dimension.average_price_with_promo": "Precio medio de promoción",
    "dataTablePerformance.dimension.average_price_with_promo_benchmark": "Precio medio de promoción Carrefour benchmark",
    "dataTablePerformance.dimension.average_price_with_promo_comparison": "Precio medio de promoción vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_amount": "Ventas medias semanales {{taxType}}",
    "dataTablePerformance.dimension.average_weekly_sales_amount_benchmark": "Ventas semanales medias Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_amount_comparison": "Ventas semanales medias vs N-1",
    "dataTablePerformance.dimension.average_weekly_sales_quantity": "Unidades medias semanales",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_benchmark": "Unidades medias semanales Carrefour benchmark",
    "dataTablePerformance.dimension.average_weekly_sales_quantity_comparison": "Unidades medias semanales vs N-1",
    "dataTablePerformance.dimension.avg-price": "Precio medio",
    "dataTablePerformance.dimension.avg-price-p": "Precio medio de promoción",
    "dataTablePerformance.dimension.barcode": "Artículo (EAN)",
    "dataTablePerformance.dimension.brand_desc": "Marca",
    "dataTablePerformance.dimension.brand_type_desc": "Tipo de marca",
    "dataTablePerformance.dimension.ca": "Ventas {{taxType}}",
    "dataTablePerformance.dimension.ca-hp": "Ventas {{taxType}} sin promo",
    "dataTablePerformance.dimension.ca-p": "Ventas {{taxType}} en promo",
    "dataTablePerformance.dimension.class_desc": "Familia",
    "dataTablePerformance.dimension.gross_service_rate_downstream": "Tasa de servicio bruta descendente",
    "dataTablePerformance.dimension.gross_service_rate_downstream_comparison": "Tasa de servicio bruta descendente vs N-1",
    "dataTablePerformance.dimension.gross_service_rate_upstream": "Tasa de servicio bruta ascendente",
    "dataTablePerformance.dimension.gross_service_rate_upstream_comparison": "Tasa de servicio bruta ascendente vs N-1",
    "dataTablePerformance.dimension.item_desc": "Artículo (SMS)",
    "dataTablePerformance.dimension.item_key": "Artículo (SMS)",
    "dataTablePerformance.dimension.main_holding_desc": "Holding",
    "dataTablePerformance.dimension.net_service_rate_downstream": "Tasa de servicio neto descendente",
    "dataTablePerformance.dimension.net_service_rate_downstream_comparison": "Tasa de servicio neto descendente vs N-1",
    "dataTablePerformance.dimension.net_service_rate_upstream": "Tasa de servicio neto ascendente",
    "dataTablePerformance.dimension.net_service_rate_upstream_comparison": "Tasa de servicio neto ascendente vs N-1",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount": "Cuota de mercado Nielsen (en ventas)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_amount_comparison": "Cuota de mercado Nielsen (en ventas) vs N-1 (pp)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity": "Cuota de mercado Nielsen (en unidades)",
    "dataTablePerformance.dimension.nielsen_market_share_sales_quantity_comparison": "Cuota de mercado Nielsen (en unidades) vs N-1",
    "dataTablePerformance.dimension.p-ca-p": "Peso promocional (en ventas)",
    "dataTablePerformance.dimension.p-uvc-p": "Peso promocional (en unidades)",
    "dataTablePerformance.dimension.sales_amount": "Ventas {{taxType}}",
    "dataTablePerformance.dimension.sales_amount_benchmark": "Ventas Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_comparison": "Ventas {{taxType}} vs N-1",
    "dataTablePerformance.dimension.sales_amount_promo_percent": "Peso promocional (en ventas)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_benchmark": "Peso promocional (en ventas) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_amount_promo_percent_comparison": "Peso promocional (en ventas) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_amount_with_promo": "Ventas {{taxType}} en promo",
    "dataTablePerformance.dimension.sales_amount_with_promo_benchmark": "Ventas promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_with_promo_comparison": "Ventas {{taxType}} promo. vs N-1",
    "dataTablePerformance.dimension.sales_amount_without_promo": "Ventas {{taxType}} sin promo",
    "dataTablePerformance.dimension.sales_amount_without_promo_benchmark": "Ventas sin promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_amount_without_promo_comparison": "Ventas {{taxType}} sin promo. vs N-1",
    "dataTablePerformance.dimension.sales_possession_rate": "Tasa de posesión sobre ventas",
    "dataTablePerformance.dimension.sales_possession_rate_comparison": "Tasa de posesión sobre ventas vs N-1",
    "dataTablePerformance.dimension.sales_quantity": "Unidades",
    "dataTablePerformance.dimension.sales_quantity_benchmark": "Unidades Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_comparison": "Unidades vs N-1",
    "dataTablePerformance.dimension.sales_quantity_promo_percent": "Peso promocional (en unidades)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_benchmark": "Peso promocional (en unidades) Carrefour benchmark (pp)",
    "dataTablePerformance.dimension.sales_quantity_promo_percent_comparison": "Peso promocional (en unidades) vs N-1 (pp)",
    "dataTablePerformance.dimension.sales_quantity_with_promo": "Unidades en promo",
    "dataTablePerformance.dimension.sales_quantity_with_promo_benchmark": "Unidades promo Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_with_promo_comparison": "Unidades promo. vs N-1",
    "dataTablePerformance.dimension.sales_quantity_without_promo": "Unidades sin promo",
    "dataTablePerformance.dimension.sales_quantity_without_promo_benchmark": "Unidades sin promo. Carrefour benchmark",
    "dataTablePerformance.dimension.sales_quantity_without_promo_comparison": "Unidades sin promo. vs N-1",
    "dataTablePerformance.dimension.store_chain_type_desc": "Formato",
    "dataTablePerformance.dimension.store_key": "Tienda",
    "dataTablePerformance.dimension.store_region_desc": "Región",
    "dataTablePerformance.dimension.store_stock_quantity": "Stock de tienda",
    "dataTablePerformance.dimension.store_stock_value": "Stock de tienda (sin IVA)",
    "dataTablePerformance.dimension.sub_class_desc": "Subfamilia",
    "dataTablePerformance.dimension.supplier_desc": "Proveedor local",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount": "Cuota de mercado de Carrefour (en ventas)",
    "dataTablePerformance.dimension.supplier_market_share_sales_amount_comparison": "Cuota de mercado de Carrefour (en ventas) vs N-1 (pp)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity": "Cuota de mercado de Carrefour (en unidades)",
    "dataTablePerformance.dimension.supplier_market_share_sales_quantity_comparison": "Cuota de mercado de Carrefour (en unidades) vs N-1 (pp)",
    "dataTablePerformance.dimension.top_flop_ca_barcode": "Top & Flop 10000 de los articulos (en ventas)",
    "dataTablePerformance.dimension.top_flop_ca_item_key": "Top & Flop 10000 de los articulos (en ventas)",
    "dataTablePerformance.dimension.top_flop_uvc_barcode": "Top & Flop 10000 de los articulos (en unidades)",
    "dataTablePerformance.dimension.top_flop_uvc_item_key": "Top & Flop 10000 de los articulos (en unidades)",
    "dataTablePerformance.dimension.total": "total",
    "dataTablePerformance.dimension.uvc": "Unidades",
    "dataTablePerformance.dimension.uvc-hp": "Unidades sin promo",
    "dataTablePerformance.dimension.uvc-p": "Unidades en promo",
    "dataTablePerformance.dimension.warehouse_stock_quantity": "Stock de almacén",
    "dataTablePerformance.dimension.warehouse_stock_value": "Stock de almacén (sin IVA)",
    "dataTablePerformance.excelExport": "Exportar a Excel",
    "dataTablePerformance.subtitle": "Seleccione a continuación al menos 1 dimensión en la que desee ver desglosado el rendimiento del perímetro seleccionado anteriormente.",
    "dataTablePerformance.title": "Desglose del rendimiento",
    "email.acceptText": "Si este usuario es parte de tu entidad, por favor acepta su solicitud directamente en la herramienta a través de",
    "email.accountActivated": "Tu solicitud de acceso a la plataforma ha sido aprobada. Ya puede acceder a ella a través del siguiente enlace y empezar a configurar su cuenta",
    "email.andConfigure": "y empezar a <b>configurar tu cuenta</b>",
    "email.appName": "Carrefour Links Growth",
    "email.catmanTeam": "El equipo de Carrefour Links",
    "email.downloadYourExport": "Aquí está el enlace de descarga para su extracción",
    "email.exportAvailability": "Este enlace está disponible durante 15 días.",
    "email.exportFailed": "Lo sentimos, su archivo no se ha podido cargar debido a un error técnico. <br/> Por favor, ejecute de nuevo la exportación desde la plataforma.",
    "email.exportFileName": "Desglose del rendimiento",
    "email.forAnyQuestionReferTo": "Si tienes alguna duda, puedes <b>contacta directamente</b>",
    "email.hello": "Hola",
    "email.orReferToDoc": "o consultar la documentación de usuario",
    "email.otherwisePlease": "De lo contrario, por favor <b>recházalo</b>",
    "email.rejectText": "Tu solicitud de acceso a Carrefour Links Growth ha sido rechazada",
    "email.requestAccess": "ha solicitado acceso a la plataforma <span style=\\\"color: #da8796; font-weight: bold\\\">Carrefour Links Growth.</span>",
    "email.seeYouSoon": "Nos vemos pronto en la plataforma",
    "email.subject.accessRejected": "Tu solicitud de acceso a Carrefour Links Growth ha sido rechazada",
    "email.subject.accessRequest": "Un usuario quiere acceder a Carrefour Links Growth",
    "email.subject.accessValidated": "¡Bienvenido a la plataforma Carrefour Links Growth!",
    "email.subject.exportError": "Se produjo un error durante tu exportación",
    "email.subject.exportSuccess": "Tu exportación está lista!",
    "email.subject.projectInvitation": "Has sido invitado a unirte a un proyecto en Carrefour Links Growth",
    "email.thisLink": "este enlace",
    "email.welcome": "Bienvenido a la plataforma Carrefour Links Growth",
    "excelExport.confirmation": "Exportación en curso\nRecibirá su archivo por correo electrónico",
    "excelExport.CTA": "Exportar a Excel",
    "excelExport.desc": "Recibirá un correo electrónico con un enlace para descargar su archivo .xlsx.\nDuración generalmente observada (1 a 2 min)",
    "excelExport.dimension.average_price": "Precio medio",
    "excelExport.dimension.average_price_benchmark": "Precio medio Carrefour benchmark",
    "excelExport.dimension.average_price_comparison": "Precio medio vs N-1",
    "excelExport.dimension.average_price_with_promo": "Precio medio de promoción",
    "excelExport.dimension.average_price_with_promo_benchmark": "Precio medio de promoción Carrefour benchmark",
    "excelExport.dimension.average_price_with_promo_comparison": "Precio medio de promoción vs N-1",
    "excelExport.dimension.average_weekly_sales_amount": "Ventas medias semanales {{taxType}}",
    "excelExport.dimension.average_weekly_sales_amount_benchmark": "Ventas semanales medias Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_amount_comparison": "Ventas semanales medias vs N-1",
    "excelExport.dimension.average_weekly_sales_quantity": "Unidades medias semanales",
    "excelExport.dimension.average_weekly_sales_quantity_benchmark": "Unidades medias semanales Carrefour benchmark",
    "excelExport.dimension.average_weekly_sales_quantity_comparison": "Unidades medias semanales vs N-1",
    "excelExport.dimension.avg-price": "Precio medio",
    "excelExport.dimension.avg-price-p": "Precio medio de promoción",
    "excelExport.dimension.barcode": "EAN code",
    "excelExport.dimension.barcode_desc": "EAN desc.",
    "excelExport.dimension.brand_desc": "Marca",
    "excelExport.dimension.brand_type_desc": "Tipo de marca",
    "excelExport.dimension.ca": "Ventas {{taxType}}",
    "excelExport.dimension.ca-hp": "Ventas {{taxType}} sin promo",
    "excelExport.dimension.ca-p": "Ventas {{taxType}} en promo",
    "excelExport.dimension.class_desc": "Familia",
    "excelExport.dimension.gross_service_rate_downstream": "Tasa de servicio bruta descendente",
    "excelExport.dimension.gross_service_rate_downstream_comparison": "Tasa de servicio bruta descendente vs N-1 (pp)",
    "excelExport.dimension.gross_service_rate_upstream": "Tasa de servicio bruta ascendente",
    "excelExport.dimension.gross_service_rate_upstream_comparison": "Tasa de servicio bruta ascendente vs N-1 (pp)",
    "excelExport.dimension.item_desc": "Item desc.",
    "excelExport.dimension.item_key": "Item code",
    "excelExport.dimension.main_holding_desc": "Holding desc.",
    "excelExport.dimension.main_holding_key": "Holding code",
    "excelExport.dimension.net_service_rate_downstream": "Tasa de servicio neto descendente",
    "excelExport.dimension.net_service_rate_downstream_comparison": "Tasa de servicio neto descendente vs N-1 (pp)",
    "excelExport.dimension.net_service_rate_upstream": "Tasa de servicio neto ascendente",
    "excelExport.dimension.net_service_rate_upstream_comparison": "Tasa de servicio neto ascendente vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_amount": "Cuota de mercado Nielsen (en ventas)",
    "excelExport.dimension.nielsen_market_share_sales_amount_comparison": "Cuota de mercado Nielsen (en ventas) vs N-1 (pp)",
    "excelExport.dimension.nielsen_market_share_sales_quantity": "Cuota de mercado Nielsen (en unidades)",
    "excelExport.dimension.nielsen_market_share_sales_quantity_comparison": "Cuota de mercado Nielsen (en unidades) vs N-1",
    "excelExport.dimension.p-ca-p": "Peso promocional (en ventas)",
    "excelExport.dimension.p-uvc-p": "Peso promocional (en unidades)",
    "excelExport.dimension.sales_amount": "Ventas {{taxType}}",
    "excelExport.dimension.sales_amount_benchmark": "Ventas Carrefour benchmark",
    "excelExport.dimension.sales_amount_comparison": "Ventas {{taxType}} vs N-1",
    "excelExport.dimension.sales_amount_promo_percent": "Peso promocional (en ventas)",
    "excelExport.dimension.sales_amount_promo_percent_benchmark": "Peso promocional (en ventas) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_amount_promo_percent_comparison": "Peso promocional (en ventas) vs N-1 (pp)",
    "excelExport.dimension.sales_amount_with_promo": "Ventas {{taxType}} en promo",
    "excelExport.dimension.sales_amount_with_promo_benchmark": "Ventas promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_with_promo_comparison": "Ventas {{taxType}} promo. vs N-1",
    "excelExport.dimension.sales_amount_without_promo": "Ventas {{taxType}} sin promo",
    "excelExport.dimension.sales_amount_without_promo_benchmark": "Ventas sin promo Carrefour benchmark",
    "excelExport.dimension.sales_amount_without_promo_comparison": "Ventas {{taxType}} sin promo. vs N-1",
    "excelExport.dimension.sales_possession_rate": "Tasa de posesión sobre ventas",
    "excelExport.dimension.sales_possession_rate_comparison": "Tasa de posesión sobre ventas vs N-1 (pp)",
    "excelExport.dimension.sales_quantity": "Unidades",
    "excelExport.dimension.sales_quantity_benchmark": "Unidades Carrefour benchmark",
    "excelExport.dimension.sales_quantity_comparison": "Unidades vs N-1",
    "excelExport.dimension.sales_quantity_promo_percent": "Peso promocional (en unidades)",
    "excelExport.dimension.sales_quantity_promo_percent_benchmark": "Peso promocional (en unidades) Carrefour benchmark (pp)",
    "excelExport.dimension.sales_quantity_promo_percent_comparison": "Peso promocional (en unidades) vs N-1 (pp)",
    "excelExport.dimension.sales_quantity_with_promo": "Unidades en promo",
    "excelExport.dimension.sales_quantity_with_promo_benchmark": "Unidades promo Carrefour benchmark",
    "excelExport.dimension.sales_quantity_with_promo_comparison": "Unidades promo. vs N-1",
    "excelExport.dimension.sales_quantity_without_promo": "Unidades sin promo",
    "excelExport.dimension.sales_quantity_without_promo_benchmark": "Unidades sin promo. Carrefour benchmark",
    "excelExport.dimension.sales_quantity_without_promo_comparison": "Unidades sin promo. vs N-1",
    "excelExport.dimension.store_chain_type_desc": "Formato",
    "excelExport.dimension.store_desc": "Tienda desc.",
    "excelExport.dimension.store_key": "Tienda code",
    "excelExport.dimension.store_region_desc": "Región",
    "excelExport.dimension.sub_class_desc": "Subfamilia",
    "excelExport.dimension.supplier_desc": "Proveedor local desc.",
    "excelExport.dimension.supplier_key": "Proveedor local code",
    "excelExport.dimension.supplier_market_share_sales_amount": "Cuota de mercado de Carrefour (en ventas)",
    "excelExport.dimension.supplier_market_share_sales_amount_comparison": "Cuota de mercado de Carrefour (en ventas) vs N-1 (pp)",
    "excelExport.dimension.supplier_market_share_sales_quantity": "Cuota de mercado de Carrefour (en unidades)",
    "excelExport.dimension.supplier_market_share_sales_quantity_comparison": "Cuota de mercado de Carrefour (en unidades) vs N-1 (pp)",
    "excelExport.dimension.top_flop_ca_barcode": "Top & Flop 10000 de los articulos (en ventas)",
    "excelExport.dimension.top_flop_ca_item_key": "Top & Flop 10000 de los articulos (en ventas)",
    "excelExport.dimension.top_flop_uvc_barcode": "Top & Flop 10000 de los articulos (en unidades)",
    "excelExport.dimension.top_flop_uvc_item_key": "Top & Flop 10000 de los articulos (en unidades)",
    "excelExport.dimension.total": "total",
    "excelExport.dimension.uvc": "Unidades",
    "excelExport.dimension.uvc-hp": "Unidades sin promo",
    "excelExport.dimension.uvc-p": "Unidades en promo",
    "excelExport.dimensionDesc": "Seleccione al menos 1 dimensión",
    "excelExport.dimensionTitle": "Dimensiones a exportar",
    "excelExport.error": "Se ha producido un error. Por favor, inténtelo de nuevo.",
    "excelExport.flop": "Flop EAN",
    "excelExport.indicatorDesc": "Seleccione al menos un indicador",
    "excelExport.indicatorTitle": "Indicadores que deben exportarse",
    "excelExport.nameTitle.average_price": "Precio medio",
    "excelExport.nameTitle.average_price_benchmark": "Precio medio Carrefour benchmark",
    "excelExport.nameTitle.average_price_comparison": "Precio medio vs N-1",
    "excelExport.nameTitle.average_price_with_promo": "Precio medio de promoción",
    "excelExport.nameTitle.average_price_with_promo_benchmark": "Precio medio de promoción Carrefour benchmark",
    "excelExport.nameTitle.average_price_with_promo_comparison": "Precio medio de promoción vs N-1",
    "excelExport.nameTitle.average_weekly_sales_amount": "Ventas medias semanales {{taxType}}",
    "excelExport.nameTitle.average_weekly_sales_amount_benchmark": "Ventas semanales medias Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_amount_comparison": "Ventas semanales medias vs N-1",
    "excelExport.nameTitle.average_weekly_sales_quantity": "Unidades medias semanales",
    "excelExport.nameTitle.average_weekly_sales_quantity_benchmark": "Unidades medias semanales Carrefour benchmark",
    "excelExport.nameTitle.average_weekly_sales_quantity_comparison": "Unidades medias semanales vs N-1",
    "excelExport.nameTitle.avg-price": "Precio medio",
    "excelExport.nameTitle.avg-price-p": "Precio medio de promoción",
    "excelExport.nameTitle.barcode": "Artículo (EAN)",
    "excelExport.nameTitle.brand_desc": "Marca",
    "excelExport.nameTitle.brand_type_desc": "Tipo de marca",
    "excelExport.nameTitle.ca": "Ventas {{taxType}}",
    "excelExport.nameTitle.ca-hp": "Ventas {{taxType}} sin promo",
    "excelExport.nameTitle.ca-p": "Ventas {{taxType}} en promo",
    "excelExport.nameTitle.class_desc": "Familia",
    "excelExport.nameTitle.item_desc": "Artículo (SMS)",
    "excelExport.nameTitle.item_key": "Artículo (SMS)",
    "excelExport.nameTitle.main_holding_desc": "Holding",
    "excelExport.nameTitle.nielsen_market_share_sales_amount": "Cuota de mercado Nielsen (en ventas)",
    "excelExport.nameTitle.nielsen_market_share_sales_amount_comparison": "Cuota de mercado Nielsen (en ventas) vs N-1 (pp)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity": "Cuota de mercado Nielsen (en unidades)",
    "excelExport.nameTitle.nielsen_market_share_sales_quantity_comparison": "Cuota de mercado Nielsen (en unidades) vs N-1",
    "excelExport.nameTitle.p-ca-p": "Peso promocional (en ventas)",
    "excelExport.nameTitle.p-uvc-p": "Peso promocional (en unidades)",
    "excelExport.nameTitle.sales_amount": "Ventas {{taxType}}",
    "excelExport.nameTitle.sales_amount_benchmark": "Ventas Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_comparison": "Ventas {{taxType}} vs N-1",
    "excelExport.nameTitle.sales_amount_promo_percent": "Peso promocional (en ventas)",
    "excelExport.nameTitle.sales_amount_promo_percent_benchmark": "Peso promocional (en ventas) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_amount_promo_percent_comparison": "Peso promocional (en ventas) vs N-1 (pp)",
    "excelExport.nameTitle.sales_amount_with_promo": "Ventas {{taxType}} en promo",
    "excelExport.nameTitle.sales_amount_with_promo_benchmark": "Ventas promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_with_promo_comparison": "Ventas {{taxType}} promo. vs N-1",
    "excelExport.nameTitle.sales_amount_without_promo": "Ventas {{taxType}} sin promo",
    "excelExport.nameTitle.sales_amount_without_promo_benchmark": "Ventas sin promo Carrefour benchmark",
    "excelExport.nameTitle.sales_amount_without_promo_comparison": "Ventas {{taxType}} sin promo. vs N-1",
    "excelExport.nameTitle.sales_possession_rate": "Tasa de posesión sobre ventas",
    "excelExport.nameTitle.sales_possession_rate_comparison": "Tasa de posesión sobre ventas vs N-1",
    "excelExport.nameTitle.sales_quantity": "Unidades",
    "excelExport.nameTitle.sales_quantity_benchmark": "Unidades Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_comparison": "Unidades vs N-1",
    "excelExport.nameTitle.sales_quantity_promo_percent": "Peso promocional (en unidades)",
    "excelExport.nameTitle.sales_quantity_promo_percent_benchmark": "Peso promocional (en unidades) Carrefour benchmark (pp)",
    "excelExport.nameTitle.sales_quantity_promo_percent_comparison": "Peso promocional (en unidades) vs N-1 (pp)",
    "excelExport.nameTitle.sales_quantity_with_promo": "Unidades en promo",
    "excelExport.nameTitle.sales_quantity_with_promo_benchmark": "Unidades promo Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_with_promo_comparison": "Unidades promo. vs N-1",
    "excelExport.nameTitle.sales_quantity_without_promo": "Unidades sin promo",
    "excelExport.nameTitle.sales_quantity_without_promo_benchmark": "Unidades sin promo. Carrefour benchmark",
    "excelExport.nameTitle.sales_quantity_without_promo_comparison": "Unidades sin promo. vs N-1",
    "excelExport.nameTitle.store_chain_type_desc": "Formato",
    "excelExport.nameTitle.store_key": "Tienda",
    "excelExport.nameTitle.store_region_desc": "Región",
    "excelExport.nameTitle.sub_class_desc": "Subfamilia",
    "excelExport.nameTitle.supplier_desc": "Proveedor local",
    "excelExport.nameTitle.supplier_market_share_sales_amount": "Cuota de mercado de Carrefour (en ventas)",
    "excelExport.nameTitle.supplier_market_share_sales_amount_comparison": "Cuota de mercado de Carrefour (en ventas) vs N-1 (pp)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity": "Cuota de mercado de Carrefour (en unidades)",
    "excelExport.nameTitle.supplier_market_share_sales_quantity_comparison": "Cuota de mercado de Carrefour (en unidades) vs N-1 (pp)",
    "excelExport.nameTitle.top_flop_ca_barcode": "Top & Flop 10000 de los articulos (en ventas)",
    "excelExport.nameTitle.top_flop_ca_item_key": "Top & Flop 10000 de los articulos (en ventas)",
    "excelExport.nameTitle.top_flop_uvc_barcode": "Top & Flop 10000 de los articulos (en unidades)",
    "excelExport.nameTitle.top_flop_uvc_item_key": "Top & Flop 10000 de los articulos (en unidades)",
    "excelExport.nameTitle.total": "total",
    "excelExport.nameTitle.uvc": "Unidades",
    "excelExport.nameTitle.uvc-hp": "Unidades sin promo",
    "excelExport.nameTitle.uvc-p": "Unidades en promo",
    "excelExport.notificationInProgressMessage": "Recibirá su archivo por correo electrónico.",
    "excelExport.notificationInProgressTitle": "Exportación en curso",
    "excelExport.scopeTitle": "Recordatorio del perímetro seleccionado",
    "excelExport.sendExport": "Recibir la exportación por correo electrónico",
    "excelExport.title": "Desglose del rendimiento",
    "excelExport.top": "Top EAN",
    "exportChecklistData.average_price": "Precio medio",
    "exportChecklistData.average_weekly_sales_amount": "Ventas medias semanales {{taxType}}",
    "exportChecklistData.average_weekly_sales_quantity": "Unidades medias semanales",
    "exportChecklistData.distribution": "Distribución",
    "exportChecklistData.gross_service_rate": "Tasa de servicio bruta",
    "exportChecklistData.net_service_rate": "Tasa de servicio neto",
    "exportChecklistData.nielsen_market_share_sales_amount": "Cuota de mercado Nielsen (en ventas)",
    "exportChecklistData.nielsen_market_share_sales_quantity": "Cuota de mercado Nielsen (en unidades)",
    "exportChecklistData.sales_amount": "Ventas {{taxType}}",
    "exportChecklistData.sales_possession_rate": "Tasa de posesión sobre ventas",
    "exportChecklistData.sales_quantity": "Unidades",
    "exportChecklistData.supplier_market_share_sales_amount": "Cuota de mercado de Carrefour (en ventas)",
    "exportChecklistData.supplier_market_share_sales_quantity": "Cuota de mercado de Carrefour (en unidades)",
    "exportKpi.activity_type": "Canal de compras",
    "exportKpi.barcode": "Artículo (EAN)",
    "exportKpi.brand_key": "Marca",
    "exportKpi.brand_type_key": "Tipo de marca",
    "exportKpi.class_key": "Familia",
    "exportKpi.department_key": "Departamento",
    "exportKpi.dimension": "Dimensión",
    "exportKpi.flopAmount_barcode": "Flop EAN (en ventas)",
    "exportKpi.flopAmount_item_key": "Flop Item (en ventas)",
    "exportKpi.flopQuantity_barcode": "Flop EAN (en unidades)",
    "exportKpi.flopQuantity_item_key": "Flop Item (en unidades)",
    "exportKpi.grp_class_key": "Grupo Familia",
    "exportKpi.HT": "sin IVA",
    "exportKpi.interval": "Intervalo",
    "exportKpi.isWithTax": "Sin IVA o IVA incluido",
    "exportKpi.item_key": "Artículo (SMS)",
    "exportKpi.main_holding_key": "Holding",
    "exportKpi.perimeter": "Perímetro",
    "exportKpi.sector_key": "Sector",
    "exportKpi.store_chain_type_key": "Formato",
    "exportKpi.store_int_flag": "Integrados / Franquicias",
    "exportKpi.store_key": "Tienda",
    "exportKpi.sub_class_key": "Subfamilia",
    "exportKpi.supplier_key": "Proveedor local",
    "exportKpi.topAmount_barcode": "Top EAN (en ventas)",
    "exportKpi.topAmount_item_key": "Top Item (en ventas)",
    "exportKpi.topQuantity_barcode": "Top EAN (en unidades)",
    "exportKpi.topQuantity_item_key": "Top Item (en unidades)",
    "exportKpi.TTC": "IVA incluido",
    "filterPopin.activityType": "Canal de compras",
    "filterPopin.activityTypesData.E-Commerce": "E-comercio",
    "filterPopin.activityTypesData.ECM": "e-comercio",
    "filterPopin.activityTypesData.In store": "En tienda",
    "filterPopin.activityTypesData.STO": "En tienda",
    "filterPopin.brand": "Marca",
    "filterPopin.brandType": "Tipo de marca",
    "filterPopin.chainType": "Formatos",
    "filterPopin.cpgVersion.brand": "Marcas",
    "filterPopin.cpgVersion.localSupplierAndBrand": "Proveedor local / Marca",
    "filterPopin.ean": "EAN",
    "filterPopin.hint.availableOnCarrefour": "Utilice la taxonomía Carrefour",
    "filterPopin.hint.availableOnNielsen": "Utilice la taxonomía Nielsen",
    "filterPopin.holding": "Holding",
    "filterPopin.holdingAndBrand": "Holding / Marca",
    "filterPopin.holdingAndLocalSupplier": "Holding / Proveedor local",
    "filterPopin.holdingAndLocalSupplierAndBrand": "Holding / Proveedor local / Marca",
    "filterPopin.integrateFranchised": "Integrados / Franquicias",
    "filterPopin.integrateFranchisedData.0": "Franquicias",
    "filterPopin.integrateFranchisedData.1": "Integrados",
    "filterPopin.integrateFranchisedData.2": "Desconocido",
    "filterPopin.integrateFranchisedData.2ESP": "Dark stores",
    "filterPopin.item": "Artículo",
    "filterPopin.localSupplier": "Proveedor local",
    "filterPopin.nomenclature.carrefour": "Carrefour",
    "filterPopin.nomenclature.nielsen": "Nielsen",
    "filterPopin.nomenclature.title": "Nomenclatura",
    "filterPopin.productNomenclature": "Nomenclatura de los productos",
    "filterPopin.productPlaceholder": "Introduzca al menos 4 caracteres en la barra de búsqueda",
    "filterPopin.region": "Región",
    "filterPopin.searchBar": "Buscar un artículo, marca, formato...",
    "filterPopin.store": "Tienda",
    "filterPopin.title": "Filtros",
    "impersonateFrame.timeRemaining": "Tiempo restante",
    "likeForLike.infoBanner.lineEcommerce": "El comercio electrónico.",
    "likeForLike.infoBanner.lineOne": "Tiendas que no han estado abiertas por un número comparable de días entre n y n-1.",
    "likeForLike.infoBanner.lineTwo": "Tiendas que cambiaron de tipología entre el año n y n-1 (formato, estado, país...).",
    "likeForLike.infoBanner.title": "En modo de Like for Like, el perímetro de análisis excluye:",
    "likeForLike.warningBanner.content": "Nota: Algunas tiendas que han estado cerradas menos de un mes se incluyen en el ámbito de análisis de manera mensual.",
    "marketShareTableauEnbedding.info": "El contenido a continuación está alojado en Tableau. Por favor, autentíquese y vuelva a ingresar sus filtros.",
    "marketShareTableauEnbedding.subtitle": "Para ir más allá, acceda a continuación a la síntesis de participación de mercado del Cockpit de Performance.",
    "marketShareTableauEnbedding.title": "Síntesis de Cuota de mercado",
    "onboardingConfirmation.confirmationButton": "Validar y descubrir la plataforma",
    "onboardingConfirmation.language": "Idioma",
    "onboardingConfirmation.languageEdit": "Modificar",
    "onboardingConfirmation.perimeter": "Su perímetro por defecto",
    "onboardingConfirmation.perimeterEdit": "Cambiar el perímetro por defecto",
    "onboardingConfirmation.subtitle": "Compruebe su configuración",
    "onboardingConfirmation.title": "Sólo te queda un último paso para descubrir la plataforma.",
    "onboardingLanguage.button": "idioma",
    "onboardingLanguage.confirmationButton": "Confirmar mi elección y pasar a la etapa siguiente",
    "onboardingLanguage.subtitle": "En primer lugar, configura tu idioma por defecto.",
    "onboardingLanguage.title": "¡Bienvenido a Carrefour Links Growth!",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.esp": "El canal e-commerce está excluido del ámbito de Like 4 Like",
    "onboardingPerimeter.activityType.noEcommerce.tooltip.ita": "El canal e-commerce está excluido del ámbito de Like 4 Like",
    "onboardingPerimeter.activityType.subtitle": "Canal de compras",
    "onboardingPerimeter.activityType.title": "¿En qué canal de compras desea seguir el rendimiento por defecto?",
    "onboardingPerimeter.chainType.subtitle": "Formato",
    "onboardingPerimeter.chainType.title": "¿En qué formato de tienda desea seguir el rendimiento por defecto?",
    "onboardingPerimeter.confirmationButton": "Confirmar mi elección y pasar a la etapa siguiente",
    "onboardingPerimeter.granularity.buttonTitle": "Granularidad del análisis",
    "onboardingPerimeter.granularity.monthly": "Mensual",
    "onboardingPerimeter.granularity.title": "¿Con qué nivel de detalle desea supervisar el rendimiento de su perímetro? *",
    "onboardingPerimeter.granularity.weekly": "Semanal",
    "onboardingPerimeter.productHierarchy.button": "Añadir una nomenclatura de los productos",
    "onboardingPerimeter.productHierarchy.class": "Familia",
    "onboardingPerimeter.productHierarchy.department": "Departamento",
    "onboardingPerimeter.productHierarchy.grpclass": "Grupo Familia",
    "onboardingPerimeter.productHierarchy.sector": "Sector",
    "onboardingPerimeter.productHierarchy.subclass": "Subfamilia",
    "onboardingPerimeter.productHierarchy.subtitle": "Nomenclatura de los productos",
    "onboardingPerimeter.productHierarchy.title": "¿Qué perímetropro ducto desea utilizar por defecto?",
    "onboardingPerimeter.salesAmountFormat.buttonTitle": "Valor del KPI",
    "onboardingPerimeter.salesAmountFormat.HT": "sin IVA",
    "onboardingPerimeter.salesAmountFormat.title": "¿Desea utilizar los indicadores sin IVA o con IVA incluido por defecto?",
    "onboardingPerimeter.salesAmountFormat.TTC": "IVA incluido",
    "onboardingPerimeter.storePerimeter.allStores.content": "El perímetro incluye todas las tiendas del parque.",
    "onboardingPerimeter.storePerimeter.allStores.title": "Todo el parque",
    "onboardingPerimeter.storePerimeter.likeForLike.full.content": "El ámbito excluye tiendas con menos de un año de antigüedad, cerradas durante más de un año o que hayan cambiado de tipología durante el período de estudio.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.content": "El ámbito excluye tiendas que no tienen un número comparable de días de apertura entre los períodos n-1 y n. También se excluye el e-commerce.",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.close": "Entendido",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.esp": "En España, el canal de compra de e-commerce no está disponible en Like for Like mode. Para seleccionar la opción de Like for Like, por favor seleccione el canal de compra solo in store",
    "onboardingPerimeter.storePerimeter.likeForLike.inStoreOnly.tooltip.ita": "En Italia, el canal de compra de e-commerce no está disponible en Like for Like mode. Para seleccionar la opción de Like for Like, por favor seleccione el canal de compra solo in store",
    "onboardingPerimeter.storePerimeter.likeForLike.title": "Like for like",
    "onboardingPerimeter.storePerimeter.subtitle": "Perímetro del parque de tiendas",
    "onboardingPerimeter.storePerimeter.title": "¿En qué perímetro de parque de tiendas desea analizar la evolución de los indicadores?",
    "onboardingPerimeter.subtitle": "Cada vez que se conecte, se calcularán y mostrarán los indicadores de supervisión del rendimiento para el perímetro que defina en esta fase. Puedes modificarlo en cualquier momento en la configuración de tu cuenta.",
    "onboardingPerimeter.timeframe.buttonTitle": "Periodo de tiempo",
    "onboardingPerimeter.timeframe.cumulative_to_date": "Acumulación mobil anual",
    "onboardingPerimeter.timeframe.last_period": "Período anterior",
    "onboardingPerimeter.timeframe.title": "¿En qué periodo de tiempo le gustaría ver sus indicadores de rendimiento para cada conexión? *",
    "onboardingPerimeter.timeframe.year_to_date": "Desde el comienzo del año",
    "onboardingPerimeter.title": "A continuación, defina su perímetro por defecto.",
    "performanceCarrefourCatman.salesAmountFormat.HT": "sin IVA",
    "performanceCarrefourCatman.salesAmountFormat.nielsenIsWithTax": "En la taxonomía Nielsen, las ventas se calculan con el IVA incluido.",
    "performanceCarrefourCatman.salesAmountFormat.TTC": "IVA incluido",
    "performanceCarrefourCatman.storePerimeter.allStores": "Todo el parque",
    "performanceCarrefourCatman.storePerimeter.hintEcommerceNotAvailable": "Il canale di acquisto dell'e-commerce non è disponibile nell'analisi like for like",
    "performanceCarrefourCatman.storePerimeter.hintLikeForLikeNotAvailableWithEcommerce": "L'analisi Like for like non è disponibile quando viene selezionato il canale e-commerce.",
    "performanceCarrefourCatman.storePerimeter.likeForLike": "Like for like",
    "performanceCarrefourCatman.storePerimeter.nielsenAllStores": "En la taxonomía Nielsen, el modo Like for Like no está disponible.",
    "performanceCarrefourCatman.subtitleDistribution": "Distribución",
    "performanceCarrefourCatman.subtitlePossessionAndDistribution": "Posesión y distribución",
    "performanceCarrefourCatman.subtitleSales": "Ventas",
    "performanceCarrefourCatman.subtitleServiceRate": "Suministro",
    "performanceCarrefourCatman.subtitleStock": "Stocks",
    "performanceCarrefourCatman.subtitleSupply": "Suministro",
    "performanceCarrefourCatman.title": "Rendimiento de la categoría",
    "performanceCarrefourCatmanCpgView.benchmark": "Ver referencia",
    "performanceCarrefourCatmanCpgView.title": "Rendimiento del proveedor",
    "performanceCarrefourCatmanCpgView.warning": "Está viendo la página como",
    "salesAmount.barChart": "Variación de las ventas {{taxType}} frente a N-1",
    "salesAmount.lineChart": "Desglose de las ventas {{taxType}}",
    "salesAmount.pieChart": "Análisis de ventas {{taxType}}",
    "salesAmount.switchButton": "Mostrar en unidades",
    "salesAmount.title": "Análisis de ventas {{taxType}}",
    "salesQuantity.barChart": "Variación de las cantidades vendidas frente a N-1 (unidades)",
    "salesQuantity.lineChart": "Desglose de cantidades (unidades)",
    "salesQuantity.pieChart": "Análisis de las cantidades vendidas",
    "salesQuantity.switchButton": "Mostrar en valor",
    "salesQuantity.title": "Análisis de las cantidades vendidas",
    "scorecard.grossServiceRateDownstream.tooltip": "Proporción de pedidos entregados a tiempo en la tienda por los almacenes. La tasa de servicio bruto no tiene en cuenta los reprocesos realizados por los equipos operativos. Es una relación de cantidades expresadas en unidades.",
    "scorecard.grossServiceRateUpstream.tooltip": "Proporción de pedidos entregados a tiempo por los proveedores en el almacén. La tasa de servicio bruto no tiene en cuenta los reprocesos realizados por los equipos operativos. Es una relación de cantidades expresadas en unidades.",
    "scorecard.marketshareUnavailable": "Para visualizar la cuota de mercado Nielsen, seleccione el modo Nielsen y una granularidad de semana o periodo Nielsen.",
    "scorecard.numdistUnavailable_barcode": "Para visualizar la distribución numerica, seleccione uno codigo de producto y una granularidad mensual o semanal.\nParte de las tiendas que han vendido la referencia del producto.",
    "scorecard.numdistUnavailable_item_key": "Para visualizar la distribución numerica, seleccione uno codigo de producto y una granularidad mensual o semanal.\nParte de las tiendas que han vendido la referencia del producto.",
    "scorecard.numericalDistribution.tooltip": "Parte de las tiendas que han vendido la referencia del producto.\nDisponible solo al seleccionar uno codigo de producto y una granularidad mensual o semanal.",
    "scorecard.salesPossessionRate.tooltip": "Proporción de referencias de productos vendidos en comparación con el surtido teórico de las tiendas.",
    "scorecard.serviceRateDownstream.tooltip": "Proporción de pedidos entregados a tiempo en la tienda por los almacenes. La tasa de servicio neta tiene en cuenta los ajustes realizados por los equipos operativos y es una relación de cantidades expresadas en valor. La tasa de servicio bruta es una relación de cantidades expresadas en unidades.",
    "scorecard.serviceRateUpstream.tooltip": "Proporción de pedidos entregados a tiempo por los proveedores en el almacén. La tasa de servicio neto tiene en cuenta los ajustes realizados por los equipos operativos y es una relación de cantidades expresadas en valor. La tasa de servicio bruta es una relación de cantidades expresadas en unidades.",
    "scorecard.stockWarehouse.tooltip": "Valor y cantidad de stock en el almacén. El valor del stock siempre se expresa sin IVA. La cantidad de stock se expresa en unidades de ventas.",
    "scorecard.weighdistUnavailable_barcode": "Para visualizar la distribución ponderada, seleccione uno codigo de producto y una granularidad mensual o semanal.\nRelación entre las ventes generadas por la categoría para todas las tiendas que venden el producto y las venteas generadas por la categoría para todas las tiendas.",
    "scorecard.weighdistUnavailable_item_key": "Para visualizar la distribución ponderada, seleccione uno codigo de producto y una granularidad mensual o semanal.\nRelación entre las ventes generadas por la categoría para todas las tiendas que venden el producto y las venteas generadas por la categoría para todas las tiendas.",
    "scorecard.weightedDistribution.tooltip": "Relación entre las ventes generadas por la categoría para todas las tiendas que venden el producto y las venteas generadas por la categoría para todas las tiendas. \nDisponible solo al seleccionar uno codigo de producto y una granularidad mensual o semanal.",
    "scorecardPerformance.averagePrice": "Precio medio {{taxType}})",
    "scorecardPerformance.averagePricePromo": "Precio medio en promo {{taxType}}",
    "scorecardPerformance.averagePriceWithoutTax": "Precio medio sin IVA",
    "scorecardPerformance.averagePriceWithoutTaxPromo": "Precio medio en promo sin IVA",
    "scorecardPerformance.averagePriceWithTax": "Precio medio IVA incluido",
    "scorecardPerformance.averagePriceWithTaxPromo": "Precio medio en promo IVA incluido",
    "scorecardPerformance.averagePromoPrice": "Precio medio en promo {{taxType}})",
    "scorecardPerformance.averageWeeklySales": "Ventas medias semanales {{taxType}}",
    "scorecardPerformance.averageWeeklySalesAmountWithoutTax": "Ventas medias semanales sin IVA",
    "scorecardPerformance.averageWeeklySalesAmountWithTax": "Ventas medias semanales IVA incluido",
    "scorecardPerformance.averageWeeklySalesInUnits": "Unidades medias semanales",
    "scorecardPerformance.averageWeeklySalesQuantity": "Unidades medias semanales",
    "scorecardPerformance.carrefourMarketShareValue": "Cuota de mercado Carrefour (en ventas)",
    "scorecardPerformance.carrefourMarketShareVolume": "Cuota de mercado Carrefour (en unidades)",
    "scorecardPerformance.grossServiceRateDownstream": "Tasa de servicio bruta descendente",
    "scorecardPerformance.grossServiceRateUpstream": "Tasa de servicio bruta ascendente",
    "scorecardPerformance.netServiceRateDownstream": "Tasa de servicio neto descendente",
    "scorecardPerformance.netServiceRateUpstream": "Tasa de servicio neto ascendente",
    "scorecardPerformance.nielsenMarketShareValue": "Cuota de mercado Nielsen (en ventas)",
    "scorecardPerformance.nielsenMarketShareVolume": "Cuota de mercado Nielsen (en unidades)",
    "scorecardPerformance.nonavailable": "Este indicador estará disponible en breve",
    "scorecardPerformance.nonavailableperimeter": "No disponible en este perímetro",
    "scorecardPerformance.nonPromo": "Sin promo",
    "scorecardPerformance.notAvailableForCarrefourTaxonomy": "Disponible solo en la taxonomía Nielsen",
    "scorecardPerformance.notAvailableForCurrentPerimeter": "No disponible en este perímetro",
    "scorecardPerformance.notAvailableForNielsenTaxonomy": "Disponible solo en la taxonomía Carrefour",
    "scorecardPerformance.numericalDistribution": "Distribución numerica",
    "scorecardPerformance.pressure": "Peso",
    "scorecardPerformance.promo": "En promo",
    "scorecardPerformance.promoPressure": "Peso en promo",
    "scorecardPerformance.quantity": "Unidades",
    "scorecardPerformance.salesPossessionRate": "Tasa de posesión sobre ventas",
    "scorecardPerformance.salesValue": "Ventas {{taxType}}",
    "scorecardPerformance.salesValueWithoutTax": "Ventas sin IVA",
    "scorecardPerformance.salesValueWithTax": "Ventas IVA incluido",
    "scorecardPerformance.serviceRate": "Tasa de servicio",
    "scorecardPerformance.serviceRate.gross": "Bruta",
    "scorecardPerformance.serviceRate.net": "Neto",
    "scorecardPerformance.serviceRateDownstream": "Tasa de servicio descendente",
    "scorecardPerformance.serviceRateUpstream": "Tasa de servicio ascendente",
    "scorecardPerformance.stockStore": "Stock de tienda",
    "scorecardPerformance.stockStore.tooltip": "Valor y cantidad de stock en la tienda. El valor del stock siempre se expresa sin IVA. La cantidad de stock se expresa en unidades de ventas.",
    "scorecardPerformance.stockStoreQuantity": "Stock de tienda",
    "scorecardPerformance.stockStoreWithoutTax": "Stock de tienda (sin IVA)",
    "scorecardPerformance.stockTotal": "Stock total",
    "scorecardPerformance.stockTotal.notAvailableWithPermissions": "No disponible para su nivel de permisos",
    "scorecardPerformance.stockTotal.tooltip": "Suma del stock de la tienda y del almacén. El valor del stock siempre se expresa sin IVA. La cantidad de stock se expresa en unidades de ventas.",
    "scorecardPerformance.stockTotalQuantity": "Stock de almacén",
    "scorecardPerformance.stockTotalWithoutTax": "Stock total (sin IVA)",
    "scorecardPerformance.stockWarehouse": "Stock de almacén",
    "scorecardPerformance.stockWarehouseQuantity": "Stock de almacén",
    "scorecardPerformance.stockWarehouseWithoutTax": "Stock de almacén (sin IVA)",
    "scorecardPerformance.weekAbreviation": "S",
    "scorecardPerformance.weightedDistribution": "Distribución ponderada",
    "sideMenu.accountManagement": "Gestión de la cuenta",
    "sideMenu.administration": "Administración",
    "sideMenu.performance": "Rendimiento de la categoría",
    "sideMenu.performanceReview": "Rendimiento de la categoría",
    "sideMenu.projects": "Proyectos",
    "sideMenu.settings": "Acceder a los parámetros",
    "sideMenu.shortcut": "Mostrar menú lateral",
    "sideMenu.tools": "Herramientas",
    "sideMenu.userSupport": "Acceder a la asistencia al usuario",
    "supplierPopin.description": "Seleccionar un proveedor para tener acceso a su punto de vista sobre el rendimiento de su alcance",
    "supplierPopin.recentSuppliers": "Se busca proveedor desde hace poco",
    "supplierPopin.searchBar": "Buscar un proveedor",
    "supplierPopin.title": "Acceder a la vista de proveedores",
    "support.contactSupport": "Contactar con el servicio de asistencia",
    "support.formSubmitted.goBack": "Volver a mi navegación",
    "support.formSubmitted.text": "La sua richiesta è stata ricevuta e il nostro team di supporto Le risponderà via email il prima possibile. Grazie per la sua pazienza.",
    "support.formSubmitted.title": "¡Gracias por contactarnos!",
    "support.readOurDocumentation": "Consulte nuestra documentación",
    "timeSelector.vsLastYear": "vs. año anterior",
    "timeSelectorPopin.apply": "Aplicar",
    "timeSelectorPopin.cumulative_to_date": "Acumulación mobil anual",
    "timeSelectorPopin.customInterval": "Intervalo personalizado",
    "timeSelectorPopin.description": "Seleccione la granularidad del análisis y un intervalo de tiempo.",
    "timeSelectorPopin.descriptionEssentialPackage": "Seleccione el intervalo de tiempo para el análisis.",
    "timeSelectorPopin.granularity.daily": "Diario",
    "timeSelectorPopin.granularity.monthly": "Mensual",
    "timeSelectorPopin.granularity.nielsen": "Período Nielsen",
    "timeSelectorPopin.granularity.weekly": "Semanal",
    "timeSelectorPopin.last_period": "Período anterior",
    "timeSelectorPopin.subtitleGranularity": "Granularidad del análisis",
    "timeSelectorPopin.subtitleInterval": "Intervalo de tiempo",
    "timeSelectorPopin.title": "Intervalo de tiempo de análisis",
    "timeSelectorPopin.year_to_date": "Desde el comienzo del año",
    "tools.accessButton": "Abrir",
    "tools.createButton": "Añadir una herramienta",
    "tools.empty.addButton": "Añadir una herramienta",
    "tools.empty.text.all.carrefour": "Aún no tiene herramientas en esta página. {br} Por favor, contacte a su administrador {br} o comience a agregarlas usted mismo.",
    "tools.empty.text.all.supplier": "Aún no tiene herramientas en esta página. {br} Comience agregándolas usted mismo.",
    "tools.empty.text.custom": "Aún no tienes ninguna herramienta en esta página. {br} Comienza por añadir una.",
    "tools.empty.text.favorites": "Aún no tiene herramientas en esta página. {br} Comience agregando algunas a sus favoritos.",
    "tools.empty.text.recommended": "Aún no tiene herramientas en esta página. {br} Por favor, contacte a su administrador.",
    "tools.empty.title": "¡Bienvenido!",
    "tools.featured.title": "Destacado",
    "tools.popup.add": "Añadir nuevo accesso directo",
    "tools.popup.cancel": "Cancelar",
    "tools.popup.create.error.message": "No se ha podido crear el acceso directo",
    "tools.popup.create.error.title": "Se ha producido un error",
    "tools.popup.create.success.message": "El acceso directo se ha creado con éxito",
    "tools.popup.create.success.title": "Acceso directo creado",
    "tools.popup.createTitle": "Nuevo acceso directo",
    "tools.popup.delete.error.message": "El acceso directo no se ha podido eliminar",
    "tools.popup.delete.error.title": "Se ha producido un error",
    "tools.popup.delete.success.message": "El acceso directo se ha eliminado con éxito",
    "tools.popup.delete.success.title": "Acceso directo eliminado",
    "tools.popup.deleteButton": "Eliminar",
    "tools.popup.deleteDescription": "¿De verdad quieres borrar el acceso directo?",
    "tools.popup.deleteTitle": "Eliminar acceso directo",
    "tools.popup.description": "Descripción",
    "tools.popup.descriptionPlaceholder": "Descripción de la herramienta",
    "tools.popup.form.invalidUrl": "La URL indicada no es válida",
    "tools.popup.form.notEmpty": "Este campo es obligatorio",
    "tools.popup.form.tooLong": "La descripción no debe exceder los 300 caracteres",
    "tools.popup.keepButton": "Mantenga",
    "tools.popup.label": "Nombre de acceso directo",
    "tools.popup.link": "Enlace",
    "tools.popup.shortcut": "Acceso directo",
    "tools.popup.update.error.message": "No se ha podido actualizar el acceso directo",
    "tools.popup.update.error.title": "Se ha producido un error",
    "tools.popup.update.success.message": "El acceso directo se ha actualizado con éxito",
    "tools.popup.update.success.title": "Acceso directo actualizado",
    "tools.popup.updateTitle": "Modificar el acceso directo",
    "tools.title": "Herramientas y servicios",
    "toolsFrA3distrib.description": "Consultar los catálogos de la competencia",
    "toolsFrA3distrib.title": "A3 Distrib",
    "toolsFrAgathe.description": "Configurar mi gama de productos",
    "toolsFrAgathe.title": "Agathe",
    "toolsFrApoline.description": "Configurar mis operaciones promocionales",
    "toolsFrApoline.title": "Apoline",
    "toolsFrAssortmentBuilder.description": "Definir mi gama de productos",
    "toolsFrAssortmentBuilder.title": "Assortment Builder",
    "toolsFrBasePhoto.description": "Ver las imágenes de mis productos",
    "toolsFrBasePhoto.title": "Base Photo",
    "toolsFrBcpNoyau.description": "Consultar los atributos de mis productos",
    "toolsFrBcpNoyau.title": "BCP Noyau",
    "toolsFrDataInsights.description": "Extraer informes de resultados",
    "toolsFrDataInsights.title": "Data Insigths",
    "toolsFrMemory.description": "Acceder a los módulos de gestión de categorías",
    "toolsFrMemory.title": "Memory",
    "toolsFrNielsen.description": "Ver las cuotas de mercado semanales",
    "toolsFrNielsen.title": "Nielsen PDM Hebdo PGC",
    "toolsFrPilotagePerfAlimentaire.description": "Consultar las tasas de servicio",
    "toolsFrPilotagePerfAlimentaire.title": "Pilotage de la Performance Alimentaire",
    "toolsItAnalytics.description": "Acceso a los dashboards",
    "toolsItAnalytics.title": "Analytics Italia website",
    "toolsItMicrostrategy.description": "Extraer informes de resultados",
    "toolsItMicrostrategy.title": "Report Builder/Microstrategy",
    "userSettings.confirmation": "Confirmar mi elección",
    "userSettings.globalInformation.email": "Email",
    "userSettings.globalInformation.name": "Nombre y apellidos",
    "userSettings.globalInformation.role": "Función",
    "userSettings.globalInformation.subtitle": "Gestione su información y sus datos de acceso",
    "userSettings.globalInformation.title": "Información de la cuenta",
    "userSettings.logout.button": "Desconexión",
    "userSettings.logout.subtitle": "Desconectarse de la plataforma",
    "userSettings.logout.title": "Desconexión",
    "userSettings.perimeter.subtitle": "Gestione su perímetro por defecto",
    "userSettings.perimeter.title": "Perímetro por defecto",
    "userSettings.settings": "Parámetros",
    "userSettings.title": "Configuración de la cuenta",
    "userTools.data.a3-distrib.description": "Consultar catálogos de competidores",
    "userTools.data.a3-distrib.name": "A3 Distrib",
    "userTools.data.agathe.description": "Configurar mi surtido",
    "userTools.data.agathe.name": "Agathe",
    "userTools.data.analytics-italia-website.description": "Acceder a paneles de control",
    "userTools.data.analytics-italia-website.name": "Sitio web de Analytics Italia",
    "userTools.data.apoline.description": "Configurar mis operaciones promocionales",
    "userTools.data.apoline.name": "Apoline",
    "userTools.data.assortment-builder.description": "Definir mi surtido",
    "userTools.data.assortment-builder.name": "Assortment Builder",
    "userTools.data.base-photo.description": "Consultar imágenes de productos",
    "userTools.data.base-photo.name": "Base Photo",
    "userTools.data.bcp-noyau.description": "Consultar atributos de productos",
    "userTools.data.bcp-noyau.name": "BCP Noyau",
    "userTools.data.cockpit-de-performance.description": "Punto di ingresso unico per il monitoraggio della quota di mercato",
    "userTools.data.cockpit-de-performance.name": "Cockpit de rendimiento",
    "userTools.data.data-insights.description": "Extraer informes de rendimiento",
    "userTools.data.data-insights.name": "Data Insights",
    "userTools.data.memory.description": "Acceder a módulos de gestión de categorías",
    "userTools.data.memory.name": "Memory",
    "userTools.data.microstrategy-web.name": "Microstrategy WEB",
    "userTools.data.nielsen-pdm-hebdo-pgc.description": "Consultar participación de mercado semanal",
    "userTools.data.nielsen-pdm-hebdo-pgc.name": "Nielsen PDM Semanal PGC",
    "userTools.data.pilotage-de-la-performance-alimentaire.description": "Consultar tasas de servicio",
    "userTools.data.pilotage-de-la-performance-alimentaire.name": "Gestión del Rendimiento Alimentario",
    "userTools.data.rcm-circuitos.name": "RCM Circuitos",
    "userTools.data.rcm-referencial.name": "RCM referencial",
    "userTools.data.report-builder/microstrategy.description": "Extraer informes de rendimiento",
    "userTools.data.report-builder/microstrategy.name": "Generador de Informes/Microstrategy",
    "userTools.data.reporting-digital-mercancias.name": "Reporting digital Mercancías",
    "userTools.filters.all": "Todos",
    "userTools.filters.favorites": "Mis favoritos",
    "userTools.link": "Acceder",
    "userTools.tags.custom": "Añadido por mí",
    "userTools.tags.recommended": "Recomendado",
    "userTools.title": "Herramientas y servicios",
    "viewAsSupplier.action": "Acción",
    "viewAsSupplier.choose": "Seleccione",
    "viewAsSupplier.noSupplierWithPlatformAccess": "Ningún proveedor se ha suscrito aún a la plataforma.",
    "viewAsSupplier.searchSupplier": "Buscar un proveedor",
    "viewAsSupplier.selectSupplier": "Seleccione un proveedor de los que tienen acceso a la plataforma",
    "viewAsSupplier.supplier": "Proveedor"
  }
} as const;
