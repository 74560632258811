import VueGtm from '@gtm-support/vue-gtm';
import { VueQueryPlugin } from '@tanstack/vue-query';
import i18nextVue from 'i18next-vue';
import { createApp } from 'vue';
import CountryFlag from 'vue-country-flag-next';
import VueFeather from 'vue-feather';

import App from './App.vue';
import { config } from './config/configuration';
import { initAuthClient } from './domain/core/auth/auth.client';
import { configureDayjs } from './helpers/date.helpers';
import { configureSentry } from './monitoring/sentry';
import router from './router';
import { piniaStore } from './stores/createPinia';

import i18next from '@/i18n';
import './styles/main.css';

configureDayjs();
initAuthClient();
const app = createApp(App);

app.use(VueGtm, {
  id: config.gtm.id,
  enabled: config.gtm.enabled,
  debug: config.devMode,
  // Managing envs
  queryParams: {
    gtm_auth: config.gtm.auth,
    gtm_preview: config.gtm.preview,
    gtm_cookies_win: 'x',
  },
  // We don't use vue-router integration to avoid tracking query changes
});

app.use(piniaStore);
app.use(router);
app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: import.meta.env.PROD,
      },
    },
  },
});

app.component(VueFeather.name ?? 'VueFeather', VueFeather);
app.component('CountryFlag', CountryFlag);
app.use(i18nextVue, { i18next });
configureSentry(app, router);
app.mount('#app');
