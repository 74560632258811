<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue';
import type { PropType } from 'vue';
import VueFeather from 'vue-feather';

import type { IconVariantType, VariantType } from '@/components/elements/input';
import { SpinnerLoader } from '@/components/elements/spinner';
import type { IconName } from '@/components/icons/icon.model';

export type ContainerOptionType = {
  bottomNotRounded: boolean;
};

const props = defineProps({
  variant: { type: String as PropType<VariantType>, default: 'default' },
  label: { type: String as PropType<string | null>, required: false, default: '' },
  message: { type: String, required: false, default: undefined },
  required: { type: Boolean, default: false },
  icon: { type: String as PropType<IconName>, required: false, default: undefined },
  iconVariant: { type: String as PropType<IconVariantType>, default: 'default' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  inputRef: {
    type: Object as PropType<HTMLElement>,
    required: false,
    default: undefined,
  },
});

// Check if some content is passed to the 'icon' slot
const slots = useSlots();
let hasIconSlot = false;
if (slots.icon && slots.icon()[0].children?.length) {
  hasIconSlot = true;
}
const isFocused = ref(false);

let containerOption: ContainerOptionType = {
  bottomNotRounded: false,
};

const isDisabled = computed(() => props.disabled || props.loading);

const containerClx = computed(() => ({
  'border-default-very-light': isDisabled,
  'border-default-neutral-217': !isFocused.value && !isDisabled.value,
  'border-primary': isFocused.value && !isDisabled.value && props.variant === 'default',
  'border-error': props.variant === 'error' && !isDisabled.value,
  'border-success': props.variant === 'success' && !isDisabled.value,
  rounded: !containerOption.bottomNotRounded,
  'rounded-t': containerOption.bottomNotRounded,
}));

const label = computed(() => props.label + (props.required ? '*' : ''));
const labelClx = computed(() => ({
  'text-default-disabled': isDisabled.value,
  'text-default-lighter': !isFocused.value && !isDisabled.value,
  'text-primary': isFocused.value && !isDisabled.value && props.variant === 'default',
  'text-error': props.variant === 'error' && !isDisabled.value,
  'text-success': props.variant === 'success' && !isDisabled.value,
}));

const iconClx = computed(() => ({
  'text-default-neutral-217': isDisabled.value && props.iconVariant === 'default',
  'text-primary-disabled': isDisabled.value && props.iconVariant === 'primary',
  'text-default': props.iconVariant === 'default' && !isDisabled.value,
  'text-primary': props.iconVariant === 'primary' && !isDisabled.value,
}));

const messageClx = computed(() => ({
  'text-error': props.variant === 'error',
  'text-success': props.variant === 'success',
}));

const focus = (option?: ContainerOptionType) => {
  isFocused.value = true;
  if (option) {
    containerOption = { ...option };
  }
};
const blur = (option?: ContainerOptionType) => {
  isFocused.value = false;
  if (option) {
    containerOption = { ...option };
  }
};
const setFocus = () => {
  props.inputRef?.focus();
};
</script>

<template>
  <div
    v-bind="$attrs"
    :class="containerClx"
    class="relative border flex cursor-text"
    @click="setFocus"
  >
    <label
      v-if="label"
      :class="labelClx"
      class="absolute h-4 text-xs -mt-2 ml-4 px-1 bg-white items-center"
      >{{ label }}</label
    >
    <div
      v-if="loading"
      class="absolute flex items-center justify-center w-full h-full z-20"
      @click.prevent=""
    >
      <SpinnerLoader size="large" />
    </div>

    <div v-if="icon || hasIconSlot" :class="iconClx" class="ml-6 flex items-center">
      <VueFeather v-if="icon" size="22" :type="icon" />
      <div v-else class="w-5 h-5">
        <slot name="icon" />
      </div>
    </div>
    <slot ref="input" :focus="focus" :blur="blur" />
  </div>
  <p v-if="message" :class="messageClx" class="text-xs mt-1 mb-2">{{ message }}</p>
</template>
