import { z } from 'zod';

const roleEntitySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
});

type RoleEntityType = z.infer<typeof roleEntitySchema>;

const roleEntityToCreateSchema = roleEntitySchema.omit({ id: true });

type RoleEntityToCreateType = z.infer<typeof roleEntityToCreateSchema>;

export {
  roleEntitySchema,
  type RoleEntityType,
  roleEntityToCreateSchema,
  type RoleEntityToCreateType,
};
