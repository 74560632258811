<script lang="ts" setup>
import DatePicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { computed } from 'vue';
import VueFeather from 'vue-feather';

import { InputBase } from '@/components/elements/input';
import { defaultPropsDate } from '@/components/elements/input/inputDate/inputDate.props';

import '@vuepic/vue-datepicker/dist/main.css';

const lang = i18next.resolvedLanguage;

const props = defineProps(defaultPropsDate);
const model = defineModel<VueDatePickerProps['modelValue']>({ required: true });

const getStartFrom = () => {
  if (props.startDate) {
    return dayjs()
      .year(props.startDate?.getFullYear())
      .month(props.startDate?.getMonth())
      .date(props.startDate?.getDate())
      .toDate();
  }

  return dayjs().toDate();
};

const getClxIcon = (data: string) => ({
  'text-default-neutral-217': props.disabled && data === 'default',
  'text-primary-disabled': props.disabled && data === 'primary',
  'text-default': data === 'default' && !props.disabled,
  'text-primary': data === 'primary' && !props.disabled,
});

const iconLeftClx = computed(() => getClxIcon(props.iconLeftVariant));

const iconRightClx = computed(() => getClxIcon(props.iconRightVariant));

const valueButtonClx = computed(() => ({
  'text-default-disabled': props.disabled,
}));

const dateMinValue = computed(() => props.dateMin);
const dateMaxValue = computed(() => props.dateMax);
</script>

<template>
  <div class="grow" :data-testid="testId">
    <InputBase v-bind="{ name, variant, label, placeholder, message, required, disabled }">
      <template #icon>
        <slot name="icon" />
      </template>
      <template #default>
        <DatePicker
          v-bind="{
            monthPicker,
            range,
            multiCalendars,
            weekPicker,
            weekNumbers,
          }"
          v-model="model"
          auto-apply
          :clearable="canReset"
          :disabled="disabled"
          :start-date="getStartFrom()"
          :min-date="dateMinValue"
          :max-date="dateMaxValue"
          :locale="lang"
          :enable-time-picker="false"
          :teleport="true"
        >
          <template #trigger>
            <div class="flex w-full">
              <div class="flex w-full">
                <button type="button" class="flex w-full focus:ring-offset-white">
                  <div class="relative h-14 flex w-full focus:ring-offset-white">
                    <div v-if="iconLeft" class="flex items-center inset-y-0 left-0 pl-6">
                      <VueFeather :type="iconLeft" class="h-5 w-5" :class="iconLeftClx" />
                    </div>
                    <div
                      class="w-full outline-none px-6 p-0 border-none flex items-center focus:ring-offset-white"
                      :class="valueButtonClx"
                    >
                      <slot name="content" />
                    </div>
                    <div v-if="iconRight" class="flex items-center inset-y-0 right-0 pr-6">
                      <VueFeather :type="iconRight" class="h-5 w-5" :class="iconRightClx" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </template>
        </DatePicker>
      </template>
    </InputBase>
  </div>
</template>

<style>
.dp__week_num {
  color: blue;
  font-weight: bold;
}
</style>
