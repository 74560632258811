import type { RouteRecordRaw } from 'vue-router';

import { projectsRoutes } from './routes';

export const projectsRouter: RouteRecordRaw[] = [
  {
    path: projectsRoutes.projects,
    name: 'projects',
    component: () => import('@/views/Projects/views/ProjectView.vue'),
    meta: {
      featureName: 'projects',
      sidebar: 'menu',
      sidebarIcon: 'clipboard',
      breadcrumbs: [
        {
          title: 'commonLabel.projects',
          to: { name: 'projects' },
        },
      ],
    },
  },
  {
    path: projectsRoutes.projectDetails,
    name: 'project-details',
    component: () => import('@/views/Projects/views/ProjectDetailsView.vue'),
    meta: {
      breadcrumbs: [
        {
          title: 'commonLabel.projects',
          to: { name: 'projects list' },
        },
      ],
    },
  },
];
