import { createPinia } from 'pinia';
import { markRaw } from 'vue';
import type { Router } from 'vue-router';

import router from '@/router';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}
export const piniaStore = createPinia();
piniaStore.use(({ store }) => {
  store.router = markRaw(router);
});
