<script lang="ts" setup>
import { computed, type PropType } from 'vue';

import { ICON_VARIANTS, InputDateRange } from '@/components/elements/input';
import { type DateIntervalType as InputDateRangeDateIntervalType } from '@/components/elements/input/inputDate/inputDate.props';
import type {
  DateIntervalType,
  SelectedIntervalType,
} from '@/domain/core/filter/components/periodSelector/periodSelector.model';

const props = defineProps({
  availableInterval: { type: Object as PropType<DateIntervalType>, required: true },
  selectedInterval: { type: Object as PropType<SelectedIntervalType>, required: true },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits<{ (e: 'updateInterval', interval: SelectedIntervalType): void }>();

const selectedIntervalData = computed(
  () => [props.selectedInterval.from, props.selectedInterval.to] as InputDateRangeDateIntervalType,
);

function onUpdateInterval(values: Date[]) {
  emit('updateInterval', {
    from: values?.[0],
    to: values?.[1],
  });
}
</script>

<template>
  <div class="flex flex-row gap-x-4 justify-between items-center">
    <InputDateRange
      :model-value="selectedIntervalData"
      :name="'time-selector-calendar'"
      :placeholder="'dd/mm/yyyy'"
      :date-min="availableInterval.start"
      :date-max="availableInterval.end"
      :start-date="availableInterval?.end"
      :icon-right="'calendar'"
      :icon-right-variant="ICON_VARIANTS[1]"
      :disabled="disabled"
      test-id="time-selector-calendar"
      @update:model-value="onUpdateInterval($event)"
    />
  </div>
</template>
