export const PERIOD_GRANULARITIES = ['monthly', 'weekly', 'daily', 'nielsen'] as const;
export type PeriodGranularity = (typeof PERIOD_GRANULARITIES)[number];

export const KPI_GRANULARITIES = [
  'likeForLike',
  'activityType',
  'chainType',
  'region',
  'stores',
  'storeType',
  'productHierarchy',
  'brandType',
  'region',
  'ean',
  'sales',
  'quantity',
  'promotion',
  'averageWeeklySales',
  'averagePrice',
  'numericalDistribution',
  'weightedDistribution',
  'nielsenMarketShare',
  'carrefourMarketShare',
  'benchmarkTrend',
  'localSupplier',
  'holding',
  'brand',
  'grossServiceRate',
  'netServiceRate',
  'storeStock',
  'warehouseStock',
  'totalStock',
  'salesPossessionRate',
] as const;
export type KpiGranularity = (typeof KPI_GRANULARITIES)[number];

export const FEATURE_PERMISSIONS = ['projects', 'authV2'] as const;
export type FeaturePermission = (typeof FEATURE_PERMISSIONS)[number];

export type Granularity = KpiGranularity | PeriodGranularity | FeaturePermission;

// TypeGuards
export function isAValidKpiGranularity(kpiGranularity: string): kpiGranularity is KpiGranularity {
  return KPI_GRANULARITIES.includes(kpiGranularity as unknown as KpiGranularity);
}

export function isAValidKpiGranularitiesArray(
  kpiGranularities: string[],
): kpiGranularities is KpiGranularity[] {
  return kpiGranularities.every(isAValidKpiGranularity);
}

export function isAValidPeriodGranularity(
  periodGranularity: string,
): periodGranularity is PeriodGranularity {
  return PERIOD_GRANULARITIES.includes(periodGranularity as unknown as PeriodGranularity);
}

export function isAValidPeriodGranularitiesArray(
  periodGranularities: string[],
): periodGranularities is PeriodGranularity[] {
  return periodGranularities.every(isAValidPeriodGranularity);
}

export function isAValidFeaturePermission(
  featurePermission: string,
): featurePermission is FeaturePermission {
  return FEATURE_PERMISSIONS.includes(featurePermission as FeaturePermission);
}
