import type { CountryType } from './country.model';
import { locales } from './locales';

export const AVAILABLE_LANGUAGES = ['en', 'fr', 'it', 'es'] as const;
export type AvailableLanguagesType = (typeof AVAILABLE_LANGUAGES)[number];

export const COUNTRY_TO_LNG: Record<CountryType, AvailableLanguagesType> = {
  FRA: 'fr',
  ITA: 'it',
  ESP: 'es',
} as const;

export type TranslationKey = keyof (typeof locales)['en'];
