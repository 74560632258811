<script lang="ts" setup>
import dayjs from 'dayjs';
import { useTranslation } from 'i18next-vue';
import type { PropType } from 'vue';
import { computed, ref, watch } from 'vue';

import { ICON_VARIANTS, InputDateWeek } from '@/components/elements/input';
import type { DateIntervalType as InputDateRangeDateIntervalType } from '@/components/elements/input/inputDate/inputDate.props';
import type {
  DateIntervalType,
  SelectedIntervalType,
} from '@/domain/core/filter/components/periodSelector/periodSelector.model';

const { t } = useTranslation();

const props = defineProps({
  availableInterval: { type: Object as PropType<DateIntervalType>, required: true },
  selectedInterval: { type: Object as PropType<SelectedIntervalType>, required: true },
  disabled: { type: Boolean, default: false },
});
const emit = defineEmits<{ (e: 'updateInterval', interval: SelectedIntervalType): void }>();

const startDate = computed(() => dayjs(props.selectedInterval.from).add(6, 'day').toDate());
const startEnd = computed(() => dayjs(props.selectedInterval.to).subtract(6, 'day').toDate());

const selectedIntervalDataStart = ref([
  props.selectedInterval.from,
  startDate.value,
] as InputDateRangeDateIntervalType);
const selectedIntervalDataEnd = ref([
  startEnd.value,
  props.selectedInterval.to,
] as InputDateRangeDateIntervalType);

watch(
  selectedIntervalDataStart,
  (value) => {
    emit('updateInterval', {
      from: value[0],
      to: selectedIntervalDataEnd.value[1],
    });
  },
  { deep: true },
);

watch(
  selectedIntervalDataEnd,
  (value) =>
    emit('updateInterval', {
      from: selectedIntervalDataStart.value[0],
      to: value?.[1],
    }),
  { deep: true },
);

const getAvailableDateMin = computed(() => {
  if (
    props.availableInterval.start &&
    selectedIntervalDataStart.value[1] > props.availableInterval.start
  ) {
    return selectedIntervalDataStart.value[1];
  }

  return props.availableInterval.start;
});

const getAvailableDateMax = computed(() => {
  if (
    props.availableInterval.end &&
    selectedIntervalDataEnd.value[0] < props.availableInterval.end
  ) {
    return selectedIntervalDataEnd.value[0];
  }

  return props.availableInterval.end;
});

const getVariant = computed(() => {
  return selectedIntervalDataStart.value?.length > 1 &&
    selectedIntervalDataEnd.value?.length > 1 &&
    selectedIntervalDataStart.value?.[0] > selectedIntervalDataEnd.value?.[1]
    ? 'error'
    : 'default';
});
</script>

<template>
  <div class="flex justify-between items-center flex-col">
    <div class="flex items-center w-full">
      <label class="w-10 capitalize">{{ t(`commonLabel.from`) }}</label>
      <InputDateWeek
        v-model="selectedIntervalDataStart"
        name="time-selector-week-start"
        :placeholder="'dd/mm/yyyy'"
        :date-min="availableInterval.start"
        :date-max="getAvailableDateMax"
        :start-date="availableInterval?.end"
        :icon-right="'calendar'"
        :icon-right-variant="ICON_VARIANTS[1]"
        :disabled="disabled"
        test-id="time-selector-week-start"
        class="ml-5"
      />
    </div>

    <div class="flex items-center w-full mt-5">
      <label class="w-10 capitalize">{{ t(`commonLabel.to`) }}</label>
      <InputDateWeek
        v-model="selectedIntervalDataEnd"
        name="time-selector-week-end"
        :placeholder="'dd/mm/yyyy'"
        :date-min="getAvailableDateMin"
        :date-max="availableInterval.end"
        :start-date="availableInterval?.end"
        :icon-right="'calendar'"
        :icon-right-variant="ICON_VARIANTS[1]"
        :variant="getVariant"
        :disabled="disabled"
        test-id="time-selector-week-end"
        class="ml-5"
      />
    </div>
  </div>
</template>
