import { isApiErrorCode, type ApiErrorType } from '@carrefour-gcs/shared';

export function isApiError(error: unknown): error is { apiErrorCode: ApiErrorType } {
  return (
    Object.prototype.hasOwnProperty.call(error, 'apiErrorCode') &&
    isApiErrorCode((error as { apiErrorCode: unknown }).apiErrorCode)
  );
}
export function isApiErrorOfType(error: unknown, apiErrorCode: ApiErrorType) {
  if (isApiError(error)) {
    return error.apiErrorCode === apiErrorCode;
  }

  return false;
}
