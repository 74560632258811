<script setup lang="ts">
import { computed } from 'vue';

import type { SizeType } from '@/components/utils/sizes';

const props = defineProps<{
  size?: SizeType;
}>();

const sizeClsx = computed(() => ({
  'w-full': !props.size,
  'w-4 h-4': props.size === 'small',
  'w-6 h-6': props.size === 'medium',
  'w-10 h-10': props.size === 'large',
}));
</script>

<template>
  <div
    class="aspect-square overflow-visible animate-spin rounded-full border-2 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]"
    :class="sizeClsx"
    role="status"
  ></div>
</template>
