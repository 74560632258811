export const ApiErrorCode = {
  API_ERROR: '@API/ERROR',

  AUTHENT_UNAUTHENTICATED: '@AUTHENT/UNAUTHENTICATED',
  AUTHENT_UNAUTHORIZED: '@AUTHENT/UNAUTHORIZED',
  AUTHENT_UNAUTHORIZED_ROLE: '@AUTHENT/UNAUTHORIZED_ROLE',
  AUTHENT_UNAUTHORIZED_PERMISSION: '@AUTHENT/UNAUTHORIZED_PERMISSION',
  AUTHENT_ACCOUNT_DEACTIVATED: '@AUTHENT/ACCOUNT_DEACTIVATED',
  UNAUTHORIZED_DATA_ACCESS_PERMISSIONS: '@UNAUTHORIZED/DATA_ACCESS_PERMISSIONS',
  UNAUTHORIZED_DIMENSIONS: '@UNAUTHORIZED/DIMENSIONS',

  DATA_NOT_FOUND: '@DATA/NOTFOUND',
  DATA_WRONG_REQUEST: '@DATA/WRONG_REQUEST',
  DATA_NOT_AVAILABLE_FOR_THIS_REFERENTIAL: '@DATA/NOT_AVAILABLE_FOR_THIS_REFERENTIAL',
  DATA_DUPLICATE: '@DATA/DUPLICATE',

  USER_EXPIRED_TOKEN: '@USER/EXPIRED_TOKEN',
  USER_ALREADY_HAS_AN_ACCESS_REQUEST: '@USER/ALREADY_HAS_AN_ACCESS_REQUEST',

  HOLDING_MISSING_ACCOUNT_MANAGER: '@HOLDING/MISSING_ACCOUNT_MANAGER',

  EXPORT_TIMEOUT: '@EXPORT/TIMEOUT',
} as const;

export type ApiErrorType = (typeof ApiErrorCode)[keyof typeof ApiErrorCode];

export function isApiErrorCode(errorCode: unknown): errorCode is ApiErrorType {
  return Object.values(ApiErrorCode).includes(errorCode as ApiErrorType);
}
