import type { FirebaseOptions } from 'firebase/app';
import { FirebaseError, initializeApp } from 'firebase/app';
import type { Auth, AuthProvider, User } from 'firebase/auth';
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import { useFeatureToggle } from '../../../config/composables/useFeatureToggle';

import type { EmailPasswordAuthenticationErrorType } from './auth-client.model';

import { config } from '@/config/configuration';

let auth: Auth | null = null;
let googleProvider: GoogleAuthProvider | null = null;
let microsoftProvider: AuthProvider | null = null;

export function initAuthClient() {
  const firebaseConfig: FirebaseOptions = {
    apiKey: config.idPlatform.apiKey,
    authDomain: config.idPlatform.authDomain,
  };

  const app = initializeApp(firebaseConfig);

  auth = getAuth(app);
  googleProvider = new GoogleAuthProvider();
  microsoftProvider = new OAuthProvider('microsoft.com').addScope('mail.read');
}
export async function googleAuth() {
  if (!auth || !googleProvider) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }

  // Workaround https://firebase.google.com/docs/auth/web/redirect-best-practices?hl=en&authuser=0#signinwithpopup
  const signIn = signInWithPopup;
  await signIn(auth, googleProvider);
}

export async function microsoftAuth() {
  if (!auth || !microsoftProvider) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }

  // Workaround https://firebase.google.com/docs/auth/web/redirect-best-practices?hl=en&authuser=0#signinwithpopup
  const signIn = signInWithPopup;
  await signIn(auth, microsoftProvider);
}

export async function emailPasswordAuth(
  email: string,
  password: string,
): Promise<'success' | EmailPasswordAuthenticationErrorType> {
  const isEmailPasswordAuthenticationEnabled = useFeatureToggle('emailPasswordAuthentication', {
    isCountryAgnostic: true,
  });
  if (!isEmailPasswordAuthenticationEnabled.value) {
    throw new Error('Email password authentication is not enabled');
  }
  if (!auth) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }

  try {
    await signInWithEmailAndPassword(auth, email, password);
    return 'success';
  } catch (error) {
    if (error instanceof FirebaseError) {
      return 'invalid_credential';
    }
    return 'unhandled_error';
  }
}

export async function resetPasswordAuth(email: string) {
  const isEmailPasswordAuthenticationEnabled = useFeatureToggle('emailPasswordAuthentication', {
    isCountryAgnostic: true,
  });
  if (!isEmailPasswordAuthenticationEnabled.value) {
    throw new Error('Email password authentication is not enabled');
  }
  if (!auth) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }
  try {
    sendPasswordResetEmail(auth, email);
  } catch (error) {
    // shallow error for security reasons
    return;
  }
}

export async function onAuthStateChange(callback: (user: User | null) => void) {
  if (!auth) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }
  onAuthStateChanged(auth, callback);
}

export async function logout() {
  if (!auth) {
    throw new Error('Auth client not initialized. Please call initAuthClient() first');
  }
  await signOut(auth);
}
