<script lang="ts" setup>
import dayjs from 'dayjs';
import { computed } from 'vue';

import { defaultPropsSimpleDate } from '../inputDate.props';

import { InputDateGeneric } from '@/components/elements/input';

type MonthDatePickerModelType = { month: number | string; year: number | string };

const props = defineProps(defaultPropsSimpleDate);
const model = defineModel<Date>({
  required: true,
});

const monthDatePickerModelValue = computed(
  (): MonthDatePickerModelType => ({
    month: model.value.getMonth(),
    year: model.value.getFullYear(),
  }),
);
function handleUpdateModelValue(newValue: MonthDatePickerModelType | undefined) {
  if (!newValue) {
    return;
  }
  model.value = dayjs()
    .year(parseInt(`${newValue.year}`, 10))
    .month(parseInt(`${newValue.month}`, 10))
    .toDate();
}

const formatDate = computed(() => {
  return dayjs(model.value).format('MM/YYYY');
});
</script>

<template>
  <InputDateGeneric
    v-bind="{ ...props }"
    month-picker
    :model-value="monthDatePickerModelValue"
    @update:model-value="handleUpdateModelValue($event as MonthDatePickerModelType)"
  >
    <template #content>
      {{ formatDate }}
    </template>
  </InputDateGeneric>
</template>
