import { useGtm } from '@gtm-support/vue-gtm';
import { useRoute, type RouteRecordName } from 'vue-router';

import { config } from '@/config/configuration';
import { useUserStore } from '@/stores/user.store';

type CurrentPageNameType = RouteRecordName | null | undefined;

export const GTM_EVENT_CATEGORIES = {
  page_view: ['page_viewed'],
  datatable: ['change_tab'],
  filter: ['date_changed', 'filter_selection'],
  account: ['account_creation_request', 'account_validation', 'logout'],
  admin: ['access_validated', 'access_rejected'],
  project: ['project_creation'],
  tool: ['tool_creation', 'tool_updated'],
  app: ['app_access'],
} as const;
type CategoryType = keyof typeof GTM_EVENT_CATEGORIES;

interface UseTrackEventArgs<Category extends CategoryType> {
  category: Category;
  event: (typeof GTM_EVENT_CATEGORIES)[Category][number];
  action: string;
  customFields?: Record<string, unknown>;
}
export function useAnalytics() {
  const gtm = useGtm();
  const userStore = useUserStore();

  function trackEvent<Category extends CategoryType>(
    { category, event, action, customFields }: UseTrackEventArgs<Category>,
    route = useRoute(),
  ) {
    if (!gtm) {
      console.info('GTM not configured. Skipping ...');
      return;
    }

    const page_name = route ? routeRecordNameToString(route.name) : window.location.pathname;

    gtm.trackEvent({
      environment: config.appEnv,
      internal_user_id: userStore.authenticatedUser?.id,
      user_holding: userStore.authenticatedUser?.scope?.holding.holdingName,
      user_country: userStore.authenticatedUser?.scope?.holding.country,
      is_accountmanager: userStore.authenticatedUser?.scope?.flags.isAccountManager,
      userActivated: !userStore.isUserOnboarding,
      page_name,
      category,
      event,
      action,
      ...customFields,
    });
  }

  function trackView(currentPageName: CurrentPageNameType, path: string) {
    if (!gtm) {
      console.info('GTM not configured. Skipping ...');
      return;
    }

    const screenName = routeRecordNameToString(currentPageName);
    gtm.trackView(screenName, path, {
      environment: config.appEnv,
      internal_user_id: userStore.authenticatedUser?.id,
      user_holding: userStore.authenticatedUser?.scope?.holding.holdingName,
      user_country: userStore.authenticatedUser?.scope?.holding.country,
      is_accountmanager: userStore.authenticatedUser?.scope?.flags.isAccountManager,
      userActivated: !userStore.isUserOnboarding,
    });
  }
  return { trackEvent, trackView };
}

function routeRecordNameToString(currentPageName: CurrentPageNameType): string {
  return typeof currentPageName === 'string' ? `${currentPageName}` : '';
}
