import type { AppRouter } from '@carrefour-gcs/bff';
import * as Sentry from '@sentry/vue';
import { TRPCClientError, createTRPCProxyClient, httpLink, type TRPCLink } from '@trpc/client';
import type { AnyRouter, inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { observable, tap } from '@trpc/server/observable';
import { createGlobalState } from '@vueuse/core';
import SuperJSON from 'superjson';

import { getCurrentTransactionId } from '../monitoring/sentry';
import { useAuthStore } from '../stores/auth.store';

import { useImpersonateToken } from '@/domain/core/admin/composables/useImpersonateToken';

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

/**
 * Instantiates a TRPC client that can be used to communicate with the backend.
 *
 * @returns A TRPC client.
 * @example
 * // Initialize or retrieve the TRPC client.
 * const trpcClient = useTrpc()
 *
 * // Use the client to query the backend.
 * trpcClient.catman.kpi.getScorecards.query()
 */
export const useTrpc = createGlobalState(() => {
  const authStore = useAuthStore();
  const impersonateToken = useImpersonateToken();

  const httpLinkOptions = {
    url: '/api/trpc',
    async headers() {
      const accessToken = authStore?.auth ? await authStore.auth.getAccessToken() : undefined;
      return Object.fromEntries(
        Object.entries({
          authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          'x-transaction-id': getCurrentTransactionId() ?? undefined,
          'x-impersonate-token': impersonateToken.value ?? undefined,
        }).filter(([_, value]) => value !== undefined),
      );
    },
  };

  return createTRPCProxyClient<AppRouter>({
    transformer: SuperJSON,
    links: [errorLink(), httpLink(httpLinkOptions)],
  });
});

function errorLink<TRouter extends AnyRouter = AnyRouter>(): TRPCLink<TRouter> {
  return () => {
    return ({ next, op }) => {
      return observable((observer) => {
        return next(op)
          .pipe(
            tap({
              error(err) {
                Sentry.captureException(err);
                observer.error(err);
              },
            }),
          )
          .subscribe(observer);
      });
    };
  };
}

export function isTRPCClientError(error: unknown): error is TRPCClientError<AppRouter> {
  return error instanceof TRPCClientError;
}
