import { z } from 'zod';

const userEntitySchema = z.object({
  id: z.number(),
  email: z.string().email(),
  fullName: z.string().min(1),
  countryAlpha2: z.string().length(2),
  companyName: z.string(),
  externalId: z.string().optional(),
});

type UserEntityType = z.infer<typeof userEntitySchema>;

const userEntityToCreateSchema = userEntitySchema.omit({ id: true });

type UserEntityToCreateType = z.infer<typeof userEntityToCreateSchema>;

export {
  userEntitySchema,
  type UserEntityType,
  userEntityToCreateSchema,
  type UserEntityToCreateType,
};
