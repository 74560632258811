import type { UserWithScope } from '@carrefour-gcs/bff';
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import { config } from '@/config/configuration';

export const TRANSACTION_ID_KEY = 'transaction_id';
let currentTransactionId: string | null = null;

export function configureSentry(app: App, router: Router) {
  Sentry.init({
    enabled: !!config.sentryDsn,
    app,
    dsn: config.sentryDsn,
    environment: config.appEnv,
    release: `${config.appVersion}-${config.appEnv}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.growth\.carrefour\.com/], // For activating debug mode in local env. Add /^\/(?!\/)/ in "tracePropagationTargets" array

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function configureSentryUser(user?: UserWithScope) {
  currentTransactionId = globalThis.crypto.randomUUID();

  Sentry.configureScope((scope) => {
    scope.setTag(TRANSACTION_ID_KEY, currentTransactionId);
    if (!user) {
      Sentry.configureScope((scope) => scope.clear());
      return;
    }
    Sentry.setUser({
      id: user.id,
      country: user.scope?.organization.country,
      holdingId: null, //user.scope?.holdingId, TODO add missing field
    });
  });
}
export function getCurrentTransactionId() {
  return currentTransactionId;
}
