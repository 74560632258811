import { useScriptTag } from '@vueuse/core';
import { onMounted, onUnmounted } from 'vue';

import { config } from '../../../config/configuration';

export function useProjectsGTM() {
  const { load, unload } = useScriptTag(config.gtmProjects.scriptUrl, () => {
    console.info('Projects GTM script loaded');
    window.projectsTracker = window.projectsTracker || [];
    window.projectsTracker.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  });

  onMounted(async () => {
    if (config.gtmProjects.enabled) {
      await load(true);
    }
  });

  onUnmounted(async () => {
    if (config.gtmProjects.enabled) {
      unload();
      window.projectsTracker = undefined;
    }
  });
}
