import { z } from 'zod';

import { filtersEntitySchema } from './projects.filters.model';

const comparisonEntitySchema = z.object({
  id: z.number(),
  comparisonFilters: filtersEntitySchema,
});

type ComparisonEntityType = z.infer<typeof comparisonEntitySchema>;

export { comparisonEntitySchema, type ComparisonEntityType };
