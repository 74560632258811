import {
  isAValidNielsenPeriod,
  isDateSelectionOption,
  isPeriod,
  type DateSelectionOption,
} from '@carrefour-gcs/shared';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { LocationQueryRaw } from 'vue-router';

import type { KpisPerimeterType } from '../kpisPerimeter.model';
import { useUrlQueryString, type QueryRouter } from '../useUrlQueryString';

import {
  isNielsenPeriod,
  type DatePeriod,
  type DatePeriodIntervalType,
  type NielsenPeriodIntervalType,
  type PeriodIntervalType,
} from '@/domain/core/filter/period.model';
import { customDateToString, isCustomDateEquals, parseCustomDate } from '@/helpers/date.helpers';
import { useUserStore } from '@/stores/user.store';

export const usePeriod = (
  router: QueryRouter,
): KpisPerimeterType['period'] & {
  generateSelectedPeriodQueryParams: (
    period: DatePeriodIntervalType | NielsenPeriodIntervalType,
  ) => LocationQueryRaw;
} => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { updateQuery } = useUrlQueryString(router);

  const selectedPeriod = useGetPeriodFromQuery(router);

  const isDefault = (
    currentValue: DatePeriodIntervalType | NielsenPeriodIntervalType = selectedPeriod.value,
  ) => {
    const checksForEquality = [
      currentValue.option === defaultKpisPerimeter.value.period.option,
      currentValue.period === defaultKpisPerimeter.value.period.period,
    ];

    if (isNielsenPeriod(currentValue)) {
      checksForEquality.push(
        currentValue.periodInterval.start ===
          defaultKpisPerimeter.value.period.periodInterval.start,
        currentValue.periodInterval.end === defaultKpisPerimeter.value.period.periodInterval.end,
      );
      // Should alway be true because, at the moment,
      // defaultKpisPerimeter.value.period is of type DatePeriodIntervalType
    } else if (!isNielsenPeriod(defaultKpisPerimeter.value.period)) {
      checksForEquality.push(
        isCustomDateEquals(
          currentValue.periodInterval.start,
          defaultKpisPerimeter.value.period.periodInterval.start,
        ),
        isCustomDateEquals(
          currentValue.periodInterval.end,
          defaultKpisPerimeter.value.period.periodInterval.end,
        ),
      );
    }

    return checksForEquality.every(Boolean);
  };

  const generateSelectedPeriodQueryParams = (
    period: DatePeriodIntervalType | NielsenPeriodIntervalType,
  ) => {
    if (isDefault(period)) {
      return {
        option: undefined,
        period: undefined,
        start: undefined,
        end: undefined,
      };
    }

    return {
      option: period.option,
      period: period.period,
      start:
        typeof period.periodInterval.start === 'string'
          ? period.periodInterval.start
          : customDateToString(period.periodInterval.start),
      end:
        typeof period.periodInterval.end === 'string'
          ? period.periodInterval.end
          : customDateToString(period.periodInterval.end),
    };
  };

  const updatePeriod = async (period: DatePeriodIntervalType | NielsenPeriodIntervalType) => {
    await updateQuery(generateSelectedPeriodQueryParams(period));
  };

  const referentialIntervals = computed(() => {
    return defaultKpisPerimeter.value.referentialIntervals;
  });

  return {
    referentialIntervals,
    selectedPeriod,
    updatePeriod,
    isDefault: () => isDefault(),
    generateSelectedPeriodQueryParams,
  };
};

const useGetPeriodFromQuery = (router: QueryRouter) => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { query } = useUrlQueryString(router);

  return computed(() => {
    let option: DateSelectionOption = defaultKpisPerimeter.value.period.option;
    let period = defaultKpisPerimeter.value.period.period as DatePeriod;
    let periodInterval = defaultKpisPerimeter.value.period.periodInterval as PeriodIntervalType;

    if (isDateSelectionOption(query.value.option)) {
      option = query.value.option as DateSelectionOption;
    }

    if (isPeriod(query.value.period)) {
      if (query.value.period !== 'nielsen') {
        period = query.value.period as DatePeriod;
      }
    }

    if (typeof query.value.start === 'string' && typeof query.value.end === 'string') {
      if (isAValidNielsenPeriod(query.value.start) && isAValidNielsenPeriod(query.value.end)) {
        return {
          period: 'nielsen',
          option,
          periodInterval: {
            start: query.value.start,
            end: query.value.end,
          },
        } satisfies NielsenPeriodIntervalType;
      }

      periodInterval = {
        start: parseCustomDate(query.value.start),
        end: parseCustomDate(query.value.end),
      };
    }

    return {
      period,
      option,
      periodInterval,
    } satisfies DatePeriodIntervalType;
  });
};
