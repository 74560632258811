import * as z from 'zod';

export interface NielsenPeriodType {
  year: number;
  period: number;
}
export type NielsenPeriodStrType = `${number}P${string}`;

const nielsenPeriodRegex = /^[0-9]{4}P(0[1-9]|1[0-3])$/;

export function isAValidNielsenPeriod(value: string): value is NielsenPeriodStrType {
  return nielsenPeriodRegex.test(value);
}

export const NielsenPeriodIntervalZodSchema = z.object({
  start: z.string().regex(nielsenPeriodRegex),
  end: z.string().regex(nielsenPeriodRegex),
});
export type NielsenPeriodIntervalType = z.infer<typeof NielsenPeriodIntervalZodSchema>;

export function nielsenPeriodToInt(period: string): number | null {
  const year = parseInt(period.substring(0, 4));
  const month = parseInt(period.substring(5));

  // Check for valid year and month
  if (isNaN(year) || isNaN(month) || month <= 0 || month > 13) {
    return null; // Invalid period
  }

  return year * 100 + month;
}

export function intToNielsenPeriod(value: number): NielsenPeriodType | null {
  const year = Math.floor(value / 100);
  const period = value % 100;

  if (period <= 0 || period > 13) {
    return null; // Invalid period value
  }

  return { year, period };
}

export function generateNielsenPeriods(minPeriod: string, maxPeriod: string): NielsenPeriodType[] {
  const minInt = nielsenPeriodToInt(minPeriod);
  const maxInt = nielsenPeriodToInt(maxPeriod);
  const periods: NielsenPeriodType[] = [];

  if (minInt === null || maxInt === null) {
    console.error('Invalid input periods');
    return periods;
  }

  for (let i = minInt; i <= maxInt; i++) {
    const nielsenPeriod = intToNielsenPeriod(i);
    if (nielsenPeriod !== null) {
      periods.push(nielsenPeriod);
    }
  }

  return periods;
}

export function nielsenPeriodStrToNielsenPeriod(value: NielsenPeriodStrType): NielsenPeriodType {
  if (!isAValidNielsenPeriod(value)) {
    throw new Error(`Invalid Nielsen period "${value}"`);
  }

  const parts = value.split('P');
  return {
    year: parts[0] ? parseInt(parts[0], 10) : NaN,
    period: parts[1] ? parseInt(parts[1], 10) : NaN,
  };
}
export function nielsenPeriodToStr({ year, period }: NielsenPeriodType): NielsenPeriodStrType {
  return `${year}P${`${period}`.padStart(2, '0')}`;
}

export function formatNielsenPeriodToReadableStr(period: NielsenPeriodType): string {
  return `P${`${period.period}`.padStart(2, '0')} ${period.year}`;
}
