import { z } from 'zod';

const productNameSchema = z.enum(['Growth', 'ECommerce']);

const productEntitySchema = z.object({
  id: z.number(),
  name: productNameSchema,
  url: z.string(),
});

type ProductEntityType = z.infer<typeof productEntitySchema>;

type ProductNameType = z.infer<typeof productNameSchema>;

const productEntityToCreateSchema = productEntitySchema.omit({ id: true });

type ProductEntityToCreateType = z.infer<typeof productEntityToCreateSchema>;

export {
  productEntitySchema,
  type ProductEntityType,
  productEntityToCreateSchema,
  type ProductEntityToCreateType,
  type ProductNameType,
};
