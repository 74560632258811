import { z } from 'zod';

const projectOutputEntitySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
});

type ProjectOutputEntityType = z.infer<typeof projectOutputEntitySchema>;

const projectOutputEntityToCreateSchema = projectOutputEntitySchema.omit({ id: true });

type ProjectOutputEntityToCreateType = z.infer<typeof projectOutputEntityToCreateSchema>;

export {
  projectOutputEntitySchema,
  type ProjectOutputEntityType,
  projectOutputEntityToCreateSchema,
  type ProjectOutputEntityToCreateType,
};
