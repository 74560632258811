import {
  DATE_SELECTION_OPTIONS,
  customDateToDate,
  dateToCustomDate,
  type DateReferentialIntervalType,
  type DateSelectionOption,
  type Period,
  type ProductNomenclatureType,
  type ReferentialIntervalsType,
  type TranslationKey,
} from '@carrefour-gcs/shared';
import dayjs from 'dayjs';
import type { TOptions } from 'i18next';
import type { Component } from 'vue';

import type { DatePeriod, PeriodIntervalType } from '../../period.model';
import type { DateOptionsSelectorOptionType } from '../periodSelector/dateOptionsSelector/DateOptionsSelector.vue';

import {
  CalendarIntervalSelector,
  MonthlyIntervalSelector,
  WeeklyIntervalSelector,
} from './dateIntervalSelectors';

import type { useLanguage } from '@/composables/useLanguage';
import type { SelectedIntervalType } from '@/domain/core/filter/components/periodSelector/periodSelector.model';

/**
 * Map period type to time selector component
 *
 * Nielsen is excluded because it's handled differently
 */
export const DATE_PERIOD_TO_COMPONENT: Record<DatePeriod, Component> = {
  daily: CalendarIntervalSelector,
  weekly: WeeklyIntervalSelector,
  monthly: MonthlyIntervalSelector,
};

export const availableMonthInterval = (
  t: (key: TranslationKey | NonNullable<string>, options?: TOptions) => string,
): Array<{ id: number; text: string }> => {
  const months = [];
  for (let i: number = 1; i <= 12; i++) {
    months.push({
      id: i,
      text: t(`commonLabel.month.${i}`),
    });
  }
  return months;
};

function getWeekCumulativeToDateStartFromEnd(referentialEnd: dayjs.Dayjs) {
  return referentialEnd.subtract(referentialEnd.isoWeeksInYear() - 1, 'weeks');
}

export const getDateCumulativeOptions = (
  period: DatePeriod,
  referentialIntervalEnd: Date,
  t: ReturnType<typeof useLanguage>['t'],
): DateOptionsSelectorOptionType[] => {
  const referentialIntervalEndDayJs = dayjs(referentialIntervalEnd);
  const getSublabel: Record<DatePeriod, Record<DateSelectionOption, string>> = {
    daily: {
      cumulative_to_date: `${referentialIntervalEndDayJs
        .subtract(1, 'year')
        .add(1, 'day')
        .format('DD/MM/YYYY')} ➔ ${referentialIntervalEndDayJs.format('DD/MM/YYYY')}`,
      year_to_date: `${referentialIntervalEndDayJs
        .startOf('year')
        .format('DD/MM/YYYY')} ➔ ${referentialIntervalEndDayJs.format('DD/MM/YYYY')}`,
      last_period: '',
      dateRange: '',
    },
    weekly: {
      cumulative_to_date: `S${getWeekCumulativeToDateStartFromEnd(
        referentialIntervalEndDayJs,
      ).isoWeek()} ${getWeekCumulativeToDateStartFromEnd(
        referentialIntervalEndDayJs,
      ).year()} ➔ S${referentialIntervalEndDayJs.isoWeek()} ${referentialIntervalEndDayJs.year()}`,
      year_to_date: `S${referentialIntervalEndDayJs
        .isoWeek(1)
        .isoWeek()} ${referentialIntervalEndDayJs.year()} ➔ S${referentialIntervalEndDayJs.isoWeek()} ${referentialIntervalEndDayJs.year()}`,
      last_period: `S${referentialIntervalEndDayJs.isoWeek()} ${referentialIntervalEndDayJs.year()}`,
      dateRange: '',
    },
    monthly: {
      cumulative_to_date: `${
        availableMonthInterval(t)[referentialIntervalEndDayJs.subtract(11, 'months').month()].text
      } ${referentialIntervalEndDayJs.subtract(11, 'months').year()} ➔ ${
        availableMonthInterval(t)[referentialIntervalEndDayJs.month()].text
      } ${referentialIntervalEndDayJs.year()}`,
      year_to_date: `${
        availableMonthInterval(t)[referentialIntervalEndDayJs.startOf('year').month()].text
      } ${referentialIntervalEndDayJs.year()} ➔ ${
        availableMonthInterval(t)[referentialIntervalEndDayJs.month()].text
      } ${referentialIntervalEndDayJs.year()}`,
      last_period: `${
        availableMonthInterval(t)[referentialIntervalEndDayJs.month()].text
      } ${referentialIntervalEndDayJs.year()}`,
      dateRange: '',
    },
  };

  return DATE_SELECTION_OPTIONS.filter((o) => o !== 'dateRange').map((o) => ({
    label: `timeSelectorPopin.${o}`,
    subLabel: getSublabel[period][o],
    value: o,
  }));
};

interface GetCarrefourPeriodIntervalArgs {
  selectedPeriod: DatePeriod;
  currentDateReferentialInterval: DateReferentialIntervalType;
  selectedInterval: PeriodIntervalType;
  selectedCalendarOption: DateSelectionOption;
}

export function getCarrefourPeriodInterval({
  selectedPeriod,
  currentDateReferentialInterval,
  selectedCalendarOption,
  selectedInterval,
}: GetCarrefourPeriodIntervalArgs) {
  const computeInterval: Record<
    DatePeriod,
    Record<DateSelectionOption, () => PeriodIntervalType>
  > = {
    daily: {
      last_period: () => {
        return selectedInterval;
      },
      cumulative_to_date: () => {
        const periodDateEnd = customDateToDate(currentDateReferentialInterval.end);
        return {
          start: dateToCustomDate(
            dayjs(periodDateEnd).subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
          ),

          end: dateToCustomDate(dayjs(periodDateEnd).endOf('day').toDate()),
        };
      },
      year_to_date: () => {
        const periodDateEnd = customDateToDate(currentDateReferentialInterval.end);

        return {
          start: dateToCustomDate(dayjs(periodDateEnd).startOf('year').startOf('day').toDate()),
          end: dateToCustomDate(dayjs(periodDateEnd).endOf('day').toDate()),
        };
      },
      dateRange: () => {
        return selectedInterval;
      },
    },
    weekly: {
      last_period: () => {
        const periodEndDate = customDateToDate(currentDateReferentialInterval.end);

        return {
          start: dateToCustomDate(dayjs(periodEndDate).startOf('week').toDate()),
          end: currentDateReferentialInterval.end,
        };
      },
      cumulative_to_date: () => {
        const periodEndDate = customDateToDate(currentDateReferentialInterval.end);

        return {
          start: dateToCustomDate(
            dayjs(periodEndDate).subtract(1, 'year').startOf('week').toDate(),
          ),
          end: currentDateReferentialInterval.end,
        };
      },
      year_to_date: () => {
        return {
          start: dateToCustomDate(
            dayjs(customDateToDate(currentDateReferentialInterval.end))
              .isoWeek(1)
              .isoWeekday(1)
              .startOf('day')
              .toDate(),
          ),
          end: currentDateReferentialInterval.end,
        };
      },
      dateRange: () => {
        return selectedInterval;
      },
    },
    monthly: {
      last_period: () => {
        return {
          start: dateToCustomDate(
            dayjs(customDateToDate(currentDateReferentialInterval.end)).startOf('month').toDate(),
          ),
          end: currentDateReferentialInterval.end,
        };
      },

      // cumul à date mobile
      cumulative_to_date: () => {
        return {
          start: dateToCustomDate(
            dayjs(customDateToDate(currentDateReferentialInterval.end))
              .startOf('month')
              .subtract(11, 'months')
              .toDate(),
          ),
          end: currentDateReferentialInterval.end,
        };
      },
      // cumul à date
      year_to_date: () => {
        return {
          start: dateToCustomDate(
            dayjs(customDateToDate(currentDateReferentialInterval.end))
              .startOf('year')
              .startOf('day')
              .toDate(),
          ),
          end: currentDateReferentialInterval.end,
        };
      },
      dateRange: () => {
        return selectedInterval;
      },
    },
  };

  return computeInterval[selectedPeriod][selectedCalendarOption]();
}

/**
 * From a date interval defined as a tuple of two Date, return a normalized "SelectedIntervalType" month interval
 * It means that the returned interval will be the first day of the month for the start date and the last day of the month for the end date
 */
export function normalizeMonthDateInterval([from, to]: [
  from: Date,
  to: Date,
]): SelectedIntervalType {
  return {
    from: dayjs(from).startOf('month').startOf('day').toDate(),
    to: dayjs(to).endOf('month').endOf('day').toDate(),
  };
}

/**
 * Get the right referential interval for the current referential and the selected period and period
 * {@throws Error} if the combination of referential and period is not supported or if no Nielsen interval is available but the current referential is Nielsen
 */
export function getCurrentDateReferentialInterval({
  currentReferential,
  referentialIntervals,
  selectedPeriodType,
}: {
  currentReferential: ProductNomenclatureType;
  referentialIntervals: ReferentialIntervalsType;
  selectedPeriodType: Period;
}): DateReferentialIntervalType {
  if (selectedPeriodType === 'nielsen') {
    throw new Error(
      `Invalid state: No DateReferentialInterval available for periodType="${selectedPeriodType}" is incompatible`,
    );
  }
  if (currentReferential === 'nielsen' && !referentialIntervals.nielsen) {
    throw new Error(
      `Invalid state: No Nielsen interval available but current referential is Nielsen`,
    );
  }
  if (currentReferential === 'nielsen' && selectedPeriodType !== 'weekly') {
    throw new Error(
      `Invalid state: No DateReferentialInterval available for Referential="${currentReferential}" and periodType="${selectedPeriodType}"`,
    );
  }
  if (currentReferential === 'nielsen') {
    if (!referentialIntervals.nielsen) {
      throw new Error(
        'ShouldNotOccurredError: nielsen referential existance should have been checked before',
      );
    }
    return referentialIntervals.nielsen.weekly;
  }

  return referentialIntervals.carrefour[selectedPeriodType];
}
