import type { AvailableLanguagesType, CustomDateType } from '@carrefour-gcs/shared';
import { AVAILABLE_LANGUAGES, locales } from '@carrefour-gcs/shared';
import i18next, { type ResourceLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'i18next-vue';
import { ref, watch, type Ref } from 'vue';

import projectsTranslationsEn from './domain/projects/language/en.json';
import projectsTranslationsEs from './domain/projects/language/es.json';
import projectsTranslationsFr from './domain/projects/language/fr.json';
import projectsTranslationsIt from './domain/projects/language/it.json';

import { setDayjsLocale } from '@/helpers/date.helpers';

const LANGUAGE_RESOURCES: Record<AvailableLanguagesType, ResourceLanguage> = {
  en: { translation: { ...locales.en, ...projectsTranslationsEn } },
  fr: { translation: { ...locales.fr, ...projectsTranslationsFr } },
  it: { translation: { ...locales.it, ...projectsTranslationsIt } },
  es: { translation: { ...locales.es, ...projectsTranslationsEs } },
};

i18next.use(LanguageDetector).init({
  fallbackLng: 'en',
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupFromPathIndex: 0,
    convertDetectedLanguage: (lng: string) => {
      const detectedLng = lng.split('-')?.[0] || 'en';
      if (AVAILABLE_LANGUAGES.includes(detectedLng)) {
        return detectedLng;
      }
      return 'en';
    },
  },
  resources: LANGUAGE_RESOURCES,
});

/**
 * @deprecated Use `useLanguage()` methods instead.
 */
export default i18next;

/**
 * @deprecated Use `useLanguage().setLanguage` instead.
 */
export function changeLanguage(lang: AvailableLanguagesType) {
  setDayjsLocale(lang);
  i18next.changeLanguage(lang);
}

export function formatDateToLocale(date: Date): string {
  return date.toLocaleDateString(i18next.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatCustomDateToLocaleFR(customDate: CustomDateType): string {
  const { year, month, day } = customDate;
  return new Date(year, month - 1, day).toLocaleDateString('fr', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

/**
 * @deprecated Use `useLanguage().language` instead.
 */
export function useCurrentLang(): Ref<AvailableLanguagesType> {
  const { i18next } = useTranslation();
  const currentLang = ref(i18next.resolvedLanguage as AvailableLanguagesType);
  watch(
    () => i18next.resolvedLanguage,
    (resolvedLanguage) => {
      currentLang.value = resolvedLanguage as AvailableLanguagesType;
    },
  );
  return currentLang;
}
