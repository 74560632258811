import { FILTER_NAME_TO_KPI_FILTER } from '@carrefour-gcs/shared';
import { computed } from 'vue';

import type { QueryRouter } from '../useUrlQueryString';

import { useGetFiltersFromQuery } from './useGetFiltersFromQuery';

export const useGetFiltersForKpis = (router: QueryRouter) => {
  const filters = useGetFiltersFromQuery(router);

  return computed(() => {
    return {
      [FILTER_NAME_TO_KPI_FILTER['store']]: filters.storeFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['region']]: filters.regionFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['sector']]: filters.sectorFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['department']]: filters.departmentFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['grpClass']]: filters.groupClassFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['class']]: filters.classFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['subClass']]: filters.subClassFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['activityType']]: filters.activityTypeFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['chainType']]: filters.chainTypeFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['integratedFranchised']]:
        filters.integratedFranchisedFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['brandType']]: filters.triptyqueFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['bemMainHolding']]: filters.bemHoldingFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['supplier']]: filters.supplierFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['brand']]: filters.brandFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['item']]: filters.itemFilters.value,
      [FILTER_NAME_TO_KPI_FILTER['barcode']]: filters.barcodeFilters.value,
    };
  });
};
