interface RetryOnErrorOpts {
  maxAttempts?: number;
  baseDelayMs?: number; // This delay will be multiplied by the number of attempts
}
export async function retryOnError<T>(
  taskName: string,
  task: () => Promise<T>,
  { baseDelayMs = 500, maxAttempts = 5 }: RetryOnErrorOpts = {
    maxAttempts: 5,
    baseDelayMs: 500,
  },
) {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const result = await task();

      return result;
    } catch (error: unknown) {
      attempts++;

      await delay(baseDelayMs * attempts);
      if (attempts === maxAttempts) {
        const errorTyped = error as Error;
        errorTyped.message = `[${taskName}] Task failed after ${maxAttempts} attempts: ${errorTyped.message}`;
        throw errorTyped;
      }
    }
  }
}

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
