<script lang="ts" setup>
import dayjs from 'dayjs';
import { useTranslation } from 'i18next-vue';
import { computed } from 'vue';

import { InputDateGeneric } from '@/components/elements/input';
import {
  defaultPropsArrayDate,
  type DateIntervalType as InputDateRangeDateIntervalType,
} from '@/components/elements/input/inputDate/inputDate.props';

const { t } = useTranslation();

const props = defineProps(defaultPropsArrayDate);
const model = defineModel<InputDateRangeDateIntervalType>({ required: true });

const formatDateValue = (date: Date) => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  return '';
};

const formatedDateMin = computed(() => formatDateValue(model.value[0]));
const formatedDateMax = computed(() => formatDateValue(model.value[1]));
</script>

<template>
  <InputDateGeneric v-bind="{ ...props }" v-model="model" range multi-calendars>
    <template #content>
      <span>
        <label class="text-gray-500">{{ t(`commonLabel.from`) }}</label>
        {{ formatedDateMin }}
        <label class="text-gray-500">{{ t(`commonLabel.to`) }}</label>
        {{ formatedDateMax }}
      </span>
    </template>
  </InputDateGeneric>
</template>
