import {
  formatNielsenPeriodToReadableStr,
  generateNielsenPeriods,
  nielsenPeriodStrToNielsenPeriod,
  nielsenPeriodToStr,
  type DateSelectionOption,
  type DeepPartial,
  type NielsenPeriodStrType,
  type NielsenPeriodType,
  type PeriodReferentialIntervalType,
} from '@carrefour-gcs/shared';

import type { NielsenIntervalType, NielsenPeriodIntervalType } from '../../period.model';

import { DEFAULT_PERIOD_OPTIONS } from '@/composables/kpisPerimeter';

/**
 * Nielsen split a year in 13 periods
 */
export const NIELSEN_PERIOD_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] as const;

export function getDefaultNielsenPeriod({
  nielsenInterval,
  withOption = DEFAULT_PERIOD_OPTIONS,
}: {
  nielsenInterval: PeriodReferentialIntervalType;
  withOption?: DateSelectionOption;
}): NielsenPeriodIntervalType {
  const nielsenStart = nielsenPeriodStrToNielsenPeriod(nielsenInterval.start);
  const nielsenEnd = nielsenPeriodStrToNielsenPeriod(nielsenInterval.end);
  const nielsenCumulativeOptions = getNielsenCumulativeOptions(nielsenEnd);

  if (withOption === 'dateRange') {
    return {
      period: 'nielsen',
      option: withOption,
      periodInterval: {
        start: nielsenPeriodToStr({
          ...nielsenEnd,
          // If start and end are on the same year, we start from the first available period. otherwise we start from period 1
          period: nielsenStart.year === nielsenEnd.year ? nielsenStart.period : 1,
        }),
        end: nielsenInterval.end,
      },
    };
  }

  return {
    period: 'nielsen',
    option: withOption,
    periodInterval: nielsenCumulativeOptions[withOption].periodInterval,
  };
}

type WithoutDateRangeDateSelectionOption = Exclude<DateSelectionOption, 'dateRange'>;

function getYearToDateStart({ year, period }: NielsenPeriodType): NielsenPeriodType {
  if (period === NIELSEN_PERIOD_VALUES[NIELSEN_PERIOD_VALUES.length - 1]) {
    return { year, period: 1 };
  }

  return { year: year - 1, period: period + 1 };
}

export function getPreviousNielsenPeriodFromStr(
  nielsenPeriod: NielsenPeriodStrType,
): NielsenPeriodType {
  const { year, period } = nielsenPeriodStrToNielsenPeriod(nielsenPeriod);

  if (period === 1) {
    return {
      year: year - 1,
      period: NIELSEN_PERIOD_VALUES[NIELSEN_PERIOD_VALUES.length - 1],
    };
  }

  return { year, period: period - 1 };
}

export function getNielsenCumulativeOptions(referentialPeriodEnd: NielsenPeriodType): Record<
  WithoutDateRangeDateSelectionOption,
  {
    value: WithoutDateRangeDateSelectionOption;
    label: string;
    periodInterval: NielsenIntervalType;
  }
> {
  const previousYearPeriod = getYearToDateStart(referentialPeriodEnd);
  const startOfTheYearPeriod: NielsenPeriodType = {
    ...referentialPeriodEnd,
    period: 1,
  };
  return {
    year_to_date: {
      value: 'year_to_date',
      periodInterval: {
        start: nielsenPeriodToStr(startOfTheYearPeriod),
        end: nielsenPeriodToStr(referentialPeriodEnd),
      },
      label: `${formatNielsenPeriodToReadableStr(
        startOfTheYearPeriod,
      )} ➔ ${formatNielsenPeriodToReadableStr(referentialPeriodEnd)}`,
    },
    cumulative_to_date: {
      value: 'cumulative_to_date',
      periodInterval: {
        start: nielsenPeriodToStr(previousYearPeriod),
        end: nielsenPeriodToStr(referentialPeriodEnd),
      },
      label: `${formatNielsenPeriodToReadableStr(
        previousYearPeriod,
      )} ➔ ${formatNielsenPeriodToReadableStr(referentialPeriodEnd)}`,
    },
    last_period: {
      value: 'last_period',
      periodInterval: {
        start: nielsenPeriodToStr(referentialPeriodEnd),
        end: nielsenPeriodToStr(referentialPeriodEnd),
      },
      label: `${formatNielsenPeriodToReadableStr(referentialPeriodEnd)}`,
    },
  };
}

export function generateNielsenPeriodOptions({
  referentialInterval,
  selectedStart,
  selectedEnd,
}: {
  referentialInterval: NielsenIntervalType;
  selectedStart: NielsenPeriodType;
  selectedEnd: NielsenPeriodType;
}) {
  const periods = generateNielsenPeriods(referentialInterval.start, referentialInterval.end);

  const availableStartPeriod = periods
    .filter(({ year }) => year === selectedStart.year)
    .map(({ period }) => period);

  const availableEndPeriod = periods
    .filter(({ year }) => year === selectedEnd.year)
    .filter(({ period }) => {
      const nielsenStartPeriod = selectedStart;
      const nielsenEndPeriod = selectedEnd;

      if (nielsenStartPeriod.year === nielsenEndPeriod.year) {
        return period >= nielsenStartPeriod.period;
      }

      return true;
    })
    .map(({ period }) => period);

  return {
    startOptions: {
      periods: NIELSEN_PERIOD_VALUES.map((period) => ({
        value: period,
        label: 'P' + `${period}`.padStart(2, '0'),
        disabled: !availableStartPeriod.includes(period),
      })),
      years: [...new Set(periods.map(({ year }) => year))].map((year) => ({
        value: year,
        label: `${year}`,
      })),
    },
    endOptions: {
      periods: NIELSEN_PERIOD_VALUES.map((period) => ({
        value: period,
        label: 'P' + `${period}`.padStart(2, '0'),
        disabled: !availableEndPeriod.includes(period),
      })),
      years: [
        ...new Set(
          periods.filter(({ year }) => year >= selectedStart.year).map(({ year }) => year),
        ),
      ].map((year) => ({
        value: year,
        label: `${year}`,
      })),
    },
  };
}

export function getUpdatedNielsenIntervalValues({
  start,
  end,
  referentialInterval,
  currentInterval,
}: DeepPartial<{
  start: NielsenPeriodType;
  end: NielsenPeriodType;
}> & {
  referentialInterval: NielsenIntervalType;
  currentInterval: {
    start: NielsenPeriodType;
    end: NielsenPeriodType;
  };
}) {
  /**
   *  If current selected period is not available in new selected year,
   * returns period as first available period in the selection
   * else, returns current selected period
   */
  const getAvailablePeriodForSelectedYear = (
    year: NielsenPeriodType['year'],
    currentSelectedPeriod: NielsenPeriodType,
  ) => {
    const periods = generateNielsenPeriods(referentialInterval.start, referentialInterval.end);

    const availablePeriod = periods.filter((period) => period.year === year);
    if (currentSelectedPeriod.period < availablePeriod[0].period) {
      return availablePeriod[0].period;
    }

    return currentSelectedPeriod.period;
  };

  if (start?.year !== undefined) {
    start.period = getAvailablePeriodForSelectedYear(start.year, currentInterval.start);

    /**
     * When changing start year, check that current selected end year is compatible
     * otherwise, set end year to same as start year
     */
    if (currentInterval.end.year < start.year) {
      end = {
        ...end,
        year: start.year,
      };
    }
  }

  if (end?.year !== undefined) {
    end.period = getAvailablePeriodForSelectedYear(end.year, currentInterval.end);

    /**
     * When changing start year, check that current selected end period is compatible
     * otherwise, set end period to same as start period
     */
    if (start?.year !== undefined && start.year === end.year) {
      if (start.period !== undefined && end.period !== undefined && end.period < start.period) {
        end = {
          ...end,
          period: start.period,
        };
      }
    }
  }

  return { start, end };
}
