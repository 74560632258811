<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';

import { useHubSpot } from './composables/useHubSpot';
import { useProductFruits } from './domain/core/user/composables/useProductFruits';
import { useProjectsGTM } from './domain/projects/analytics/useProjectsGTM';
import NotificationProvider from './providers/notification/NotificationProvider.vue';
import { useAuthStore } from './stores/auth.store';

import { SpinnerLoader } from '@/components/elements/spinner';
import { useTrackUserAppAccess } from '@/domain/core/analytics/composables/useTrackUserAppAccess';

const authStore = useAuthStore();
useHubSpot();
useTrackUserAppAccess();
useProductFruits();
useProjectsGTM();

onMounted(async () => {
  authStore.initAuth();
});
</script>

<template>
  <NotificationProvider>
    <div v-if="authStore.isAuthLoading" class="h-screen w-screen flex items-center justify-center">
      <SpinnerLoader size="large" />
    </div>
    <RouterView v-else />
  </NotificationProvider>
</template>
