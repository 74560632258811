import { createGlobalState, useScriptTag } from '@vueuse/core';
import { onMounted, onUnmounted } from 'vue';

import { config } from '../config/configuration';

import { useFeatureToggle } from '@/config/composables/useFeatureToggle';

interface LoadHubspotFormArgsType {
  target: string;
  onBeforeFormSubmit: (form: HTMLFormElement) => void;
  onFormSubmitted: VoidFunction;
}

export const useHubSpot = createGlobalState(() => {
  const isHubSpotEnabled = useFeatureToggle('hubSpot');

  const { load, unload } = useScriptTag(
    `//js-eu1.hs-scripts.com/${config.hubSpot.portalId}.js`,
    () => {
      console.info('HubSpot script loaded');
    },
    {
      manual: true,
      attrs: {
        id: 'hs-script-loader',
      },
    },
  );

  onMounted(async () => {
    if (isHubSpotEnabled.value) {
      load();
    }
  });

  onUnmounted(async () => {
    if (isHubSpotEnabled.value) {
      unload();
    }
  });

  function loadHubspotForm({
    target,
    onBeforeFormSubmit,
    onFormSubmitted,
  }: LoadHubspotFormArgsType) {
    const { load, unload } = useScriptTag(
      '//js-eu1.hsforms.net/forms/embed/v2.js',
      () => {
        // @ts-expect-error: hbspt is not typed
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: config.hubSpot.portalId,
          formId: config.hubSpot.formId,
          target,
          onBeforeFormSubmit,
          onFormSubmitted,
        });
      },
      {
        manual: true,
        attrs: {
          id: 'hsform-script-loader',
        },
      },
    );
    return { loadFormScript: load, unLoadFormScript: unload };
  }

  return {
    loadHubspotForm,
  };
});
