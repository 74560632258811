import { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import type { PropType } from 'vue';

import type { IconVariantType, VariantType } from '@/components/elements/input';
import type { IconName } from '@/components/icons/icon.model';

export type DateIntervalType = [Date, Date];

export const commonProps = {
  name: { type: String, required: true },
  variant: { type: String as PropType<VariantType>, default: 'default' },
  label: { type: String, required: false, default: '' },
  placeholder: { type: String, required: true },
  message: { type: String, required: false, default: undefined },
  required: { type: Boolean, default: false },
  iconLeft: { type: String as PropType<IconName>, required: false, default: undefined },
  iconLeftVariant: { type: String as PropType<IconVariantType>, default: 'default' },
  iconRight: { type: String as PropType<IconName>, required: false, default: undefined },
  iconRightVariant: { type: String as PropType<IconVariantType>, default: 'default' },
  error: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  dateMin: { type: Date, required: false, default: undefined },
  dateMax: { type: Date, required: false, default: undefined },
  startDate: { type: Date, required: false, default: undefined },
  canReset: { type: Boolean, default: false },
  testId: { type: String, required: false, default: undefined },
} as const;

export const defaultPropsSimpleDate = {
  ...commonProps,
  modelValue: { type: Date, default: null },
} as const;

export const defaultPropsArrayDate = {
  ...commonProps,
} as const;

export const defaultPropsDate = {
  ...commonProps,
  monthPicker: { type: Boolean, default: undefined },
  range: { type: Boolean, default: undefined },
  multiCalendars: {
    type: Boolean as PropType<VueDatePickerProps['multiCalendars']>,
    default: undefined,
  },
  weekPicker: { type: Boolean, default: undefined },
  weekNumbers: {
    type: String as PropType<VueDatePickerProps['weekNumbers']>,
    default: undefined,
  },
} as const;
