import type { EnvType } from '@carrefour-gcs/shared';

export const config = {
  baseUrl: import.meta.env.BASE_URL,
  appVersion: __APP_VERSION__,
  appEnv: import.meta.env.VITE_APP_ENV as EnvType,
  devMode: import.meta.env.MODE === 'development',
  idPlatform: {
    apiKey: import.meta.env.VITE_ID_PLATFORM_API_KEY,
    authDomain: import.meta.env.VITE_ID_PLATFORM_AUTH_DOMAIN,
  },
  gtm: {
    enabled: import.meta.env.MODE !== 'development',
    id: import.meta.env.VITE_GTM_ID ?? 'GTM-XXXXXXX',
    auth: import.meta.env.VITE_GTM_AUTH,
    preview: import.meta.env.VITE_GTM_PREVIEW,
  },
  gtmProjects: {
    enabled: import.meta.env.MODE !== 'development',
    debug: import.meta.env.MODE === 'development',
    scriptUrl: import.meta.env.VITE_PROJECTS_GTM_SCRIPT_URL ?? '',
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  hubSpot: {
    portalId: import.meta.env.VITE_HUB_SPOT_PORTAL_ID,
    formId: import.meta.env.VITE_HUB_SPOT_FORM_ID,
  },
  userflowToken: import.meta.env.VITE_USERFLOW_TOKEN,
  tableau: {
    embeddingSdkUrl:
      import.meta.env.VITE_TABLEAU_EMBEDDING_SDK_URL ??
      'https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js',
    marketShareDashboardUrl: import.meta.env.VITE_TABLEAU_MARKET_SHARE_DASHBOARD_URL,
  },
  productFruitsWorkspace: import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE,
};
