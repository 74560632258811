import { getFilterTypeAndKeyFromNodeId, type AllFilterType } from '@carrefour-gcs/shared';
import { storeToRefs } from 'pinia';
import { computed, type ComputedRef } from 'vue';

import type { FilterNameType } from '../kpisPerimeter.model';
import { useReferential } from '../useKpisPerimeter';
import { useUrlQueryString, type QueryRouter } from '../useUrlQueryString';

import { useUserStore } from '@/stores/user.store';

interface FilterFromQueryType {
  sectorFilters: ComputedRef<string[]>;
  departmentFilters: ComputedRef<string[]>;
  groupClassFilters: ComputedRef<string[]>;
  classFilters: ComputedRef<string[]>;
  subClassFilters: ComputedRef<string[]>;
  storeFilters: ComputedRef<string[]>;
  chainTypeFilters: ComputedRef<string[]>;
  regionFilters: ComputedRef<string[]>;
  activityTypeFilters: ComputedRef<string[]>;
  integratedFranchisedFilters: ComputedRef<string[]>;
  triptyqueFilters: ComputedRef<string[]>;
  bemHoldingFilters: ComputedRef<string[]>;
  supplierFilters: ComputedRef<string[]>;
  brandFilters: ComputedRef<string[]>;
  itemFilters: ComputedRef<string[]>;
  barcodeFilters: ComputedRef<string[]>;
}

export const useGetFiltersFromQuery = (router: QueryRouter): FilterFromQueryType => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { query } = useUrlQueryString(router);
  const { referential } = useReferential(router);

  const getComputedFiltersFromQuery = (filterKey: FilterNameType, type: AllFilterType) =>
    computed<string[]>(() => {
      const queryValue = query.value[filterKey];

      const getFilterValues = () => {
        // As empty array display nothing in the url, we need to be able
        // to distinguish nothing is selected from default value.
        // Using null as value will display query string key only, allowing to
        // set correct filter values when reloading the page.
        if (queryValue === null) {
          return [];
        }

        if (queryValue === undefined) {
          if (referential.value === 'nielsen') {
            // No filters from user settings in nielsen because there is no correspondance between
            // carrefour and nielsen product / store referential
            return [];
          }

          return defaultKpisPerimeter.value.filters[filterKey];
        }

        return typeof queryValue === 'string' ? [queryValue] : (queryValue as string[]);
      };

      return getFilterValues().filter((nodeId) => {
        const { filterType } = getFilterTypeAndKeyFromNodeId(nodeId);

        return filterType === type;
      });
    });

  const sectorFilters = getComputedFiltersFromQuery('nomenclatureFilters', 'sector');
  const departmentFilters = getComputedFiltersFromQuery('nomenclatureFilters', 'department');
  const groupClassFilters = getComputedFiltersFromQuery('nomenclatureFilters', 'grpClass');
  const classFilters = getComputedFiltersFromQuery('nomenclatureFilters', 'class');
  const subClassFilters = getComputedFiltersFromQuery('nomenclatureFilters', 'subClass');
  const storeFilters = getComputedFiltersFromQuery('storesFilters', 'store');
  const chainTypeFilters = getComputedFiltersFromQuery('storesFilters', 'chainType');
  const activityTypeFilters = getComputedFiltersFromQuery('activityTypesFilters', 'activityType');
  const integratedFranchisedFilters = getComputedFiltersFromQuery(
    'integratedFranchisedFilters',
    'integratedFranchised',
  );
  const triptyqueFilters = getComputedFiltersFromQuery('tryptiqueFilters', 'brandType');
  const bemHoldingFilters = getComputedFiltersFromQuery('brandsFilters', 'bemMainHolding');
  const supplierFilters = getComputedFiltersFromQuery('brandsFilters', 'supplier');
  const brandFilters = getComputedFiltersFromQuery('brandsFilters', 'brand');
  const barcodeFilters = getComputedFiltersFromQuery('barcodeFilters', 'barcode');
  const itemFilters = getComputedFiltersFromQuery('itemFilters', 'item');

  const regionFilters = getComputedFiltersFromQuery('storesFilters', 'region');

  return {
    sectorFilters,
    departmentFilters,
    groupClassFilters,
    classFilters,
    subClassFilters,
    storeFilters,
    chainTypeFilters,
    activityTypeFilters,
    integratedFranchisedFilters,
    triptyqueFilters,
    bemHoldingFilters,
    supplierFilters,
    brandFilters,
    itemFilters,
    barcodeFilters,
    regionFilters,
  };
};
