import type {
  CustomDateType,
  DateSelectionOption,
  NielsenPeriodStrType,
  Period,
} from '@carrefour-gcs/shared';

export interface PeriodIntervalType {
  start: CustomDateType;
  end: CustomDateType;
}
export interface NielsenIntervalType {
  start: NielsenPeriodStrType;
  end: NielsenPeriodStrType;
}
/**
 * Period with "nielsen" excluded. Nielsen is handled differently
 */
//TODO: find a way to get a better name for this types  "DatePeriod"/"DatePeriodType"
export type DatePeriod = Exclude<Period, 'nielsen'>;

export interface DatePeriodIntervalType {
  periodInterval: PeriodIntervalType;
  period: DatePeriod;
  option: DateSelectionOption;
}
export interface NielsenPeriodIntervalType {
  periodInterval: NielsenIntervalType;
  period: 'nielsen';
  option: DateSelectionOption;
}

export const isNielsenPeriod = (
  period: DatePeriodIntervalType | NielsenPeriodIntervalType,
): period is NielsenPeriodIntervalType => {
  return period.period === 'nielsen';
};

export const isNielsenInterval = (
  interval: PeriodIntervalType | NielsenIntervalType,
): interval is NielsenIntervalType => {
  return typeof interval.start === 'string' && typeof interval.end === 'string';
};
