import type { AvailableLanguagesType, CustomDateType } from '@carrefour-gcs/shared';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeekInYear from 'dayjs/plugin/isoWeeksInYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import i18next from '../i18n';

export function configureDayjs() {
  dayjs.extend(updateLocale);
  dayjs.extend(timezone);
  dayjs.extend(isoWeek);
  dayjs.extend(utc);
  dayjs.extend(weekOfYear);
  dayjs.extend(customParseFormat);
  dayjs.extend(quarterOfYear);
  dayjs.extend(isBetween);
  dayjs.extend(duration);
  dayjs.extend(isoWeekInYear);
  dayjs.extend(isLeapYear);

  setDayjsLocale(i18next.language as AvailableLanguagesType);
  // Set Monday as first day of the week for en local
  dayjs.updateLocale('en', {
    weekStart: 1,
  });
}

export async function setDayjsLocale(lang: AvailableLanguagesType) {
  dayjs.locale(lang);
}

export function customDateToString(customDate: CustomDateType): string {
  return `${customDate.year}/${customDate.month}/${customDate.day}`;
}

export function parseCustomDate(customDate: string): CustomDateType {
  const [year, month, day] = customDate.split('/').map(Number);

  // Validate the date
  if (
    isNaN(year) ||
    isNaN(month) ||
    isNaN(day) ||
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31 ||
    !dayjs(`${year}-${month}-${day}`, 'YYYY-M-D').isValid()
  ) {
    throw new Error('Invalid custom date provided');
  }

  return { year, month, day };
}

export function isCustomDateEquals(left: CustomDateType, right: CustomDateType): boolean {
  return left.year === right.year && left.month === right.month && left.day === right.day;
}
