<script setup lang="ts">
import { provide, ref } from 'vue';

import NotificationContainer from '@/providers/notification/NotificationContainer.vue';
import type {
  Notification,
  NotificationFunction,
  NotificationFunctionProps,
} from '@/providers/notification/notification.type';

const notifications = ref<Notification[]>([]);

const addNotification: NotificationFunction = (props: NotificationFunctionProps) => {
  const id = Date.now();
  notifications.value.push({ id, ...props });

  if (props?.duration) {
    setTimeout(() => {
      notifications.value = notifications.value.filter((n) => n.id !== id);
    }, props.duration);
  }
};

provide('addNotification', addNotification);
</script>
<template>
  <slot></slot>

  <div class="fixed z-[100] top-0 right-0 max-w-sm p-4 overflow-hidden gap-2">
    <TransitionGroup name="notification" tag="div" class="flex flex-col gap-2">
      <div v-for="notification in notifications" :key="notification.id">
        <NotificationContainer
          :notification="notification"
          :close="() => (notifications = notifications.filter((n) => n.id !== notification.id))"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s ease;
}
.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
