import { computed, type ComputedRef } from 'vue';
import type { LocationQueryRaw, Router } from 'vue-router';
// Because `useUrlQueryString` is called from a `createGlobalState` inside `useKpisPerimeter`,
// using the global router object assure a correct access of current route
// https://github.com/vueuse/vueuse/issues/466#issuecomment-841076844
// import router from '../../router';

interface UrlQueryStringReturnType {
  query: ComputedRef<LocationQueryRaw>;
  updateQuery: (value: LocationQueryRaw) => Promise<void>;
  resetQuery: () => Promise<void>;
}

export type QueryRouter = Pick<Router, 'replace' | 'currentRoute'>;

export const useUrlQueryString = (router: QueryRouter): UrlQueryStringReturnType => {
  const query = computed(() => router.currentRoute.value.query);

  const updateQuery = async (values: LocationQueryRaw) => {
    await router.replace({
      query: { ...router.currentRoute.value.query, ...values },
      params: { resetQuery: 'true' },
    });
  };
  const resetQuery = async () => {
    await router.replace({
      query: undefined,
      params: { resetQuery: 'true' },
    });
  };

  return { query, updateQuery, resetQuery };
};
