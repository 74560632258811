import { z } from 'zod';
import { type CountryType, isAValidCountry } from './country.model';

export const HOLDING_COUNTRY_FIELD_SEPARATOR = '___'; // Triple underscore to avoid collisions with eventual underscores in holding names
export type HoldingId = string;
export type HoldingName = string;
export type HoldingXCountryType = `${HoldingId}___${HoldingName}___${CountryType}`;

export interface HoldingCountryInfoType {
  holdingId: string;
  holdingName: string;
  country: CountryType;
}

export function isHoldingCountry(value: string): value is HoldingXCountryType {
  const parts = value.split(HOLDING_COUNTRY_FIELD_SEPARATOR);
  if (parts.length !== 3) {
    return false;
  }
  const [holdingId, holdingName, country] = parts;

  return (
    !!holdingId?.length && !!holdingName?.length && !!country?.length && isAValidCountry(country)
  );
}
export function extractInfoFromHoldingName(holdingCountry: string): HoldingCountryInfoType {
  if (!isHoldingCountry(holdingCountry)) {
    throw new Error(`Invalid holding country ${holdingCountry}`);
  }
  const [holdingId, holdingName, country] = holdingCountry.split(HOLDING_COUNTRY_FIELD_SEPARATOR);
  if (!holdingId?.length || !holdingName?.length || !country?.length || !isAValidCountry(country)) {
    throw new Error(`Invalid holding country ${holdingCountry}`);
  }

  return {
    holdingId,
    holdingName,
    country,
  };
}
export const HoldingCountryZodSchema = z.string().refine(isHoldingCountry, {
  message: 'Invalid HoldingCountry',
});
