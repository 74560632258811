import type { FeatureType } from '@carrefour-gcs/shared';
import {
  createRouter,
  createWebHistory,
  parseQuery,
  type LocationQuery,
  type RouteLocationNamedRaw,
  type RouteLocationNormalized,
  type RouteLocationPathRaw,
  type Router,
} from 'vue-router';

import { useFeatureToggle } from '../config/composables/useFeatureToggle';
import { useAnalytics } from '../domain/core/analytics/composables/useAnalytics';

import { routes } from './routes';

import type { IconName } from '@/components/icons/icon.model';
import { config } from '@/config/configuration';
import { projectsRouter } from '@/router/routerProjects';
import { useAuthStore } from '@/stores/auth.store';
import { useUserStore } from '@/stores/user.store';

const LoginView = () => import('@/views/Login/LoginView.vue');
const OnboardingView = () => import('@/views/Onboarding/OnboardingView.vue');
const ErrorView = () => import('@/views/ErrorView.vue');
const SettingsView = () => import('@/views/Settings/SettingsView.vue');
const ToolsView = () => import('@/views/Tools/ToolsView.vue');
const SupportView = () => import('@/views/Support/SupportView.vue');
const SettingsEditView = () => import('@/views/Settings/SettingsEditView.vue');
const AccountManagement = () => import('@/views/AccountManagement/AccountManagement.vue');
const AdminView = () => import('@/views/Admin/AdminView.vue');

declare module 'vue-router' {
  interface RouteMeta {
    sidebar?: 'menu' | 'tool';
    sidebarIcon?: IconName;
    isUnauthenticated?: boolean;
    isAdminRequired?: boolean;
    isAccountManagerRequired?: boolean;
    breadcrumbs?: Array<{ title: string; to?: { name: string } }>;
    isOnboarding?: boolean;
    featureName?: FeatureType;
  }
}

const router = createRouter({
  history: createWebHistory(config.baseUrl),
  routes: [
    {
      path: routes.login,
      name: 'login',
      component: LoginView,
      meta: {
        isUnauthenticated: true,
      },
    },
    {
      path: routes.performance,
      name: 'performance',
      component: () => import('@/views/Performance/PerformanceCategory.vue'),
      meta: {
        sidebar: 'menu',
        sidebarIcon: 'bar-chart-2',
        breadcrumbs: [
          {
            title: 'commonLabel.performance',
            to: { name: 'performance' },
          },
        ],
      },
    },

    {
      path: routes.detail('market-share'),
      name: 'market-share',
      component: () => import('@/views/Performance/PerformanceMarketShareEmbedding.vue'),
      props: true,
      meta: {
        featureName: 'embeddingMarketShareDashboard',
        breadcrumbs: [
          {
            title: 'commonLabel.performance',
            to: { name: 'performance' },
          },
          {
            title: 'commonLabel.details',
            to: { name: 'market-share' },
          },
        ],
      },
    },
    {
      path: routes.detail(),
      name: 'detail',
      component: () => import('@/views/Performance/PerformanceDetail.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            title: 'commonLabel.performance',
            to: { name: 'performance' },
          },
          {
            title: 'commonLabel.details',
            to: { name: 'detail' },
          },
        ],
      },
    },
    ...projectsRouter,
    {
      path: routes.admin.home,
      name: 'administration',
      component: AdminView,
      beforeEnter: () => {
        const userStore = useUserStore();
        if (!userStore.isAdmin) {
          return { name: 'performance' };
        }
      },
      meta: {
        isAdminRequired: true,
        sidebar: 'menu',
        sidebarIcon: 'tool',
        breadcrumbs: [
          {
            title: 'commonLabel.administration',
          },
        ],
      },
    },
    {
      path: routes.admin.account,
      name: 'accountManagement',
      component: AccountManagement,
      beforeEnter: () => {
        const userStore = useUserStore();
        if (!userStore.isAccountManager) {
          return { name: 'performance' };
        }
      },
      meta: {
        isAccountManagerRequired: true,
        sidebar: 'tool',
        sidebarIcon: 'shield',
        breadcrumbs: [
          {
            title: 'commonLabel.administration',
          },
        ],
      },
    },
    {
      path: routes.tools,
      name: 'tools',
      component: ToolsView,
      meta: {
        sidebar: 'tool',
        sidebarIcon: 'tool',
      },
    },
    {
      path: routes.support,
      name: 'support',
      component: SupportView,
      meta: {
        skipPreserveQuery: true,
      },
    },
    {
      path: routes.settings.home,
      name: 'settings',
      component: SettingsView,
      meta: {
        sidebar: 'tool',
        sidebarIcon: 'settings',
      },
    },
    {
      path: routes.settings.edit,
      name: 'settingsEdit',
      component: SettingsEditView,
    },
    {
      path: routes.onboarding,
      name: 'onboarding',
      component: OnboardingView,
      meta: {
        isOnboarding: true,
      },
    },
    {
      path: routes.error,
      name: 'error',
      component: ErrorView,
      meta: {
        isUnauthenticated: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const { trackView } = useAnalytics();
  const routeEnabled = useFeatureToggle(to.meta.featureName);

  // If feature is disabled in feature-toggle config, redirect to performance page
  if (!routeEnabled.value) {
    return next({ name: 'performance' });
  }

  const queryToPreserve = getQueryParamsToPreserve(to, from);

  const authStore = useAuthStore();
  const userStore = useUserStore();
  const isUserOnboarding = userStore.isUserOnboarding;

  if (authStore.isAuthLoading) {
    return next(false);
  }

  // If the user is an admin it does only have access to the admin page
  if (userStore.isAdmin && to.meta.sidebar === 'menu' && !to.meta.isAdminRequired) {
    return next({ name: 'administration' });
  }
  if (
    !authStore.isAuthenticated &&
    // ❗️ Avoid an infinite redirect
    !to.meta.isUnauthenticated &&
    to.name !== 'error'
  ) {
    return next({ name: 'login', query: queryToPreserve });
  }

  if (authStore.isAuthenticated && isUserOnboarding && !to.meta.isOnboarding) {
    return next({ name: 'onboarding', query: queryToPreserve });
  }

  if (queryToPreserve && Object.keys(queryToPreserve).length) {
    return next({ ...to, query: queryToPreserve });
  }
  if (to.name !== from.name) {
    trackView(typeof to.name === 'string' ? `${to.name}` : to.path, to.path);
  }

  next();
});

function getQueryParamsToPreserve(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): LocationQuery | undefined {
  const mustResetQuery = !!(to.params.resetQuery ?? to.query.resetQuery);
  if (mustResetQuery) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetQuery, ...queryToPreserve } = to.redirectedFrom?.query || from.query; // remove an eventual resetQuery param
  return queryToPreserve;
}

export default router;

export function navigatePreservingQuery(
  router: Router,
  nav: RouteLocationPathRaw | RouteLocationNamedRaw,
) {
  router.push({ ...nav, query: parseQuery(window.location.search) });
}
