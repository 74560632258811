import { useTrpc, type RouterInput, type RouterOutput } from '@/composables/useTrpc';
import { retryOnError } from '@/helpers/retry';

export type UserType = RouterOutput['core']['user']['getMe'];
export type SaveUserSettingsInput = RouterInput['core']['user']['saveUserSettings'];
export type createUserAccessInput = RouterInput['core']['user']['createUserAccessRequest'];

export async function getMe() {
  return useTrpc().core.user.getMe.query();
}
export async function getUserWithRetry(maxAttempts = 5) {
  return retryOnError('getMe', getMe, { maxAttempts });
}

export async function getDefaultAccessRequest() {
  return useTrpc().core.user.getDefaultAccessRequest.query();
}

export async function saveUserSettings(input: SaveUserSettingsInput) {
  return useTrpc().core.user.saveUserSettings.mutate(input);
}

export async function createUserAccessRequest(input: createUserAccessInput) {
  return useTrpc().core.user.createUserAccessRequest.mutate(input);
}
