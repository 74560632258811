import {
  isProductNomenclatureType,
  isStorePerimeterType,
  type ProductNomenclatureType,
  type StorePerimeterType,
} from '@carrefour-gcs/shared';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter, type LocationQueryRaw } from 'vue-router';

import { EMPTY_FILTERS_STATE } from './defaultPerimeter.const';
import { useFilters } from './filters';
import { type KpisPerimeterType } from './kpisPerimeter.model';
import { usePeriod } from './period';
import { useUrlQueryString, type QueryRouter } from './useUrlQueryString';

import { getDefaultNielsenPeriod } from '@/domain/core/filter/components/periodSelector/nielsenPeriod';
import { useUserStore } from '@/stores/user.store';

export const useKpisPerimeter = (router: QueryRouter = useRouter()): KpisPerimeterType => {
  const userStore = useUserStore();
  const { userCountry } = storeToRefs(userStore);

  const { resetQuery } = useUrlQueryString(router);

  const productFilterType = computed(() => (userCountry.value === 'FRA' ? 'barcode' : 'item'));

  const withTax = useIsWithTax(router);
  const storePerimeter = useStorePerimeter(router);
  const referential = useReferential(router);
  const filters = useFilters(router);
  const period = usePeriod(router);

  const isDefaultPerimeter = () => {
    return (
      withTax.isDefault() &&
      referential.isDefault() &&
      filters.isDefault() &&
      period.isDefault() &&
      storePerimeter.isDefault()
    );
  };

  return {
    productFilterType,
    withTax,
    storePerimeter,
    referential,
    filters,
    period,
    isDefaultPerimeter,
    resetPerimeter: resetQuery,
  };
};

const useStorePerimeter = (
  router: QueryRouter,
): KpisPerimeterType['storePerimeter'] & {
  generateStorePerimeterQueryParams: (storePerimeter: StorePerimeterType) => LocationQueryRaw;
} => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter, isUserOnboarding, isAdmin } = storeToRefs(userStore);

  const { query, updateQuery } = useUrlQueryString(router);

  const storePerimeter = computed(() => {
    if (
      typeof query.value.storePerimeter === 'string' &&
      isStorePerimeterType(query.value.storePerimeter)
    ) {
      return query.value.storePerimeter;
    }

    if (!defaultKpisPerimeter.value.storePerimeter) {
      // If user hasn't completed their onboarding, consider store perimeter as `allStores`
      // This way, fetch data (for filters), is correctly scoped.
      if (isUserOnboarding.value) {
        return 'allStores';
      }

      // As admins don't complete the onboarding, they do not have any saved settings
      if (isAdmin.value) {
        return 'allStores';
      }

      throw new Error(
        '[userStorePerimeter.storePerimeter]: Store perimeter is undefined whereas user should have finish their onboarding',
      );
    }

    return defaultKpisPerimeter.value.storePerimeter;
  });

  const generateStorePerimeterQueryParams = (
    storePerimeter: StorePerimeterType,
  ): LocationQueryRaw => {
    return {
      storePerimeter: isDefault(storePerimeter) ? undefined : String(storePerimeter),
    };
  };

  const updateStorePerimeter = (storePerimeter: StorePerimeterType) =>
    updateQuery({
      ...generateStorePerimeterQueryParams(storePerimeter),
    });

  const isDefault = (currentValue = storePerimeter.value) =>
    currentValue === defaultKpisPerimeter.value.storePerimeter;

  return {
    storePerimeter,
    updateStorePerimeter,
    isDefault: () => isDefault(),
    generateStorePerimeterQueryParams,
  };
};

const useIsWithTax = (
  router: QueryRouter,
): KpisPerimeterType['withTax'] & {
  generateIsWithTaxQueryParams: (isWithTax: boolean) => LocationQueryRaw;
} => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { query, updateQuery } = useUrlQueryString(router);

  const isWithTax = computed(() => {
    if (
      typeof query.value.isWithTax === 'string' &&
      ['true', 'false'].includes(query.value.isWithTax)
    ) {
      return query.value.isWithTax === 'true';
    }

    return defaultKpisPerimeter.value.isWithTax;
  });

  const generateIsWithTaxQueryParams = (isWithTax: boolean): LocationQueryRaw => {
    return { isWithTax: isDefault(isWithTax) ? undefined : String(isWithTax) };
  };

  const updateIsWithTax = (isWithTax: boolean) =>
    updateQuery({
      ...generateIsWithTaxQueryParams(isWithTax),
    });

  const isDefault = (currentValue = isWithTax.value) =>
    currentValue === defaultKpisPerimeter.value.isWithTax;

  return {
    isWithTax,
    updateIsWithTax,
    isDefault: () => isDefault(),
    generateIsWithTaxQueryParams,
  };
};

export const useReferential = (router: QueryRouter): KpisPerimeterType['referential'] => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { query, updateQuery, resetQuery } = useUrlQueryString(router);
  const { selectedPeriod: period, generateSelectedPeriodQueryParams } = usePeriod(router);
  const { generateIsWithTaxQueryParams } = useIsWithTax(router);
  const { generateStorePerimeterQueryParams } = useStorePerimeter(router);

  const referential = computed(() => {
    const referentialQuery = query.value.referential;

    if (typeof referentialQuery === 'string' && isProductNomenclatureType(referentialQuery)) {
      return referentialQuery;
    }

    return defaultKpisPerimeter.value.referential;
  });

  const updateReferential = async (referential: ProductNomenclatureType) => {
    if (referential === 'carrefour') {
      await resetQuery();

      return;
    }

    if (!defaultKpisPerimeter.value.referentialIntervals.nielsen) {
      throw new Error('Nielsen referential interval is not defined');
    }

    const forcedIsWithTaxInNielsen = generateIsWithTaxQueryParams(true);
    const defaultNielsenReferentialPeriod = generateSelectedPeriodQueryParams(
      getDefaultNielsenPeriod({
        nielsenInterval: defaultKpisPerimeter.value.referentialIntervals.nielsen.period,
        withOption: period.value.option,
      }),
    );
    const emptiedFiltersInNielsen = EMPTY_FILTERS_STATE;
    const forcedAllStoreInNielsen = generateStorePerimeterQueryParams('allStores');

    await updateQuery({
      referential: defaultKpisPerimeter.value.referential === referential ? undefined : referential,
      ...forcedIsWithTaxInNielsen,
      ...defaultNielsenReferentialPeriod,
      ...emptiedFiltersInNielsen,
      ...forcedAllStoreInNielsen,
    });
  };

  const isDefault = () => referential.value === defaultKpisPerimeter.value.referential;

  return {
    referential,
    updateReferential,
    isDefault,
  };
};
