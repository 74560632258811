import { storeToRefs } from 'pinia';
import { watch } from 'vue';

import { useAnalytics } from '@/domain/core/analytics/composables/useAnalytics';
import router from '@/router';
import { useUserStore } from '@/stores/user.store';

export const useTrackUserAppAccess = () => {
  const { trackEvent } = useAnalytics();
  const userStore = useUserStore();
  const { authenticatedUser, isImpersonating } = storeToRefs(userStore);

  watch(authenticatedUser, (value, prev) => {
    // Only trigger the tracking if thee user is not already authenticated
    // (prevent tracking on user settings update)
    if (prev === null && value) {
      // Wait 3s before sending the tracking event to prevent spamming in case of infinite page reload
      setTimeout(() => {
        trackEvent(
          {
            action: 'user_accessed_app',
            category: 'app',
            event: 'app_access',
            customFields: {
              is_impersonated: isImpersonating.value,
            },
          },
          router.currentRoute.value,
        );
      }, 3000);
    }
  });
};
