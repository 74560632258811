import {
  FEATURES_PER_ENV,
  isFeatureEnabledForEnvAndCountry,
  type FeatureType,
} from '@carrefour-gcs/shared';
import { computed } from 'vue';

import { useUserStore } from '../../stores/user.store';
import { config } from '../configuration';

export function useFeatureToggle(
  feature?: FeatureType,
  { isCountryAgnostic } = { isCountryAgnostic: false },
) {
  const userStore = useUserStore();

  const featureEnabled = computed(() => {
    if (!feature) {
      return true;
    }
    if (isCountryAgnostic) {
      return isFeatureEnabledForEnvAndCountry({
        feature,
        appEnv: config.appEnv,
        isCountryAgnostic,
      });
    }

    const userCountry = userStore.userCountry;
    if (!userCountry) {
      return false;
    }

    return isFeatureEnabledForEnvAndCountry({
      feature,
      appEnv: config.appEnv,
      country: userCountry,
      isCountryAgnostic,
    });
  });
  return featureEnabled;
}
