<script setup lang="ts">
import type { PropType } from 'vue';
import VueFeather from 'vue-feather';

import InfoIcon from '@/assets/svg/info.svg?component';
import type { Notification } from '@/providers/notification/notification.type';

const props = defineProps({
  notification: { type: Object as PropType<Notification>, required: true },
  close: { type: Function, required: true },
});

const background = {
  'bg-background-positive': props.notification.type === 'success',
  'bg-info': props.notification.type === 'info',
  'bg-error': props.notification.type === 'error',
};

const icon = {
  'bg-white': props.notification.type !== 'info',
};
</script>

<template>
  <div class="p-4 text-white rounded-lg flex gap-4 items-start" :class="background">
    <div class="mt-0.5 h-6 w-6 rounded-full flex items-center justify-center" :class="icon">
      <VueFeather
        v-if="props.notification.type === 'success'"
        type="check"
        class="text-background-positive h-5 w-5"
      />

      <InfoIcon v-if="props.notification.type === 'info'" class="h-6 w-6" />

      <VueFeather v-if="props.notification.type === 'error'" type="x" class="text-error h-5 w-5" />
    </div>

    <div class="flex-1">
      <h6 class="font-bold text-xl">{{ props.notification.title }}</h6>
      <p class="text-md">{{ props.notification.message }}</p>
    </div>

    <div class="mt-0.5 h-6 w-6 cursor-pointer" @click="props.close()">
      <VueFeather type="x" class="text-white h-5 w-5" />
    </div>
  </div>
</template>
