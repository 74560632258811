<script lang="ts" setup>
import dayjs from 'dayjs';
import { computed } from 'vue';

import { defaultPropsArrayDate, type DateIntervalType } from '../inputDate.props';

import { InputDateGeneric } from '@/components/elements/input';

const props = defineProps(defaultPropsArrayDate);

const model = defineModel<DateIntervalType>({ required: true });

const formatDate = computed(() => {
  if (model.value && model.value.length > 1) {
    const firstDate = dayjs(model.value?.[0]);
    const secondDate = dayjs(model.value?.[1]);
    return `S${firstDate.isoWeek()} ${firstDate.format('DD/MM/YYYY')} - ${secondDate.format(
      'DD/MM/YYYY',
    )}`;
  }

  return '';
});
</script>

<template>
  <InputDateGeneric v-bind="{ ...props }" v-model="model" week-numbers="iso" week-picker>
    <template #content>
      {{ formatDate }}
    </template>
  </InputDateGeneric>
</template>
