export const COUNTRIES = ['FRA', 'ITA', 'ESP'] as const;
export type CountryType = (typeof COUNTRIES)[number];

export function isAValidCountry(country: string): country is CountryType {
  return COUNTRIES.includes(country as CountryType);
}

export const LANG = ['fr', 'it', 'en', 'es', ''] as const;
export type Langs = typeof LANG;
export type Lang = (typeof LANG)[number];

export const COUNTRY_TO_LANG: Record<CountryType, Lang> = {
  FRA: 'fr',
  ITA: 'it',
  ESP: 'es',
};
