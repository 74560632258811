<script lang="ts" setup>
import { useTranslation } from 'i18next-vue';
import type { PropType } from 'vue';
import { computed } from 'vue';

import { normalizeMonthDateInterval } from '../periodSelectorUtils';

import { ICON_VARIANTS, InputDateMonth } from '@/components/elements/input';
import type {
  DateIntervalType,
  SelectedIntervalType,
} from '@/domain/core/filter/components/periodSelector/periodSelector.model';

const { t } = useTranslation();

const props = defineProps({
  availableInterval: { type: Object as PropType<DateIntervalType>, required: true },
  selectedInterval: { type: Object as PropType<SelectedIntervalType>, required: true },
  disabled: { type: Boolean, default: false },
});
const emit = defineEmits<{ (e: 'updateInterval', interval: SelectedIntervalType): void }>();

const selectedIntervalData = computed(() => [
  props.selectedInterval.from,
  props.selectedInterval.to,
]);

function onUpdateInterval(values: [from: Date, to: Date]) {
  emit('updateInterval', normalizeMonthDateInterval(values));
}

const getAvailableDateMin = computed(() => {
  if (
    props.availableInterval.start &&
    selectedIntervalData.value[0] > props.availableInterval.start
  ) {
    return selectedIntervalData.value[0];
  }

  return props.availableInterval.start;
});

const getAvailableDateMax = computed(() => {
  if (props.availableInterval.end && selectedIntervalData.value[1] < props.availableInterval.end) {
    return selectedIntervalData.value[1];
  }

  return props.availableInterval.end;
});

const getVariant = computed(() => {
  return selectedIntervalData.value?.length > 1 &&
    selectedIntervalData.value?.[0] > selectedIntervalData.value?.[1]
    ? 'error'
    : 'default';
});
</script>

<template>
  <div class="flex flex-col justify-between items-center gap-4">
    <div class="flex flex-row items-center gap-5 w-full">
      <label class="w-10 capitalize">{{ t(`commonLabel.from`) }}</label>
      <div class="flex-1 w-full">
        <InputDateMonth
          :model-value="selectedIntervalData[0]"
          name="time-selector-month-start"
          :placeholder="'MM/YYYY'"
          :date-min="availableInterval.start"
          :date-max="getAvailableDateMax"
          :start-date="availableInterval?.end"
          :icon-right="'calendar'"
          :icon-right-variant="ICON_VARIANTS[1]"
          :disabled="disabled"
          test-id="time-selector-month-start"
          @update:model-value="onUpdateInterval([$event, selectedIntervalData[1]])"
        />
      </div>
    </div>

    <div class="flex flex-row items-center gap-5 w-full">
      <label class="w-10 capitalize">{{ t(`commonLabel.to`) }}</label>
      <div class="flex-1 w-full">
        <InputDateMonth
          :model-value="selectedIntervalData[1]"
          name="time-selector-month-end"
          :placeholder="'MM/YYYY'"
          :date-min="getAvailableDateMin"
          :date-max="availableInterval.end"
          :start-date="availableInterval?.end"
          :icon-right="'calendar'"
          :icon-right-variant="ICON_VARIANTS[1]"
          :variant="getVariant"
          :disabled="disabled"
          test-id="time-selector-month-end"
          @update:model-value="onUpdateInterval([selectedIntervalData[0], $event])"
        />
      </div>
    </div>
  </div>
</template>
