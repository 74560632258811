import * as z from 'zod';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const CustomDateZodSchema = z.object({
  year: z.number(),
  month: z.number().min(1).max(12),
  day: z.number().min(1).max(31),
});
export type CustomDateType = z.infer<typeof CustomDateZodSchema>;

export function dateToCustomDate(date: Date): CustomDateType {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date provided');
  }
  const utcDate = dayjs(date)
    .set('hour', 12)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .utc();

  return {
    year: utcDate.year(),
    month: utcDate.month() + 1,
    day: utcDate.date(),
  };
}

export function customDateToDate(customDate: CustomDateType): Date {
  const { year, month, day } = customDate;
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error('Invalid custom date provided');
  }
  return dayjs.utc(`${year}-${month}-${day}`, 'YYYY-M-D').startOf('day').toDate();
}
