import { type AllFilterType, isFilterTypeGuard } from './kpis-perimeter.model';

const FILTER_NODE_ID_LEVEL_SEPARATOR = '##';
const FILTER_NODE_ID_TYPE_SEPARATOR = '=';

export type FilterTypeAndKey = `${AllFilterType}${typeof FILTER_NODE_ID_TYPE_SEPARATOR}${string}`;

export function joinTypeAndKey(node: { filterKey: string; type: AllFilterType }): FilterTypeAndKey {
  return `${node.type}${FILTER_NODE_ID_TYPE_SEPARATOR}${node.filterKey}`;
}

export function isFilterTypeAndKey(filter: string): filter is FilterTypeAndKey {
  const typeAndFilterKey = filter.split(FILTER_NODE_ID_TYPE_SEPARATOR);
  const [filterType, filterKey] = typeAndFilterKey;

  return [
    typeAndFilterKey.length === 2 && filterType && filterKey && isFilterTypeGuard(filterType),
  ].every(Boolean);
}

type CreateFilterNodeIdParamsType = {
  filterType: AllFilterType;
  filterKey: string;
  parentNodeId?: string;
};
export function createFilterNodeId({
  filterType,
  filterKey,
  parentNodeId,
}: CreateFilterNodeIdParamsType) {
  const joinedTypeAndKey = joinTypeAndKey({
    filterKey,
    type: filterType,
  });

  if (parentNodeId) {
    return `${parentNodeId}${FILTER_NODE_ID_LEVEL_SEPARATOR}${joinedTypeAndKey}`;
  }

  return joinedTypeAndKey;
}

export function splitFilterNodeId(node: string): Array<string[]> {
  return node
    .split(FILTER_NODE_ID_LEVEL_SEPARATOR)
    .map((typeAndValue) => typeAndValue.split(FILTER_NODE_ID_TYPE_SEPARATOR));
}

export function getFilterTypeAndKeyFromNodeId(node: string): {
  filterType: AllFilterType;
  filterKey: string;
} {
  const splittedNodeId = splitFilterNodeId(node);
  const filter = splittedNodeId[splittedNodeId.length - 1] ?? [];

  const [filterType, filterKey] = filter;

  if (!filterType || !filterKey) {
    throw new Error(`Invalid filter: no filterType or filterKey`);
  }

  if (!isFilterTypeGuard(filterType)) {
    throw new Error(`Retrieved type is not one of AllFilterType: ${filterType}`);
  }

  return { filterType, filterKey };
}

export function extractFilterKeyFromNodeId(node: string): string {
  return getFilterTypeAndKeyFromNodeId(node).filterKey;
}

export function extractFilterTypeFromNodeId(node: string): AllFilterType {
  return getFilterTypeAndKeyFromNodeId(node).filterType;
}

export function isValidFilterType(node: string, filterTypes?: AllFilterType[]): boolean {
  try {
    const { filterType } = getFilterTypeAndKeyFromNodeId(node);

    return !filterTypes || filterType.includes(filterType);
  } catch (e) {
    return false;
  }
}
